import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SearchQueryState = {
  query: string;
}

const initialState: SearchQueryState = {
  query: '',
};

const searchQuerySlice = createSlice({
  name: 'searchQuery',
  initialState,
  reducers: {
    resetSearchQuery: () => initialState,
    search(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const { resetSearchQuery, search } = searchQuerySlice.actions;

export default searchQuerySlice.reducer;
