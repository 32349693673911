import React from 'react';
import {
  Box,
} from 'grommet';

import Bg from '../../assets/images/sign-in-bg.png';
import Header from './Header';

// TODO Need to implemen Main Layout UI Elements
const SubscriptionLayout: React.FC<JSX.Element> = ({ children }) => (
  <Box
    fill="horizontal"
    style={{ minHeight: '100%', background: `url("${Bg}") bottom center / 100% auto no-repeat` }}
  >
    <Box>
      <Header />
    </Box>
    <Box
      gridArea="main"
      justify="between"
      flex="grow"
    >
      {children}
    </Box>
  </Box>
);

export default SubscriptionLayout;
