import React, { useEffect } from 'react';

import {
  ThemeContext,
  Text,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Paragraph,
  Anchor,
} from 'grommet';
import { LinkNext } from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../assets/styles/themes';
import { useIdentity } from '../../app/hooks';
import { RouterPath } from '../../app/router';
import { Logo } from '../../components';

const VerifyEmail = (): React.ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();
  const { identity } = useIdentity();

  useEffect(() => {
    if (identity?.confirmed) {
      history.replace(RouterPath.ROUTE_HOME);
    } else if (!identity) {
      history.replace(RouterPath.ROUTE_LOGIN);
    }
  }, [identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="xlarge" direction="row-responsive" flex="grow" justify="center">
        <Card
          width={{ min: '360px', max: '100%' }}
          pad="large"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="large" direction="column" align="center" alignContent="center">
            <Heading level="4" margin="none" alignSelf="center">Verify your email address</Heading>
            <Text weight="bold" margin="none" alignSelf="center">Check your email</Text>
          </CardHeader>

          <CardBody>
            <Box
              align="center"
              justify="center"
              pad="medium"
            >
              <Logo />
            </Box>
            <Paragraph margin={{ vertical: 'small' }} textAlign="center">
              We have sent an email to
              <Text weight="bold">
                { identity?.email && ` ${identity.email}` }
              </Text>
            </Paragraph>
          </CardBody>
          <CardFooter
            margin={{ bottom: 'large' }}
            justify="center"
            direction="column"
            align="center"
          >
            <Box align="center" justify="center" pad={{ top: 'medium' }}>
              <Button
                type="button"
                size="medium"
                alignSelf="center"
                primary
                label="Back to Sign In"
                onClick={() => { history.replace(RouterPath.ROUTE_LOGIN); }}
              />
              <Anchor margin={{ top: 'medium' }}>
                Having trouble?
              </Anchor>
            </Box>
          </CardFooter>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default VerifyEmail;
