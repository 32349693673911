import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/react';
import storage from '../../storage';
import { User } from '../../types';

interface AuthState {
  token?: string | null;
  user?: User | null,
}

const { user, token } = storage;

const initialState: AuthState = {
  token,
  user,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state: AuthState, payload: PayloadAction<string>) {
      state.token = payload.payload;
      storage.token = payload.payload;
    },
    setUser(state: AuthState, payload: PayloadAction<User>) {
      state.user = payload.payload;
      storage.user = payload.payload;
      Sentry.setUser({ id: payload.payload.id as string });
    },

    signOut(state: AuthState) {
      state.user = null;
      state.token = null;
      storage.removeSessions();
      Sentry.configureScope((scope) => scope.setUser(null));
    },
  },
});

export const { setToken, setUser, signOut } = authSlice.actions;

export default authSlice.reducer;
