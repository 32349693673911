import React from 'react';

import {
  Box,
  Text,
} from 'grommet';
import styled from 'styled-components';

type Props = {
  label?: string;
  color?: string;
}

const RibbonBack = styled(Box)`
  position: absolute;
  left: -10px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background: none;
`;

const Ribbon = styled(Box)`
  position: absolute;
  left: -10px;
  box-shadow: inset 10px 10px 100px rgba(0,0,0,0.3);
  top: 25px;
  z-index : 1;
  border-radius: 0 50px 50px 0;
`;

export const CardRibbon = (props: Props): JSX.Element | null => {
  const { label, color } = props;

  if (!label) {
    return null;
  }

  return (
    <>
      <RibbonBack
        tabIndex={-10}
        style={{
          borderRight: `10px solid ${color}` || '20px solid color #E19D00',
        }}
      >&nbsp;
      </RibbonBack>
      <RibbonBack
        tabIndex={-10}
      >&nbsp;
      </RibbonBack>
      <Ribbon
        align="stretch"
        justify="start"
        style={{
          background: color || 'orange',
        }}
        direction="column"
        pad={{ left: '20px', right: 'medium', vertical: 'xxsmall' }}
        gap="none"
      >

        <Text size="12px" weight="bold" margin="none" color="white">
          {label || '-'}
        </Text>
      </Ribbon>
    </>
  );
};

CardRibbon.defaultProps = {
  label: '',
  color: '',
};

export default CardRibbon;
