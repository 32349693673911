import React from 'react';
import {
  BrowserRouter,
  Switch,
  Redirect,
} from 'react-router-dom';

import routerConfig from './router-config';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

const Router = (): React.ReactElement => (
  <BrowserRouter>
    <Switch>
      {
          routerConfig.privateRoutes.map(({
            key, path, component, layout, title,
          }) => (
            <PrivateRoute
              exact
              key={key}
              title={title}
              path={path as string}
              component={component as React.FC}
              layout={layout as React.FC}
            />
          ))
      }
      {
          routerConfig.publicRoutes.map(({
            type, key, path, component, title,
            layout, to,
          }) => (type === 'route' ? (
            <PublicRoute
              exact
              title={title}
              key={key}
              path={path as string}
              component={component as React.FC}
              layout={layout as React.FC}
            />
          ) : (
            <Redirect
              exact
              key={key}
              to={to as string}
            />
          )))
      }
    </Switch>
  </BrowserRouter>
);

export default Router;
