import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';

export const onboardingApiSlice = createApi({
  reducerPath: 'onboardingApi',
  baseQuery,
  endpoints(builder) {
    return {
      fetchQuestions: builder.query<any, void>({
        query() {
          return {
            url: 'onboarding-questions?_sort=order:ASC',
            method: 'GET',
          };
        },
      }),
      updateOnboarding: builder.mutation<any, any>({
        query(payload) {
          return {
            url: 'onboarding-questions',
            method: 'POST',
            body: payload,
          };
        },
      }),
    };
  },
});

export const {
  useUpdateOnboardingMutation,
  useLazyFetchQuestionsQuery,
} = onboardingApiSlice;
