import React from 'react';
import {
  Box,
} from 'grommet';
import styled from 'styled-components';

export const ScrollBox = styled(Box)`
  ::-webkit-scrollbar {
    border-radius: 30px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    cursor: pointer;
    background: #a2a0a0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #777777;
    cursor: pointer;
  }
`;

export default ScrollBox;
