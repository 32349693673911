import React from 'react';
import {
  Box,
  Layer,
  Sidebar,
} from 'grommet';

interface Props {
  onCloseHandler: () => void;
  width: string,
  height: string,
  body: JSX.Element,
  header?: JSX.Element,
  footer?: JSX.Element,
}
export const AppSlidebar = (props: Props): JSX.Element | null => {
  const {
    onCloseHandler,
    width,
    height,
    body,
    header,
    footer,
  } = props;

  return (
    <Layer
      position="left"
      responsive={false}
      onClickOutside={onCloseHandler}
    >
      <Box width={width} height={height}>
        <Sidebar
          style={{ padding: 0 }}
          width={width}
          height={height}
          background="white"
          round="none"
          header={header}
          footer={footer}
        >
          {body}
        </Sidebar>
      </Box>
    </Layer>
  );
};
AppSlidebar.defaultProps = {
  header: null,
  footer: null,
};
export default AppSlidebar;
