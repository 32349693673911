import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Text,
  Box,
  Button,
  Heading,
  Paragraph,
  Image,
  Spinner,
  Layer,
} from 'grommet';

import { Close } from 'grommet-icons';
import { FaLockOpen } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import premiumImg from '../../assets/images/premium.png';

import {
  useSessionsMutation,
} from '../../features/paymentGateway/paymentGatewayApiSlice';
import { RouterPath } from '../../app/router';

interface Props {
  onCloseHandler: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
const UpgradeSubscription = (props: Props): React.ReactElement => {
  const [isPlanYearly, setPlanYearly] = useState(true);
  const { pathname, search } = useLocation();
  const { onCloseHandler } = props;
  const [stripeCalling, setStripeCalling] = useState(false);
  const [session] = useSessionsMutation();

  const onPaidSelect = async (): Promise<void> => {
    const callback = `${pathname}${search}`;
    setStripeCalling(true);
    const result = await session({
      successUrl: `${RouterPath.ROUTE_SUBSCRIPTION_SUCCESS}?callback=${callback}`,
      cancelUrl: callback,
      isYearly: isPlanYearly,
    });
    const sessionId = (result as any).data.id;

    const stripe = await stripePromise;
    await stripe?.redirectToCheckout({
      sessionId,
    });

    setStripeCalling(false);
  };
  return (
    <Layer
      animate
      modal
      plain={false}
      responsive={false}
      background="transparent"
      onClickOutside={() => onCloseHandler()}
      onEsc={() => onCloseHandler()}
    >
      <Box align="end" pad="small">
        <Button
          primary
          color="light-1"
          onClick={onCloseHandler}
          style={{
            borderRadius: '50%',
          }}
          icon={<Close />}
        />
      </Box>
      <Box
        align="center"
        justify="center"
        direction="row"
        background="background-front"
        gap="medium"
        round="medium"
        elevation="medium"
        pad="medium"
      >
        <Box background="black" align="center" justify="center" round="100px" pad="medium">
          <FaLockOpen size="25px" />
        </Box>
        <Box align="start" justify="center">
          <Heading level="4" margin="none">
            Unlock all content
          </Heading>
          <Heading level="5" margin="none">
            Upgrade to premium now
          </Heading>
        </Box>
      </Box>
      <Box
        align="stretch"
        justify="start"
        round="medium"
        background="background-front"
        elevation="medium"
        direction="column"
        pad="large"
        margin={{ top: 'medium' }}
      >
        <Box
          align="stretch"
          justify="between"
          direction="row"
          style={{ position: 'relative' }}
        >
          <Box align="start" justify="center">
            <Paragraph>
              <Text weight={600} color="dark">Upplo</Text>
              <Text weight={600} color="status-warning">Premium</Text>
            </Paragraph>
            <Heading
              style={{ fontWeight: 'bold' }}
              size="medium"
              margin={{ bottom: 'medium', top: 'none' }}
              color="black"
              level="1"
              truncate={false}
            >
              { isPlanYearly ? '£99' : '£10'}
              <Text weight={500} size="small">/{ isPlanYearly ? 'year' : 'month'}</Text>
            </Heading>
          </Box>
          <Box align="center" justify="center" width="xsmall">
            <Image src={premiumImg} fit="contain" fill="horizontal" />
          </Box>
        </Box>
        <Box direction="row" gap="small" align="stretch" margin={{ bottom: 'medium' }}>
          <Button
            onClick={() => setPlanYearly(false)}
            primary={!isPlanYearly}
            secondary={isPlanYearly}
            label="Monthly"
            fill="horizontal"
          />
          <Button
            onClick={() => setPlanYearly(true)}
            primary={isPlanYearly}
            secondary={!isPlanYearly}
            label="Annually"
            fill="horizontal"
          />
        </Box>
        <Box align="end" justify="end" style={{ borderTop: '1px solid #D9D9D9' }}>
          <Paragraph color="dark-6">
            Over 100+ presentations ready to use. Updated content
            from experienced educators. Better productivity – save
            time planning
          </Paragraph>
          <Button
            onClick={onPaidSelect}
            disabled={stripeCalling}
            alignSelf="end"
            label={<Text color="white">Upgrade</Text>}
            primary
            reverse
            secondary={false}
            size="medium"
            icon={
            stripeCalling
              ? <Spinner size="xsmall" /> : undefined
          }
          />
        </Box>
      </Box>
    </Layer>

  );
};

export default UpgradeSubscription;
