import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import qs from 'qs';
import { decode } from 'blurhash';

import { Media, QueryParams } from '../types';

export const processError = (response: any, message: any = null, stack: any = null): any => {
  const data = {
    status: response.status,
    message: response?.data?.error || message,
    stack,
    detail: null,
  };

  if (response.data?.message) {
    if (isArray(response.data?.message) && response.data?.message[0]) {
      const { id: code, message: desc } = response.data.message[0].messages[0];
      data.detail = { code, desc } as any;
    } else if (isString(response.data?.message?.message)) {
      data.detail = {
        desc: response.data.message.message,
      } as any;
    } else if (isString(response.data?.message)) {
      data.detail = {
        desc: response.data.message,
      } as any;
    }
  }

  return data;
};

export const buildSearchQuery = (args?: QueryParams): string => {
  if (args) {
    const {
      page,
      pageSize,
      filters,
    } = args;

    const strSort = args.sort?.map((item) => `${item.field}:${item.order}`).join(',');

    let sort = {};

    if (strSort) {
      sort = {
        _sort: strSort,
      };
    }

    const query = {
      page,
      pageSize,
      ...filters,
      ...sort,
    };

    return `?${qs.stringify(query)}`;
  }

  return '';
};

export const getDefaultSearchQuery = () : QueryParams => ({
  page: 0,
  pageSize: 10,
});

export const decodeBlurHash = (blurHash: string, width: number, height: number): string => {
  try {
    const index = blurHash.lastIndexOf('||');

    const hash = (index >= 0 ? blurHash.substr(0, index) : blurHash).trim();
    const dimensions = index >= 0 ? blurHash.substr(index + 2) : null;
    let w = width;
    let h = height;

    if (dimensions) {
      const [w1, h1] = dimensions.split(':');
      w = parseInt(w1, 10) * 50;
      h = parseInt(h1, 10) * 50;
    }

    const pixels = decode(hash, w, h, 1);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imageData = ctx?.createImageData(w, h);
    imageData?.data.set(pixels);
    ctx?.putImageData(imageData as ImageData, 0, 0);
    const data = canvas.toDataURL();

    return data;
  } catch (e) {
    // error in decoding blur hash
  }

  return '';
};

export const getMedia = (media: Media | undefined):
  {
    url: string | undefined;
    width: number | undefined;
    height: number | undefined;
  } => {
  const url = media?.formats?.thumbnail?.url || media?.url;
  const width = media?.formats?.thumbnail?.width;
  const height = media?.formats?.thumbnail?.height;

  return {
    url,
    width,
    height,
  };
};
