import React, { useEffect, useState } from 'react';
import {
  ThemeContext,
  Box,
  Heading,
} from 'grommet';

import { useHistory } from 'react-router-dom';
import { formTheme } from '../../assets/styles/themes';

import FreeSubscription from './FreeSubscription';
import PaidSubscription from './PaidSubscription';
import { useFetchIdentityInterval } from '../../app/hooks';
import { RouterPath } from '../../app/router';

type CardSelect = 'free' | 'paid' | null;

const Subscription = (): React.ReactElement => {
  const [cardSelected, setCardSelected] = useState<CardSelect>(null);
  const { identity } = useFetchIdentityInterval(10000);
  const history = useHistory();

  useEffect(() => {
    const subs = identity?.subscription;
    if (subs?.type === 'Paid') {
      history.replace(`${RouterPath.ROUTE_HOME}`);
    }
  }, [identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="large">
        <Box align="center">
          <Heading level="3">Select your plan</Heading>
        </Box>
        <Box justify="center" align="center" direction="row-responsive" gap="large">
          <FreeSubscription
            onSelect={() => setCardSelected('free')}
            disabled={cardSelected === 'paid'}
          />
          <PaidSubscription
            onSelect={() => setCardSelected('paid')}
            disabled={cardSelected === 'free'}
          />
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};
export default Subscription;
