import isValidEmail from 'validator/es/lib/isEmail';
import isSPassword from 'validator/es/lib/isStrongPassword';

export const isEmail = (message: string) => (value: any): string | undefined => {
  if (value && value.trim()) {
    return isValidEmail(value) ? undefined : message;
  }

  return message;
};

export const isStrongPassword = (message: string) => (value: any): string | undefined => {
  if (isSPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
  })) {
    return undefined;
  }

  return message;
};
