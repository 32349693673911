import { User } from '../types';

import SessionStorage from './session-storage';
import LocalStorage from './local-storage';

const sessionStorage = new SessionStorage();
const localStorage = new LocalStorage();

export default {
  sessionStorage,
  localStorage,

  set user(data: User) {
    localStorage.user = data;
  },

  get user(): User {
    return localStorage.user;
  },

  set token(token: string | null | undefined) {
    localStorage.token = token;
  },

  get token(): string | null | undefined {
    return localStorage.token;
  },

  // set userState(data: UserLocalState) {
  //   localStorage.userState = data;
  // },

  // get userState(): UserLocalState {
  //   return localStorage.userState;
  // },

  set lastActiveAt(time: number) {
    localStorage.lastActiveAt = time;
  },

  get lastActiveAt(): number {
    return localStorage.lastActiveAt;
  },

  removeSessions(): void {
    localStorage.removeSessions();
  },
};
