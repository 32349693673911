import React, { useEffect } from 'react';

import {
  ThemeContext,
  Form,
  FormField,
  TextInput,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Paragraph,
  Anchor,
  Spinner,
  Text,
} from 'grommet';
import { LinkNext, SubtractCircle } from 'grommet-icons';
import {
  useForm,
  UseFormRegister,
  UseFormWatch,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../../assets/styles/themes';
import { RouterPath } from '../../../app/router';
import { ErrorMessage, ValidationMessage } from '../../../components';
import { isStrongPassword } from '../../../utils/validations';
import { useResetPasswordMutation } from '../authApiSlice';
import PasswordPolicy from '../PasswordPolicy';

interface FormFields {
  password: string;
  passwordConfirmation: string;
}

const validationSchema = (
  register: UseFormRegister<FormFields>,
  watch: UseFormWatch<FormFields>,
  t: any,
): { [key: string]: UseFormRegisterReturn } => {
  const passwordTyped = watch('password', '');

  return {
    password: register('password', {
      required: t('password.validations.required'),
      validate: isStrongPassword(t('password.validations.strong')),
    }),
    passwordConfirmation: register('passwordConfirmation', {
      required: t('confirmPassword.validations.required'),
      validate: (value: string): string | undefined => {
        if (value === passwordTyped) {
          return undefined;
        }

        return t('confirmPassword.validations.mismatch');
      },
    }),
  };
};

const ResetPassword = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormFields>();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const [resetPassword, {
    error, isLoading, isSuccess,
  }] = useResetPasswordMutation();

  const validation = validationSchema(register, watch, t);

  const resetPasswordHandler = (values: FormFields): void => {
    const { search } = location;
    const code = new URLSearchParams(search).get('code') as string;
    const payload = {
      code,
      ...values,
    };
    resetPassword(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      history.replace(RouterPath.ROUTE_LOGIN);
    }
  }, [isSuccess, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad={{ vertical: 'xlarge', horizontal: 'small' }} direction="row-responsive" flex="grow" justify="center">
        <Card
          width={{ min: '360px', max: '476px' }}
          pad="large"
          style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading level="3" margin="none" color="black" alignSelf="center">Create new password</Heading>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(resetPasswordHandler)}>
              <Box margin={{ top: 'medium' }}>
                <FormField
                  name="password"
                  htmlFor="password"
                  label="Password"
                  error={
                    errors.password
                    && (
                      <ValidationMessage message={errors.password.message} />
                    )
                  }
                >
                  <TextInput
                    id="password"
                    type="password"
                    maxLength={50}
                    {...validation.password}
                  />
                </FormField>
                <FormField
                  name="passwordConfirmation"
                  htmlFor="passwordConfirmation"
                  label="Confirm Password"
                  error={
                    errors.passwordConfirmation
                    && (
                      <ValidationMessage message={errors.passwordConfirmation.message} />
                    )
                  }
                >
                  <TextInput
                    id="passwordConfirmation"
                    type="password"
                    maxLength={50}
                    {...validation.passwordConfirmation}
                  />
                </FormField>
              </Box>
              <PasswordPolicy />

              {error && <ErrorMessage error={error} />}
              <Box align="center" direction="row" justify="between" pad={{ top: 'large' }}>
                <Button
                  type="submit"
                  size="medium"
                  plain
                  alignSelf="center"
                  label={<Text color="brand">Back</Text>}
                  reverse
                  onClick={() => { history.replace(RouterPath.ROUTE_LOGIN); }}
                />
                <Button
                  type="submit"
                  size="medium"
                  alignSelf="center"
                  primary
                  disabled={isLoading}
                  label="Reset Password"
                  icon={isLoading ? <Spinner /> : <LinkNext />}
                  reverse
                />
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default ResetPassword;
