import React, { useEffect } from 'react';
import {
  ThemeContext,
  Layer,
  Box,
  Button,
  Form,
  FormField,
  TextInput,
  Heading,
  Spinner,
  CardHeader,
  Card,
  Text,
} from 'grommet';
import { FiArrowRight } from 'react-icons/fi';
import { useForm, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../assets/styles/themes';
import {
  useFocus,
  useCurrentUserPresentation,
} from '../../app/hooks';
import { ErrorMessage } from '../../components';

type UserPresentationSchema = {
  name: string;
}

export type ActionType = 'success' | 'close' | 'abort';

const validationSchema = (
  register: UseFormRegister<UserPresentationSchema>,
  t: any,
): { [key: string]: UserPresentationSchema } => ({
  name: register('name', {
    required: t('name.validations.required'),
    validate: (value: string) => !!value.trim() || t('name.validations.required'),
  }),
});

type Prop = {
  OkLabel?: string;
  onClose: (actionType?: ActionType) => void;
}

export const NewUserPresentation: React.FC<Prop> = ({ OkLabel, onClose }): React.ReactElement => {
  const [inputRef, setInputFocus] = useFocus();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserPresentationSchema>();
  const validations = validationSchema(register, t);
  const {
    saveNewCurrentUserPresentation,
    saveNewCurrentUserPresentationState,
  } = useCurrentUserPresentation();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
  } = saveNewCurrentUserPresentationState;

  useEffect(() => {
    setInputFocus();
  }, [inputRef]);

  useEffect(() => {
    if (isSuccess) {
      onClose?.('success');
    }
  }, [isSuccess]);

  const handleSave = (values: UserPresentationSchema): void => {
    const { name } = values;

    saveNewCurrentUserPresentation(name.trim());
  };

  return (
    <ThemeContext.Extend value={formTheme}>
      <Layer
        style={{
          padding: '50px',
          background: 'transparent',
          justifyContent: 'center',
        }}
        onAbort={() => onClose?.('abort')}
        // onClickOutside={() => onClose?.('close')}
      >
        <Card
          style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
          width={{ min: '360px', max: '100%' }}
          pad="large"
          alignSelf="center"
          justify="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading
              level="3"
              margin={{ bottom: 'medium', top: 'none' }}
              alignSelf="center"
            >
              Create Presentation
            </Heading>
          </CardHeader>
          <Form onSubmit={handleSubmit(handleSave)}>
            <FormField
              name="name"
              htmlFor="name"
              label="Presentation Name"
              error={errors.name?.message}
            >
              <TextInput
                id="name"
                maxLength={50}
                size="xsmall"
                {...validations.name}
                ref={inputRef}
              />
            </FormField>
            {error && <ErrorMessage error={error} />}
            <Box direction="row" justify="between" gap="small">
              <Button
                size="medium"
                secondary
                label={<Text color="brand">Cancel</Text>}
                onClick={() => onClose?.('close')}
                disabled={isLoading}
              />
              <Button
                size="medium"
                type="submit"
                primary
                label={OkLabel || 'Continue'}
                disabled={isLoading}
                icon={isLoading ? <Spinner /> : <FiArrowRight size="24px" />}
                reverse
              />
            </Box>
          </Form>
        </Card>
      </Layer>
    </ThemeContext.Extend>
  );
};

export default NewUserPresentation;
