import React, { useEffect, useState } from 'react';
import {
  Box, Button, CheckBoxGroup, Heading, Paragraph, Spinner, Text, ThemeContext, TextInput,
} from 'grommet';

import { useHistory } from 'react-router-dom';
import { formTheme } from '../../assets/styles/themes';

import {
  useFetchQuestions,
  useIdentity,
} from '../../app/hooks';

import {
  useUpdateOnboardingMutation,
} from './onboardingApiSlice';

import { RouterPath } from '../../app/router';
import { processError } from '../../utils';

const Onboarding = (): React.ReactElement => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [selected, setSelected] = useState<(string[] | string)[]>([]);

  const { identity, fetchIdentity } = useIdentity();

  const history = useHistory();

  const { questions, fetchQuestions, isLoading: isQuestionLoading } = useFetchQuestions();
  const [updateOnboarding, updateOnboardingHooks] = useUpdateOnboardingMutation();

  useEffect(() => {
    const isOnboardingCompleted = identity?.isOnboardingCompleted;
    if (isOnboardingCompleted) {
      history.replace(`${RouterPath.ROUTE_HOME}`);
    }
  }, [identity, history]);

  useEffect(() => {
    fetchIdentity();
  }, [fetchIdentity]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  useEffect(() => {
    if (updateOnboardingHooks.isSuccess) {
      fetchIdentity();
      // history.replace(`${RouterPath.ROUTE_HOME}`);
    }
    if (updateOnboardingHooks.isError) {
      setError(processError(updateOnboardingHooks.error).message);
    }
  }, [updateOnboardingHooks.isSuccess, updateOnboardingHooks.isError]);

  const onChange = (event: any): void => {
    if ((event.value && event.value.length > 0) || event.target.value) {
      const questionType = questions[currentQuestion].type;
      let responseValue: any = '';

      switch (questionType) {
        case 'single':
          responseValue = [event.value[event.value.length - 1]];
          break;
        case 'multi':
          responseValue = [...event.value];
          break;
        case 'input':
          responseValue = event.target.value;
          break;
        default:
      }

      setSelected((state) => {
        const newState = [...state];
        newState[currentQuestion] = responseValue;
        return newState;
      });
    } else {
      setSelected((state) => {
        const newState = [...state];
        newState[currentQuestion] = [];
        return newState;
      });
    }
  };

  const handleNext = async (): Promise<void> => {
    if (!selected[currentQuestion] || selected[currentQuestion].length === 0) {
      const questionType = questions[currentQuestion].type;
      if (questionType === 'input') {
        setError('Please enter a value');
      } else {
        setError('Please select an option');
      }
      return;
    }
    setError(null);
    if (currentQuestion < questions.length - 1) {
      if (!selected[currentQuestion + 1]) {
        setSelected((state) => {
          const newState = [...state];
          const questionType = questions[currentQuestion + 1].type;
          newState[currentQuestion + 1] = questionType === 'input' ? '' : [];
          return newState;
        });
      }
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const payload: any[] = [];
      for (let i = 0; i < questions.length; i += 1) {
        const questionType = questions[i].type;
        let answer = '';
        if (questionType === 'single' || questionType === 'multi') {
          answer = (selected[i] as string[]).join(', ');
        } else {
          answer = selected[i] as string;
        }
        payload.push({ question: questions[i].question, answer });
      }
      updateOnboarding({
        onboarding: payload,
      });
    }
  };

  const handlePrevious = (): void => {
    if (currentQuestion > 0) {
      setError('');
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="large">
        <Box align="center">
          <Heading level="3">
            {isQuestionLoading && 'Loading...'}
            {!isQuestionLoading && 'Tell us about yourself'}
          </Heading>
        </Box>
        <Box justify="center" align="center" direction="column" gap="large">
          {questions[currentQuestion]
          && (
          <Box
            gap="small"
            pad="medium"
            style={{
              background: 'white',
              width: '50%',
              minWidth: '300px',
              borderRadius: '10px',
            }}
          >
            <Heading level="6" margin="none">
              Question {currentQuestion + 1} / {questions.length}
            </Heading>
            <Heading level="4" margin={{ top: 'none', bottom: 'small' }}>
              { questions[currentQuestion].question }
            </Heading>
            { ['single', 'multi'].includes(questions[currentQuestion].type)
              && (
                <CheckBoxGroup
                  options={questions[currentQuestion].options}
                  value={selected[currentQuestion] as string[]}
                  onChange={onChange}
                />
              )}
            { questions[currentQuestion].type === 'input'
              && (
                <TextInput
                  value={selected[currentQuestion] as string}
                  onChange={onChange}
                />
              )}
            <Box direction="row" gap="small" align="stretch" margin={{ top: 'medium' }}>
              <Button
                onClick={handlePrevious}
                secondary
                size="medium"
                label={<Text color="brand">Previous</Text>}
              />
              <Button
                onClick={handleNext}
                disabled={updateOnboardingHooks.isLoading}
                primary
                reverse
                size="medium"
                label={<Text color="white">Continue</Text>}
                icon={
                  updateOnboardingHooks.isLoading
                    ? <Spinner size="xsmall" /> : undefined
                }
              />
            </Box>
            {error
              && (
              <Paragraph size="small" color="status-error">
                { error }
              </Paragraph>
              )}
          </Box>
          )}
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};
export default Onboarding;
