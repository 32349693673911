import React from 'react';

// import Router from './app/routes';
import ReactGA from 'react-ga';
import { Router } from './app/router';
import ImagePreview from './features/imagePreview';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE as string);

function App(): React.ReactElement {
  return (
    <>
      <ImagePreview />
      <Router />
    </>
  );
}

export default App;
