import {
  useCallback, useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { ResponsiveContext } from 'grommet';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppDispatch, RootState } from './store';
import { resetSearchQuery } from '../features/searchQuery/searchQuerySlice';
import { setToken, setUser, signOut } from '../features/auth/authSlice';
import {
  useAuthenticateGoogleMutation,
  useAuthenticateLocalMutation,
  useSignUpLocalMutation,
} from '../features/auth/authApiSlice';
import { useLazyIdentityQuery } from '../features/users/usersApiSlice';

import { setQuestions } from '../features/onboarding/onboardingSlice';
import {
  useLazyFetchQuestionsQuery,
  useUpdateOnboardingMutation,
} from '../features/onboarding/onboardingApiSlice';

import {
  useAddUserPresentationMutation,
  useLazyFetchUserPresentationQuery,
  useLazyFetchUserPresentationSlidesQuery,
  useUpdateUserPresentationMutation,
} from '../features/userPresentations/userPresentationsApiSlice';
import {
  useAddUserSlidesMutation,
  useDeleteUserSlideMutation,
} from '../features/userSlides/userSlidesApiSlice';
import {
  addUserSlides,
  removeUserSlide,
  resetUserPresentation,
  setUserPresentation,
  setUserSlides,
  setUserSlideState,
  updateUserSlides,
} from '../features/userPresentations/userPresentationsSlice';

import storage from '../storage';
import {
  CreateUserSlidesPayload,
  Identifier,
  Presentation,
  QueryParams,
  Slide,
  UserPresentationCreatePayload,
  UserSlide,
} from '../types';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Sign up api wrapper to store user and token into state and store
export const useSignUpLocal = () => {
  const hookArgs = useSignUpLocalMutation();
  const dispatch = useAppDispatch();

  const [, arg] = hookArgs;

  if (arg.isSuccess && arg.data) {
    const { data: { user, jwt } } = arg;

    dispatch(setUser(user));
    dispatch(setToken(jwt));
  }

  return hookArgs;
};

// Sign in api wrapper to store user and token into state and store
export const useSignInLocal = () => {
  const hookArgs = useAuthenticateLocalMutation();
  const dispatch = useAppDispatch();
  const [, arg] = hookArgs;

  if (arg.isSuccess && arg.data) {
    const { data: { user, jwt } } = arg;

    dispatch(setUser(user));
    dispatch(setToken(jwt));
  }
  return hookArgs;
};

export const useSignInGoogle = () => {
  const hookArgs = useAuthenticateGoogleMutation();
  const dispatch = useAppDispatch();
  const [, arg] = hookArgs;

  if (arg.isSuccess && arg.data) {
    const { data: { user, jwt } } = arg;
    if (!user.blocked) {
      dispatch(setUser(user));
      dispatch(setToken(jwt));
    }
  }
  return hookArgs;
};

export const useSignOut = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const signOutCallback = useCallback((redirectTo = '/login') => {
    dispatch(signOut());
    dispatch(resetUserPresentation());
    dispatch(resetSearchQuery());

    history.replace(redirectTo);

    return Promise.resolve();
  }, [dispatch, history]);

  return signOutCallback;
};

export const useAuthenticated = () => {
  const authenticated = useAppSelector((state) => !!state.auth.token);

  return { authenticated };
};

export const useIdentity = () => {
  const identity = useAppSelector((state) => state.auth.user);
  const [fetchIdentity, { data }] = useLazyIdentityQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) dispatch(setUser(data));
  }, [data, dispatch]);

  return { identity, fetchIdentity };
};

type SubscriptionType = 'free' | 'paid';

export const useSubscription = () => {
  const subscription = useAppSelector<SubscriptionType>(
    (state) => {
      if (state.auth.user?.subscription?.status === 'paid') {
        return 'paid';
      }

      return 'free';
    },
  );

  return { subscription };
};

export const useFetchQuestions = () => {
  const questions = useAppSelector((state) => state.onboarding.questions);
  const [fetchQuestions, { data, isLoading, isError }] = useLazyFetchQuestionsQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) dispatch(setQuestions(data));
  }, [data, dispatch]);

  return {
    questions, fetchQuestions, isError, isLoading,
  };
};

export const useInitials = (props: { text: string }): string => {
  const [initial, setInitial] = useState('');
  const { text } = props;

  useEffect(() => {
    const arr = text.split(' ').map((item) => item.trim());
    let data = arr[0][0];

    if (arr[1]) {
      data += arr[1][0];
    }

    setInitial((data || '').toUpperCase());
  }, [text]);

  return initial;
};

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus: any = useCallback(() => {
    if (htmlElRef?.current) {
      (htmlElRef?.current as any).focus();
    }
  }, []);

  return [htmlElRef, setFocus];
};

export const useNumberOfSlides = (): number => {
  const size = useContext(ResponsiveContext);
  let num = 1;

  switch (size) {
    case 'xxlarge':
      num = 4;
      break;
    case 'xlarge':
      num = 3;
      break;
    case 'large':
      num = 3;
      break;
    case 'medium':
      num = 2;
      break;
    default:
      num = 1;
  }

  return num;
};

export const useOnScreen = (ref: React.RefObject<any>): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect(); };
  }, []);

  return isIntersecting;
};

type SlideSize = {
  items: { [key: string]: number };
}

export const useCurrentUserPresentation = () => {
  const dispatch = useAppDispatch();
  const { presentation, slides } = useAppSelector((state) => state.userPresentations);
  const [
    fetchUserPresentation,
    fetchCurrentUserPresentationState,
  ] = useLazyFetchUserPresentationQuery();
  const [addPresentation, addState] = useAddUserPresentationMutation();
  const [updatePresentation, updateState] = useUpdateUserPresentationMutation();
  const [fetchUserSlides, fetchCurrentUserSlidesState] = useLazyFetchUserPresentationSlidesQuery();
  const [addCurrentUserSlides, addCurrentUserSlidesState] = useAddUserSlidesMutation();
  const [deleteUserSlideByRef, deleteCurrentUserSlideState] = useDeleteUserSlideMutation();

  const saveCurrentUserPresentationLocal = (id: Identifier): void => {
    storage.localStorage.currentUserPresentation = id;
  };

  const removeCurrentUserPresentationLocal = (): void => {
    dispatch(resetUserPresentation());
    storage.localStorage.currentUserPresentation = null;
  };

  const fetchCurrentUserPresentation = (): void => {
    const id = storage.localStorage.currentUserPresentation;

    if (id) {
      fetchUserPresentation(id);

      const payload: QueryParams = {
        page: 1,
        pageSize: 200,
        filters: {
          id,
        },
      };

      fetchUserSlides(payload);
    }
  };

  const saveNewCurrentUserPresentation = (name: string): void => {
    addPresentation({ name }).then((res: any) => {
      dispatch(setUserSlides([]));
    });
  };

  const saveCurrentUserPresentation = (payload: UserPresentationCreatePayload): void => {
    const id = storage.localStorage.currentUserPresentation;

    if (id) {
      updatePresentation({ id, ...payload });
    } else {
      addPresentation(payload);
    }
  };

  const fetchCurrentUserSlides = (params: QueryParams): void => {
    const id = storage.localStorage.currentUserPresentation;

    if (id) {
      const payload: QueryParams = {
        ...params,
        filters: {
          ...params.filters,
          id,
        },
      };

      fetchUserSlides(payload);
    }
  };

  const addSlidesToCurrentPresentation = (
    ss: Slide[],
  ): Promise<boolean | Error> => new Promise((resolve, reject) => {
    const id = storage.localStorage.currentUserPresentation;
    if (id) {
      const maxSlides = 100;

      if (slides.length >= maxSlides) {
        return reject(
          new Error(
            'Looks like you have exceeded the 100 slides or 10MB limit for the presentation.',
          ),
        );
      }

      const slideSize: SlideSize = {
        items: {},
      };

      slides.forEach((us) => {
        const s = us.slide as Slide;

        slideSize.items[s.id as string] = s.image.size || 0;
      });

      const totalImageSize = Object.values(slideSize.items).reduce((prev, curr) => {
        const total = prev + curr;

        return total;
      }, 0);

      /* old logic
      const totalImageSize = slides.reduce((prev, curr: UserSlide) => {
        const total = prev + ((curr.slide as Slide)?.image?.size || 0);

        return total;
      }, 0);
      */

      const totalSize = (totalImageSize * 1024) / 3; // in bytes
      const sizeLimit = 10 * 1024 * 1024; // 10MB

      if ((totalSize + 5000) > sizeLimit) {
        return reject(
          new Error(
            `Presentation size limit of 10MB has exceeded.
            <br />Current size is ${Math.round(totalSize / (1024 * 1024))}MB (approx.)`,
          ),
        );
      }

      const max = Math.max(...slides.map((slide) => slide.displayOrder));
      const displayOrder = (Number.isFinite(max) ? max : 0) + 1;

      const arr: UserSlide[] = [];

      ss.forEach((slide, index) => {
        arr.push({
          tmpId: `tmp_${Date.now()}`,
          displayOrder: displayOrder + index,
          presentation: (slide.presentation as Presentation)?.id as Identifier,
          slide,
        });
      });

      dispatch(addUserSlides(arr));

      const payload: CreateUserSlidesPayload = {
        userPresentation: id,
        slides: arr.map((item) => ({ ...item, slide: (item.slide as Slide)?.id as Identifier })),
      };

      addCurrentUserSlides(payload);
    }

    return resolve(true);
  });

  const deleteCurrentUserSlide = (userSlide: UserSlide): void => {
    dispatch(removeUserSlide(userSlide));
    deleteUserSlideByRef(userSlide.tmpId as string);
  };

  useEffect(() => {
    const data = addState.data || updateState.data;

    if (data) {
      dispatch(setUserPresentation((data)));
      storage.localStorage.currentUserPresentation = data?.id;
    }
  }, [addState.data, updateState.data]);

  useEffect(() => {
    dispatch(setUserPresentation((fetchCurrentUserPresentationState.data)));
  }, [fetchCurrentUserPresentationState.data]);

  useEffect(() => {
    dispatch(setUserSlideState(fetchCurrentUserSlidesState));
    if (fetchCurrentUserSlidesState.data) {
      dispatch(setUserSlides(fetchCurrentUserSlidesState.data.results));
    }
  }, [fetchCurrentUserSlidesState]);

  useEffect(() => {
    if (addCurrentUserSlidesState.data) {
      dispatch(updateUserSlides(addCurrentUserSlidesState.data || []));

      if (!presentation?.image) {
        const { image, blurHash } = { ...addCurrentUserSlidesState.data[0]?.slide as Slide };

        saveCurrentUserPresentation({
          name: presentation?.name as string,
          image: image?.id,
          blurHash,
        });
      }
    }
  }, [addCurrentUserSlidesState]);

  return {
    saveCurrentUserPresentationLocal,
    removeCurrentUserPresentationLocal,
    saveNewCurrentUserPresentation,
    saveNewCurrentUserPresentationState: addState,
    saveCurrentUserPresentation,
    saveCurrentUserPresentationState: {
      data: addState.data || updateState.data,
      error: addState.error || updateState.error,
      isError: addState.isError || updateState.isError,
      isLoading: addState.isLoading || updateState.isLoading,
      isSuccess: addState.isSuccess || updateState.isSuccess,
      status: addState.status || updateState.status,
    },
    fetchCurrentUserPresentation,
    fetchCurrentUserPresentationState,
    fetchCurrentUserSlides,
    fetchCurrentUserSlidesState,
    addSlidesToCurrentPresentation,
    addCurrentUserSlidesState,
    deleteCurrentUserSlide,
    deleteCurrentUserSlideState,
  };
};

export const useFetchIdentityInterval = (delay: number) => {
  const { identity, fetchIdentity } = useIdentity();
  useEffect(() => {
    fetchIdentity();
    const interval = setInterval(() => {
      fetchIdentity();
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, [fetchIdentity, delay]);
  return { identity };
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
