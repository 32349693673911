import React, { useState } from 'react';
import {
  Box,
  DropButton,
} from 'grommet';
import {
  CgMenuGridO,
} from 'react-icons/cg';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import {
  useCategoriesQuery,
} from '../categories/categoriesApiSlice';
import { Category } from '../../types';

import { RouterPath } from '../../app/router';
import CategoryList from './CategoryList';
import { Skeleton } from '../../components';

const CategoryMenu = styled(Box)`
  border: 1px solid #ececec;
  padding: 10px;
`;
const CategoryDrop = (): React.ReactElement => {
  const [categoryId, setCategoryId] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const { data, isLoading, isSuccess } = useCategoriesQuery();
  const history = useHistory();

  const getChildren = (c: Category): Category[] => data?.filter(
    (item: Category) => item.parent?.id === c.id,
  ) as Category[];

  const onClickHandler = (c: Category): void => {
    if (!getChildren(c).length) {
      setOpenDrop(false);
      history.push(`${RouterPath.ROUTE_DECK}?category=${c.id as string}`);
    }
  };
  return (
    <Box
      onMouseEnter={() => {
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
        setOpenDrop(true); setCategoryId('');
      }}
      onMouseLeave={() => setOpenDrop(false)}
      style={{ position: 'relative' }}
    >
      <Box
        onClick={() => { setOpenDrop(true); setCategoryId(''); }}
        focusIndicator={false}
        height="xxsmall"
        style={{
          position: 'absolute',
          width: '100%',
        }}
      />

      <DropButton
        open={openDrop}
        icon={<CgMenuGridO size="30px" />}
        plain
        dropProps={{
          elevation: 'xsmall',
          round: 'none',
          margin: { top: 'large' },
          background: 'white',
          stretch: false,
          onClickOutside: () => setOpenDrop(false),
        }}
        color="dark-2"
        label="Categories"
        dropContent={
          isLoading && !isSuccess ? (
            <Box gap="10px" width="200px" pad="small" align="center">
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Box>
          ) : (
            <CategoryMenu border direction="row">
              <Box>
                <CategoryList
                  categories={data?.filter((item: Category) => !item.parent) as Category[]}
                  onMouseEnterHandler={(c: Category) => {
                    if (getChildren(c).length) {
                      setCategoryId(c.id as string);
                    } else {
                      setCategoryId('');
                    }
                  }}
                  onClickHandler={onClickHandler}
                  hasChildren={(c: Category) => !!getChildren(c).length}
                />
              </Box>
              {
                categoryId ? (
                  <Box style={{ borderLeft: '1px solid #eee' }}>
                    <CategoryList
                      categories={data?.filter(
                        (item: Category) => item.parent?.id === categoryId,
                      ) as Category[]}
                      onClickHandler={onClickHandler}
                      hasChildren={(c: Category) => !!getChildren(c).length}
                    />
                  </Box>
                ) : null
              }
            </CategoryMenu>
          )
        }
      />
    </Box>
  );
};
export default CategoryDrop;
