import React from 'react';

import { Text } from 'grommet';

interface ValidationErrorType {
  message?: string;
}

export const ValidationMessage = (props: ValidationErrorType): JSX.Element => {
  const { message } = props;

  return (
    <Text size="xsmall" color="status-error">{message}</Text>
  );
};

ValidationMessage.defaultProps = {
  message: undefined,
} as ValidationErrorType;

export default ValidationMessage;
