export const ROUTE_ROOT = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_USER_SETTINGS = '/settings';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_FORGOT_PASSWORD_INSTRUCTIONS = '/forgot-password-instruction';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_TERMS_N_CONDITIONS = 'https://www.upplo.co.uk/terms/';
export const ROUTE_PRIVACY_POLICY = 'https://www.upplo.co.uk/privacy-policy/';
export const ROUTE_REGISTER = '/register';
export const ROUTE_VERIFY_EMAIL = '/verify-email';
export const ROUTE_VERIFY_EMAIL_STATUS = '/verify-email-status';
export const ROUTE_HOME = '/';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_DECK = '/deck';
export const ROUTE_GOOGLE_SSO_CALLBACK = '/auth/google/callback';
export const ROUTE_SUBSCRIPTION = '/subscribe';
export const ROUTE_SUBSCRIPTION_SUCCESS = '/subscribe/success';
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_ACCEPT_TNC = '/accept-tnc';
export const ROUTE_USER_PRESENTATIONS = '/user/presentations';
export const ROUTE_USER_PRESENTATION_DOWNLOAD = '/user-presentations/:presentationId/download';
