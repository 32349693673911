import React, { useState } from 'react';
import {
  Image,
} from 'grommet';

import { decodeBlurHash } from '../../utils';

import CardImg from '../../assets/images/default-image.png';

type Props = {
  width?: string;
  height?: string;
  image?: {
    blurHash?: string;
    url?: string;
    width?: number;
    height?: number;
  }
}

export const StyledImage = (props: Props): JSX.Element => {
  const {
    width,
    height,
    image,
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = (): void => {
    setImageLoaded(true);
  };

  return (
    <>
      <Image
        fallback={CardImg}
        src={image?.url || CardImg}
        // src={slide.image?.url}
        fill
        onLoad={onLoad}
        style={{
          height: 'auto', width: width || '100%', background: 'rgb(118 144 166 / 20%)',
        }}
        hidden={!!image?.blurHash && !imageLoaded}
      />
      {
        !imageLoaded && image?.blurHash
        && (
          <Image
            src={
              decodeBlurHash(
                image?.blurHash,
                image?.width as number,
                image?.height as number,
              )
            }
            fill
            style={{ height: height || '185px', width: width || '100%', background: 'rgb(118 144 166 / 20%)' }}
            fit="cover"
          />
        )
      }
    </>
  );
};

StyledImage.defaultProps = {
  width: '100%',
  height: '185px',
  image: {
    blurHash: '',
    url: '',
    width: 400,
    height: 255,
  },
};

export default StyledImage;
