import React from 'react';
import {
  Box, Nav,
  Text,
  Button,
  Avatar,
} from 'grommet';

import { AiFillFilePpt } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import { IoLogOut } from 'react-icons/io5';
import { CgMenuGridO } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import UserMenu from '../UserMenu';
import AppSlidebar from './AppSlidebar';
import NavButton from './NavButtons';
import {
  ROUTE_USER_PRESENTATIONS,
  ROUTE_USER_SETTINGS,
} from '../../app/router/route-path.constants';
import {
  useIdentity, useInitials, useSignOut,
} from '../../app/hooks';
import { User } from '../../types';

type ClickedButton = 'category' | 'logout';

interface Props {
  width: string;
  height: string;
  onClickHandler: (cb: ClickedButton) => void;
  onCloseHandler: () => void;
}
export const LeftBar = (props: Props): JSX.Element | null => {
  const history = useHistory();
  const { identity } = useIdentity();
  const signOut = useSignOut();

  const { firstName, lastName } = identity as User;
  const data = useInitials({ text: firstName && lastName ? `${firstName} ${lastName}` : '' });
  const {
    onClickHandler, onCloseHandler, width, height,
  } = props;
  return (
    <>
      <AppSlidebar
        onCloseHandler={onCloseHandler}
        width={width}
        height={height}
        header={(
          <Box
            pad={{ horizontal: 'large', vertical: 'medium' }}
            background="light-1"
            direction="row"
            alignContent="between"
          >
            <Box align="center" justify="center" direction="row">
              <Avatar
                align="center"
                flex={false}
                justify="center"
                overflow="hidden"
                round="full"
                background={{ dark: 'false', color: 'border' }}
                size="40px"
              >
                <Text weight="bold" color="brand">{data}</Text>
              </Avatar>
              <Text margin={{ left: '10px' }}>{ firstName } { lastName }</Text>
            </Box>
          </Box>

        )}
        body={(
          <Nav gap="small" pad={{ horizontal: 'large', vertical: 'none' }}>
            <NavButton
              onClick={() => { onCloseHandler(); history.push(ROUTE_USER_PRESENTATIONS); }}
              body={(
                <>
                  <Box align="center" justify="center" direction="row">
                    <Box>
                      <AiFillFilePpt size="30px" />
                    </Box>
                    <Box flex="grow" margin={{ left: 'medium' }}>
                      <Text size="medium">My Presentations</Text>
                    </Box>
                  </Box>
                </>
              )}
            />
            <NavButton
              onClick={() => { onClickHandler('category'); }}
              body={(
                <>
                  <Box align="center" justify="center" direction="row">
                    <Box>
                      <CgMenuGridO size="30px" />
                    </Box>
                    <Box flex="grow" margin={{ left: 'medium' }}>
                      <Text size="medium">Categories</Text>
                    </Box>
                  </Box>
                </>
              )}
            />
          </Nav>
        )}
        footer={(
          <Nav gap="small" pad={{ horizontal: 'large', vertical: 'medium' }} background="light-1">
            <NavButton
              onClick={() => { onCloseHandler(); history.push(ROUTE_USER_SETTINGS); }}
              body={(
                <>
                  <Box align="center" justify="center" direction="row">
                    <Box>
                      <RiUserSettingsFill size="30px" />
                    </Box>
                    <Box flex="grow" margin={{ left: 'medium' }}>
                      <Text size="medium">Settings</Text>
                    </Box>
                  </Box>
                </>
              )}
            />
            <NavButton
              onClick={() => { signOut(); }}
              body={(
                <>
                  <Box align="center" justify="center" direction="row">
                    <Box>
                      <IoLogOut size="30px" />
                    </Box>
                    <Box flex="grow" margin={{ left: 'medium' }}>
                      <Text size="medium">Sign Out</Text>
                    </Box>
                  </Box>
                </>
            )}
            />
          </Nav>
        )}
      />
    </>
  );
};
export default LeftBar;
