import React, { useState } from 'react';
import {
  Button,
} from 'grommet';

import {
  Previous,
  Next,
} from 'grommet-icons';
import styled from 'styled-components';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import storage from '../../storage';
import {
  useIdentity,
  useCurrentUserPresentation,
  useAppDispatch,
} from '../../app/hooks';
import { Slide } from '../../types';
import { SlideCard } from '../../components';

import UpgradeSubscription from '../../components/upgradeSubscription';
import NewUserPresentation, { ActionType } from '../userPresentations/NewUserPresentation';
import { setImages } from '../imagePreview/imagePreviewSlice';
import LimitExceedWarningDialog,
{ ConfirmationValueType } from '../userPresentations/LimitExceedWarningDialog';

const SliderNav = styled(Button)`
  position: absolute;
  top: calc(50% - 40px);
  border-radius: 100%;
  background: white;
  border-width: 4px;
  box-shadow: 0 0 10px #0000004f;
  transform: scale(0.6);
`;

interface Props {
  slides: Slide[];
  isPaid: boolean;
}

const responsive = {
  desktop: {
    breakpoint: { max: 1990, min: 1600 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1600, min: 1200 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 1199, min: 0 },
    items: 1,
  },
};

const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <SliderNav
      onClick={onClick}
      style={{
        right: '-10px',
      }}
      icon={<Next color="brand" />}
      type="button"
      disabled={false}
      plain={false}
      reverse
    />
  );
};

const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <SliderNav
      onClick={onClick}
      style={{
        left: '-10px',
      }}
      icon={<Previous color="brand" />}
      type="button"
      disabled={false}
      plain={false}
      reverse
    />
  );
};

export const SlideCarousel = (props: Props): JSX.Element => {
  const { slides, isPaid } = props;
  const { identity } = useIdentity();
  const dispatch = useAppDispatch();
  const [showSubscription, setShowSubscription] = useState(false);

  const [showNewPresentationDialog, setShowNewPresentationDialog] = useState(false);
  const { addSlidesToCurrentPresentation } = useCurrentUserPresentation();
  const [showNewUserPresentationConfirmation, setShowNewUserPresentationConfirmation] = useState(false);

  const previewImages = slides.filter((slide) => slide.image)
    .map((slide: Slide) => {
      const { id, url } = slide.image;
      return { id: id as string, url };
    });

  const onSlidePreview = (s: Slide): void => {
    const index = slides.findIndex((slide) => slide.id === s.id);
    dispatch(setImages({ images: previewImages, index, show: true }));
  };

  // const onUpgradeSubscription = (s: Slide): void => {
  //   if (isPaid) setShowSubscription(isPaid);
  // };

  const processSizeLimitError = (): void => {
    setShowNewUserPresentationConfirmation(true);
  };

  const onCloseSizeLimitNotificationHandler = (e: ConfirmationValueType): void => {
    setShowNewUserPresentationConfirmation(false);
    if (e === 'yes') setShowNewPresentationDialog(true);
  };

  const addSlide = (data: Slide[]): void => {
    const { currentUserPresentation } = storage.localStorage;
    if (currentUserPresentation) {
      addSlidesToCurrentPresentation(data).catch(processSizeLimitError);
    } else {
      setShowNewPresentationDialog(true);
    }
  };

  const addSlideHandler = (slide: Slide): void => {
    if (identity?.subscription?.type === 'Paid' || !isPaid) {
      addSlide([slide]);
    } else {
      setShowSubscription(isPaid);
    }
  };

  return (
    <>
      {
        showNewUserPresentationConfirmation
        && (
          <LimitExceedWarningDialog
            onClick={(value) => onCloseSizeLimitNotificationHandler(value as ConfirmationValueType)}
          />
        )
      }
      {
        showNewPresentationDialog
        && (
          <NewUserPresentation
            onClose={() => setShowNewPresentationDialog(false)}
          />
        )
      }
      {showSubscription && (
        <UpgradeSubscription
          onCloseHandler={() => setShowSubscription(false)}
        />
      )}
      <Carousel
        swipeable
        draggable
        showDots={false}
        responsive={responsive}
        // ssr={true} // means to render carousel on server-side.
        // infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={1000}
        keyBoardControl
        // customTransition="all .5"
        // transitionDuration={500}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {slides.map((slide) => (
          <SlideCard
            key={slide.id}
            slide={slide}
            onView={onSlidePreview}
            onAdd={addSlideHandler}
          />
        ))}
      </Carousel>
    </>
  );
};

export default SlideCarousel;
