import React from 'react';

import {
  Box,
  Heading,
  Button,
  Paragraph,
} from 'grommet';
import { useHistory } from 'react-router-dom';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import styled from 'styled-components';
import { Category, Presentation } from '../../types';
import PresentationCardSkeleton from './PresentationCardSkeleton';
import CardRibbon from '../CardRibbon';
import StyledImage from '../StyledImage';
import { useIdentity } from '../../app/hooks';
import { RouterPath } from '../../app/router';
import { getMedia } from '../../utils';

type Props = {
  item: Presentation;
  category?: Category;
  allowNavigation?: boolean;
}
export const PostCard = styled(Box)`
  box-shadow: 0 0 10px 2px #25333e30;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  max-width: 325px;
`;

export const PresentationCard = (props: Props): JSX.Element => {
  const { item, category, allowNavigation } = props;
  const history = useHistory();
  const { identity } = useIdentity();

  const onClickHandler = (): void => {
    history.push(`${RouterPath.ROUTE_DECK}?presentation=${item.id}`);
  };

  return (
    <PostCard
      alignSelf="center"
      background="background-front"
      focusIndicator={false}
      style={{
        position: 'relative',
      }}
      onClick={allowNavigation ? onClickHandler : undefined}
    >
      { category && <CardRibbon label={category?.name} color={category?.colour} /> }
      <PresentationCardSkeleton
        image={(
          <Box style={{ position: 'relative' }}>
            <StyledImage
              image={{
                blurHash: item.blurHash,
                ...getMedia(item.icon),
              }}
            />
            <Box
              align="center"
              margin={{
                right: 'small',
              }}
              style={{
                position: 'absolute',
                bottom: '10px',
                right: '0',
              }}
              justify="center"
            >
              <Button
                icon={
                    !item.paid || identity?.subscription?.type === 'Paid'
                      ? <FaLockOpen color="white" style={{ width: '15px', height: '15px' }} />
                      : <FaLock color="white" style={{ width: '15px', height: '15px' }} />
                  }
                style={{
                  borderRadius: '50px',
                  width: '30px',
                  height: '30px',
                  padding: '0',
                  pointerEvents: 'none',
                }}
                primary
                size="small"
                reverse={false}
                secondary={false}
                disabled={false}
                type="submit"
                gap="none"
                plain={false}
                color={
                    !item.paid || identity?.subscription?.type === 'Paid'
                      ? 'status-ok'
                      : 'status-error'
                  }
              />
            </Box>
          </Box>
        )}
        heading={(
          <>
            <Heading
              level="5"
              margin={{ vertical: '12px', horizontal: '14px' }}
              size="small"
              color="black"
              truncate={false}
              style={{ lineHeight: '20px', maxHeight: '40px', overflow: 'hidden' }}
            >
              {item.name}
            </Heading>
          </>
        )}
        desc={(
          <Paragraph margin={{ vertical: 'none', horizontal: '14px' }} style={{ fontSize: '12px' }}>
            {item.desc}
          </Paragraph>
        )}
      />
    </PostCard>
  );
};

PresentationCard.defaultProps = {
  category: '',
  allowNavigation: true,
};

export default PresentationCard;
