import React from 'react';
import {
  Button,
  Heading,
  Text,
  Layer,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'grommet';

interface Props {
  onClose: () => void;
}

export const DownloadInfo = (props: Props): JSX.Element => {
  const { onClose } = props;
  return (
    <Layer
      responsive={false}
      style={{
        padding: '50px',
        background: 'transparent',
        justifyContent: 'center',
      }}
      onAbort={() => onClose()}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
    >
      <Card
        style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
        width={{ min: '360px', max: '100%' }}
        pad="large"
        alignSelf="center"
        justify="center"
        background="background-front"
      >
        <CardHeader gap="none" direction="column" align="center" alignContent="center">
          <Heading level={3} margin="none" textAlign="center">
            Download presentation
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Text textAlign="center">
            Your presentation is downloading in a new window. It may take a few minutes.
          </Text>
        </CardBody>
        <CardFooter alignSelf="center" gap="small">
          <Button
            size="medium"
            primary
            label={<Text>OK</Text>}
            onClick={() => onClose()}
          />
        </CardFooter>

      </Card>
    </Layer>
  );
};

export default DownloadInfo;
