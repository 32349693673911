import React, { useContext } from 'react';
import {
  Box,
  Image,
  Anchor,
  Header as GrommetHeader,
  ResponsiveContext,
} from 'grommet';

import { DesktopLogo } from '../../components/Logo';
import { UserMenu } from '../../components';

export const Header = (): React.ReactElement => {
  const responsive = useContext(ResponsiveContext);
  return (
    <Box
      fill="vertical"
      overflow="auto"
      pad={{ vertical: 'none', horizontal: `${responsive}` }}
      align="center"
      elevation="small"
      background="background-front"
      flex="grow"
    >
      <GrommetHeader background="white" pad="small" width="100%">
        <Box direction="row" gap="medium" flex="grow">
          <Box direction="row" align="center" justify="center" gap="large">
            <DesktopLogo width={135} />
          </Box>
          <Box
            direction="row"
            align="center"
            justify="center"
            flex="grow"
            gap="large"
          />
          <Box direction="row" align="center">
            <UserMenu />
          </Box>
        </Box>

      </GrommetHeader>

    </Box>
  );
};
export default Header;
