import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import MainLayout from '../../layouts/MainLayout';
import Home from '../../features/home/Home';
import SignUp from '../../features/signUp/SignUp';
import SignIn from '../../features/signIn/SignIn';
import VerifyEmail from '../../features/verifyEmail/VerifyEmail';
import VerifyEmailStatus from '../../features/verifyEmailStatus/VerifyEmailStatus';
import Deck from '../../features/deck';
import GoogleAuthCallback from '../../features/googleAuthCallback/GoogleAuthCallback';
import Subscription from '../../features/subscription/Subscription';
import SubscriptionSuccess from '../../features/subscription/SubscriptionSuccess';
import SubscriptionLayout from '../../layouts/SubscriptionLayout';

import {
  ROUTE_ROOT,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_VERIFY_EMAIL,
  ROUTE_VERIFY_EMAIL_STATUS,
  ROUTE_DECK,
  ROUTE_GOOGLE_SSO_CALLBACK,
  ROUTE_SUBSCRIPTION,
  ROUTE_SUBSCRIPTION_SUCCESS,
  ROUTE_ACCEPT_TNC,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_FORGOT_PASSWORD_INSTRUCTIONS,
  ROUTE_RESET_PASSWORD,
  ROUTE_USER_PRESENTATIONS,
  ROUTE_USER_SETTINGS,
  ROUTE_USER_PRESENTATION_DOWNLOAD, ROUTE_ONBOARDING,
} from './route-path.constants';
import AcceptTnC from '../../features/auth/terms/AcceptTerms';
import {
  ResetPassword, ForgotPassword,
  ForgotPasswordInstructions,
} from '../../features/auth/forgotPassword';
import UserPresentations from '../../features/userPresentations/myPresentations/UserPresentations';
import UserSettings from '../../features/users/UserSettings';
import UserPresentationDownload from '../../features/userPresentations/UserPresentationDownload';
import Onboarding from '../../features/onboarding/Onboarding';

export type RouteType = 'route' |'redirect';

interface RouteConfig {
  type: RouteType;
  key: string,
  title: string,
  path?: string,
  component?: React.FC<RouteComponentProps>,
  layout?: React.FC<JSX.Element>,
  to?: string;
}

const routerConfig: {[key:string]:RouteConfig[]} = {
  privateRoutes: [
    {
      type: 'route',
      key: 'home',
      title: 'Home',
      path: ROUTE_ROOT,
      component: Home,
      layout: MainLayout,
    },
    {
      type: 'route',
      key: 'subscription',
      title: 'Subscribe',
      path: ROUTE_SUBSCRIPTION,
      component: Subscription,
      layout: SubscriptionLayout,
    },
    {
      type: 'route',
      key: 'subscriptionSuccess',
      title: 'Subscription successful',
      path: ROUTE_SUBSCRIPTION_SUCCESS,
      component: SubscriptionSuccess,
      layout: SubscriptionLayout,
    },
    {
      type: 'route',
      key: 'onboarding',
      title: 'Onboarding',
      path: ROUTE_ONBOARDING,
      component: Onboarding,
      layout: SubscriptionLayout,
    },
    {
      type: 'route',
      key: 'acceptTnC',
      title: 'Accept term and condition',
      path: ROUTE_ACCEPT_TNC,
      component: AcceptTnC,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'deck',
      title: 'Deck',
      path: ROUTE_DECK,
      component: Deck,
      layout: MainLayout,
    },
    {
      type: 'route',
      key: 'userPresentations',
      title: 'My Presentations',
      path: ROUTE_USER_PRESENTATIONS,
      component: UserPresentations,
      layout: MainLayout,
    },
    {
      type: 'route',
      key: 'userPresentations',
      title: 'Presentation download',
      path: ROUTE_USER_PRESENTATION_DOWNLOAD,
      component: UserPresentationDownload,
      layout: undefined,
    },
    {
      type: 'route',
      key: 'settings',
      title: 'Settings',
      path: ROUTE_USER_SETTINGS,
      component: UserSettings,
      layout: MainLayout,
    },
  ],
  publicRoutes: [
    {
      type: 'route',
      key: 'registerUser',
      title: 'Register',
      path: ROUTE_REGISTER,
      component: SignUp,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'login',
      title: 'Login',
      path: ROUTE_LOGIN,
      component: SignIn,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'forgotPassword',
      title: 'Forgot password',
      path: ROUTE_FORGOT_PASSWORD,
      component: ForgotPassword,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'forgotPasswordInstructions',
      title: 'Forgot password instructions',
      path: ROUTE_FORGOT_PASSWORD_INSTRUCTIONS,
      component: ForgotPasswordInstructions,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'resetPassword',
      title: 'Reset password',
      path: ROUTE_RESET_PASSWORD,
      component: ResetPassword,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'verifyEmail',
      title: 'Verify your email',
      path: ROUTE_VERIFY_EMAIL,
      component: VerifyEmail,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'verifyEmailStatus',
      title: 'Verify your email status',
      path: ROUTE_VERIFY_EMAIL_STATUS,
      component: VerifyEmailStatus,
      layout: DefaultLayout,
    },
    {
      type: 'route',
      key: 'googleSSO',
      title: 'Google SSO',
      path: ROUTE_GOOGLE_SSO_CALLBACK,
      component: GoogleAuthCallback,
      layout: DefaultLayout,
    },
    {
      type: 'redirect',
      key: 'redirectHome',
      title: 'Home',
      to: ROUTE_HOME,
    },
  ],
};

export default routerConfig;
