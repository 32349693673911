import React, { useState } from 'react';

import CategoryPanel from './CategoryPanel';
import LeftbarPanel from './LeftbarPanel';

interface Props {
  onCloseHandler: () => void;
}
export const LeftBar = (props: Props): JSX.Element | null => {
  const [showCategoryPanel, setShowCategoryPanel] = useState(false);
  const { onCloseHandler } = props;
  const width = '80vw';
  const height = '100vh';

  return (
    <>
      <LeftbarPanel
        width={width}
        height={height}
        onClickHandler={(cb) => setShowCategoryPanel(cb === 'category')}
        onCloseHandler={onCloseHandler}
      />

      {showCategoryPanel
        && (
        <CategoryPanel
          width={width}
          height={height}
          onCloseHandler={(closeLeftBar: boolean) => {
            setShowCategoryPanel(false);
            if (closeLeftBar) {
              onCloseHandler();
            }
          }}
        />
        )}
    </>
  );
};
export default LeftBar;
