import React, { useEffect, useState } from 'react';
import {
  Text,
  Box,
  Button,
  Heading,
  Paragraph,
  Image,
  Spinner,
} from 'grommet';

import { useHistory } from 'react-router-dom';

import freeImg from '../../assets/images/free.png';
import { useIdentity } from '../../app/hooks';

import {
  useFreeSubscriptionMutation,
} from '../paymentGateway/paymentGatewayApiSlice';
import { RouterPath } from '../../app/router';
import { ErrorMessage } from '../../components';

interface Props {
  onSelect: () => void;
  disabled?: boolean;
}

const FreeSubscription = (props: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [freeSubscription, { error }] = useFreeSubscriptionMutation();
  const { identity, fetchIdentity } = useIdentity();
  const history = useHistory();

  const { onSelect, disabled } = props;

  const onFreeSelect = async (): Promise<void> => {
    setIsLoading(true);
    onSelect();
    await freeSubscription();
    await fetchIdentity();
  };

  useEffect(() => {
    const subs = identity?.subscription;
    if (subs?.type === 'Free') {
      history.replace(`${RouterPath.ROUTE_HOME}`);
    }
    setIsLoading(false);
  }, [identity, history]);

  return (
    <Box
      align="stretch"
      justify="between"
      background={{ color: 'background-front' }}
      pad="medium"
      elevation="large"
      round="medium"
    >
      <Box
        align="stretch"
        justify="between"
        direction="row"
        style={{ position: 'relative', borderBottom: '1px solid #D9D9D9' }}
      >
        <Box align="start" justify="center">
          <Paragraph>
            <Text weight={600} color="brand">Upplo Free</Text>
          </Paragraph>
          <Heading
            style={{ fontWeight: 'bold' }}
            size="medium"
            margin={{ bottom: 'medium', top: 'none' }}
            color="black"
            level="1"
            truncate={false}
          >
            £0
          </Heading>
        </Box>
        <Box align="center" justify="center" width="xsmall">
          <Image src={freeImg} fit="contain" fill="horizontal" />
        </Box>
      </Box>
      <Box align="stretch" justify="between">
        <Paragraph color="dark-5">
          For educators and departments wanting to design slides with limited library content.
        </Paragraph>

        <Button
          onClick={onFreeSelect}
          disabled={disabled || isLoading}
          alignSelf="end"
          label={<Text color="brand">Continue</Text>}
          primary={false}
          reverse
          secondary={false}
          size="medium"
          icon={
            isLoading
              ? <Spinner size="xsmall" /> : undefined
          }
        />
      </Box>
      {error && <ErrorMessage error={error} />}
    </Box>

  );
};
FreeSubscription.defaultProps = {
  disabled: false,
};

export default FreeSubscription;
