import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OnboardingState {
  questions: {
    question: string;
    options: string[];
    type: string;
  }[];
}

const initialState: OnboardingState = {
  questions: [],
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setQuestions(state: OnboardingState, { payload }: PayloadAction<any | null>) {
      if (payload && payload.length) {
        const questions = payload?.map((question: any) => ({
          type: question.type,
          question: question.question,
          options: question.options.map((option: any) => option.text),
        }));
        state.questions = questions || [];
      }
    },
  },
});

export const { setQuestions } = onboardingSlice.actions;

export default onboardingSlice.reducer;
