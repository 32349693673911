import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  Heading,
  Button,
  Image,
  Text,
} from 'grommet';

import {
  Add,
  Expand,
} from 'grommet-icons';

import { Slide } from '../../types';
import StyledImage from '../StyledImage';
import { useOnScreen } from '../../app/hooks';
import StyledTag from '../StyledTag';
import { getMedia } from '../../utils';

const Wrapper = styled(Box)`
  position: relative;
  border: 1px solid rgb(228 228 228);
  box-shadow: rgb(0 0 0 / 11%) 0px 4px 14px;
  #slide-options {
    display: none;
  }
  :hover #slide-options, :active #slide-options {
    display: flex;
  }
`;

type Props = {
  slide: Slide;
  onView: (s: Slide) => void;
  onAdd: (s: Slide) => void;
}

export const SlideCard = (props: Props): JSX.Element => {
  const { slide, onView, onAdd } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setLoaded(true);
    }
  }, [isVisible]);

  const onClickAddHandler = (): void => {
    onAdd(slide);
  };

  const onClickViewHandler = (): void => {
    onView(slide);
  };

  return (
    <Box
      margin={{ horizontal: 'small' }}
      focusIndicator={false}
      justify="start"
      align="start"
      ref={ref}
    >
      <Wrapper
        width="100%"
        align="center"
        justify="center"
        round="small"
        overflow="hidden"
      >
        <Box
          align="center"
          justify="center"
          direction="row"
          width="100%"
          overflow="hidden"
          wrap={false}
          responsive={false}
          animation="fadeIn"
          style={{ paddingBottom: '56.25%', display: 'block', height: '0' }}

        >
          {
            (isVisible || loaded)
            && (
              <StyledImage
                image={{
                  blurHash: slide.blurHash,
                  ...getMedia(slide.image),
                }}
              />
            )
          }
        </Box>
        <Box
          id="slide-options"
          align="center"
          justify="center"
          style={{ position: 'absolute' }}
          height="100%"
          width="100%"
          background="linear-gradient(90deg, rgba(0, 176, 155, 0.80) 0%, rgba(61, 201, 117, 0.80) 100%)"
        >
          <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
            <Box align="center" justify="center">
              <Button
                icon={<Add color="dark-1" />}
                primary
                style={{ borderRadius: '50px', background: 'white' }}
                onClick={onClickAddHandler}
              />
              <Heading
                margin={{ top: 'small', bottom: 'none' }}
                color="white"
                level="4"
                size="xsmall"
              >
                Add
              </Heading>
            </Box>
            <Box
              align="center"
              justify="center"
            >
              <Button
                icon={<Expand color="dark-1" />}
                primary
                style={{ borderRadius: '50px', background: 'white' }}
                onClick={onClickViewHandler}
              />
              <Heading
                margin={{ top: 'small', bottom: 'none' }}
                color="white"
                level="4"
                size="xsmall"
              >
                View
              </Heading>
            </Box>
          </Box>
        </Box>
      </Wrapper>
      {
        slide.tag?.name
          ? (
            <StyledTag>
              <Text margin="none" size="xsmall" color="dark-6">
                {slide.tag?.name}
              </Text>
            </StyledTag>
          )
          : <Text>&nbsp;</Text>
      }
    </Box>
  );
};

export default SlideCard;
