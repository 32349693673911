import React from 'react';

import {
  Box,
  Heading,
  Paragraph,
} from 'grommet';
import { SubtractCircle } from 'grommet-icons';

const PasswordPolicy = (): JSX.Element => (
  <Box
    align="start"
    justify="start"
    style={{ borderRadius: '10px' }}
    background={{ color: 'light-2' }}
    pad={{ vertical: 'small', horizontal: 'small' }}
    margin={{ bottom: 'small' }}
  >
    <Box align="start" justify="start">
      <Heading level="5" margin="none" size="xsmall">
        At least 8 characters long, must contain:
      </Heading>
    </Box>
    <Box align="center" justify="center" direction="row" gap="small">
      <Box align="center" justify="center" direction="row" gap="xsmall">
        <SubtractCircle color="dark-2" size="small" />
        <Paragraph margin="none" color="dark-5" size="small">
          A-Z
        </Paragraph>
      </Box>
      <Box align="center" justify="center" direction="row" gap="xsmall">
        <SubtractCircle color="dark-2" size="small" />
        <Paragraph margin="none" color="dark-5" size="small">
          a-z
        </Paragraph>
      </Box>
      <Box align="center" justify="center" direction="row" gap="xsmall">
        <SubtractCircle color="dark-2" size="small" />
        <Paragraph margin="none" color="dark-5" size="small">
          0-9
        </Paragraph>
      </Box>
      <Box align="center" justify="center" direction="row" gap="xsmall">
        <SubtractCircle color="dark-2" size="small" />
        <Paragraph margin="none" color="dark-5" size="small">
          a special character
        </Paragraph>
      </Box>
    </Box>
  </Box>
);

export default PasswordPolicy;
