import React, { useRef, useState } from 'react';
import {
  Box,
  Text,
  InfiniteScroll,
  Button,
  Paragraph,
  Heading,
} from 'grommet';
import { Add } from 'grommet-icons';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import {
  useAppSelector,
  useAppDispatch,
} from '../../app/hooks';
import { ScrollBox } from '../../components';
import { useUpdateUserPresentationDisplayOrdersMutation } from './userPresentationsApiSlice';
import { setUserSlides } from './userPresentationsSlice';
import { Identifier, UserSlide } from '../../types';
import storage from '../../storage';
import UserSlideComponent from './UserSlide';
import UserSlideSkeleton from './UserSlideSkeleton';

type Props = {
  onNewPresentation: () => void;
}

export const UserSlideList: React.FC<Props> = ({ onNewPresentation }): React.ReactElement => {
  const {
    presentation: userPresentation,
    slides,
    added,
  } = useAppSelector((state) => state.userPresentations);
  const slidesState = useAppSelector((state) => state.userPresentations.state);
  const dispatch = useAppDispatch();
  const ref = useRef<any>(0);
  const [updateDisplayOrder] = useUpdateUserPresentationDisplayOrdersMutation();

  const { isLoading, isSuccess } = slidesState;

  const onMoreHandler = (): void => {
    console.log('more');
  };

  const onDragEndHandler = (result: any): void => {
    if (!result.destination) {
      return;
    }

    let items = [...slides];

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items = items.map((item, index) => ({ ...item, displayOrder: index + 1 }));

    dispatch(setUserSlides(items));

    clearTimeout(ref.current);

    ref.current = setTimeout(() => {
      const id = storage.localStorage.currentUserPresentation as Identifier;
      updateDisplayOrder({
        id,
        userSlides: items.map((userSlide) => ({
          id: userSlide.id,
          tmpId: userSlide.tmpId,
        })),
      });
    }, 2000);
  };

  return (
    <Box
      align="center"
      justify="start"
      width="100%"
      gap="medium"
      style={{ minHeight: 'calc(100vh - 221px)' }}
    >
      {
        isLoading
        // && isSuccess
        && (

          <ScrollBox
            pad={{ vertical: 'medium', left: 'small', right: 'medium' }}
            height={{ max: 'calc(100vh - 221px)', min: '100%' }}
            overflow={{
              vertical: 'auto',
            }}
            direction="column"
            margin="none"
            style={{ flexFlow: 'wrap' }}
          >
            {isLoading && [1, 2, 3, 4, 5].map((item) => (
              <UserSlideSkeleton key={item} />
            ))}
          </ScrollBox>
        )
        }
      {
        !isLoading
        // && isSuccess
        && !!slides.length
        && (
        <DragDropContext onDragEnd={onDragEndHandler}>
          <Droppable droppableId="user-slides">
            {(provided) => (
              <ScrollBox
                pad={{ vertical: 'small', horizontal: 'small' }}
                height={{ max: 'calc(100vh - 221px)', min: '100%' }}
                overflow={{
                  vertical: 'auto',
                }}
                direction="column"
                margin="none"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <InfiniteScroll
                  items={slides}
                  onMore={onMoreHandler}
                  step={10}
                  show={added ? slides.length : 0}
                >
                  {(slide: UserSlide, index: number) => (
                    <UserSlideComponent key={slide.id} index={index} userSlide={slide} />
                  )}
                </InfiniteScroll>
                {/* {
                  slides.map((slide, index) => (
                    <UserSlideComponent key={slide.id} index={index} userSlide={slide} />
                  ))
                } */}
                {provided.placeholder}
              </ScrollBox>
            )}
          </Droppable>
        </DragDropContext>
        )
      }
      {
        !isLoading
        && !userPresentation
        && (
          <Box
            margin={{ vertical: 'auto' }}
            align="center"
            alignSelf="center"
            style={{ borderRadius: '10px' }}
            justify="center"
            pad="medium"
          >
            <Box background="status-ok" margin={{ left: '5px' }} round="small">
              <Button
                icon={<Add color="white" />}
                title="Create a new presentation"
                onClick={onNewPresentation}
              />
            </Box>
            <Heading level="5" margin={{ bottom: 'none' }}>
              Create a new presentation
            </Heading>
          </Box>
        )
      }
      {
        !!userPresentation
        && !isLoading
        && !slides.length
        && (
          <Box
            margin={{ vertical: 'auto' }}
            align="center"
          >
            <Text textAlign="center" size="small" style={{ fontStyle: 'italic' }}>
              There are no slides<br />in this presentation
            </Text>
          </Box>
        )
      }
    </Box>
  );
};

export default UserSlideList;
