import React from 'react';
import {
  Box,
  Text,
  Avatar,
  Menu,
} from 'grommet';

import { AiFillFilePpt } from 'react-icons/ai';
import { RiUserSettingsFill } from 'react-icons/ri';
import { IoLogOut, IoPersonAdd } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { useIdentity, useInitials, useSignOut } from '../../app/hooks';

import {
  ROUTE_USER_PRESENTATIONS,
  ROUTE_USER_SETTINGS,
} from '../../app/router/route-path.constants';

interface Props {
  firstName: string;
  lastName: string;
}

const Initials = (props: Props): JSX.Element => {
  const { firstName, lastName } = props;

  const data = useInitials({ text: firstName && lastName ? `${firstName} ${lastName}` : '' });

  return <Text weight="bold" color="brand">{data}</Text>;
};

export const UserMenu = (): JSX.Element => {
  const { identity } = useIdentity();
  const signOut = useSignOut();
  const history = useHistory();
  return (
    <Box align="center" justify="center" direction="row">
      <Avatar
        align="center"
        flex={false}
        justify="center"
        overflow="hidden"
        round="full"
        background={{ dark: 'false', color: 'border' }}
        size="medium"
      >
        <Initials firstName={identity?.firstName || ''} lastName={identity?.lastName || ''} />
      </Avatar>
      <Menu
        label={identity?.firstName ? identity?.firstName : ''}
        items={[
          {
            label: (<Box alignSelf="center" margin={{ left: 'xsmall' }}>My Presentations</Box>),
            icon: (<AiFillFilePpt style={{ alignSelf: 'center', width: '40px' }} size="25px" />),
            onClick: () => history.push(ROUTE_USER_PRESENTATIONS),
          },
          {
            label: (
              <Box direction="row" align="center" alignSelf="center" margin={{ left: 'xsmall' }}>
                My Referrals:
                <div
                  style={{
                    color: 'brand',
                    fontWeight: 'bold',
                    marginLeft: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    padding: '2px 9px',
                    borderRadius: '100%',
                  }}
                >
                  { identity?.activeReferrals }
                </div>
              </Box>
            ),
            icon: (<IoPersonAdd style={{ alignSelf: 'center', width: '40px' }} size="25px" />),
            onClick: () => history.push(ROUTE_USER_SETTINGS),
          },
          {
            label: (<Box alignSelf="center" margin={{ left: 'xsmall' }}>Settings</Box>),
            icon: (<RiUserSettingsFill style={{ alignSelf: 'center', width: '40px' }} size="25px" />),
            onClick: () => history.push(ROUTE_USER_SETTINGS),
          },
          {
            label: (<Box alignSelf="center" margin={{ left: '2px' }}>Sign Out</Box>),
            icon: (<IoLogOut style={{ marginLeft: '3px', alignSelf: 'center', width: '40px' }} size="25px" />),
            onClick: () => signOut(),
          },
        ]}
      />
    </Box>
  );
};

export default UserMenu;
