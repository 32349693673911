import { createApi } from '@reduxjs/toolkit/query/react';

import { Category } from '../../types';
import { jwtBaseQuery as baseQuery } from '../../api';

export const categoriesApiSlice = createApi({
  reducerPath: 'categoriesApi',
  baseQuery,
  endpoints(builder) {
    return {
      categories: builder.query<Category[], void>({
        query() {
          return {
            url: '/categories?_sort=displayOrder:ASC',
          };
        },
      }),
    };
  },
});

export const {
  useCategoriesQuery,
  useLazyCategoriesQuery,
} = categoriesApiSlice;
