import React from 'react';

import { Box } from 'grommet';
import { HeightType, WidthType, BackgroundType } from 'grommet/utils';

type Props = {
  radius?: string | number;
  height?: HeightType;
  width?: WidthType;
  background?: BackgroundType;
}

export const Skeleton = (props: Props): JSX.Element => {
  const {
    height,
    width,
    radius,
    background,
  } = props;

  return (
    <Box
      height={height}
      width={width}
      style={{ borderRadius: radius }}
      background={background}
    />
  );
};

Skeleton.defaultProps = {
  radius: '8px',
  height: 'xxsmall',
  width: '100%',
  background: 'background-back',
};

export default Skeleton;
