import React from 'react';
import {
  Box,
  Paragraph,
} from 'grommet';

import { Skeleton } from '../../components';

export const UserSlideSkeleton = (): React.ReactElement => (
  <Box pad="none" margin={{ bottom: 'medium' }}>
    <Box align="start" justify="center" direction="row">
      <Box align="center" justify="center" width="xxsmall">
        <Paragraph margin="none">
          {'  '}
        </Paragraph>
      </Box>
      <Box
        align="center"
        style={{ border: '1px solid rgb(228 228 228)', boxShadow: 'rgb(0 0 0 / 28%) 0px 4px 14px' }}
        justify="center"
        width="medium"
        overflow="hidden"
        round="small"
      >
        <Skeleton height="171px" />
      </Box>
    </Box>
  </Box>
);

export default UserSlideSkeleton;
