import React, { useEffect, useState } from 'react';
import {
  Anchor,
  Box,
  Heading,
} from 'grommet';

import { useHistory } from 'react-router-dom';
import {
  useLazyFetchUserPresentationsQuery,
} from '../userPresentationsApiSlice';

import UserPresentationCard from './UserPresentationCard';
import { QueryParams, UserPresentation } from '../../../types';
import { ROUTE_USER_PRESENTATIONS } from '../../../app/router/route-path.constants';
import UserPresentationCardSkeleton from './UserPresentationCardSkeleton';
import DeleteConfirmation, { ConfirmationValueType } from './DeleteConfirmation';
import DownloadInfo from './DownloadInfo';

export const MyPresentations = (): JSX.Element | null => {
  const history = useHistory();
  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const [
    confirmationCallback,
    setConfirmationCallback,
  ] = useState<((p?: ConfirmationValueType)=>void) | null>(null);

  const [fetchUserPresentations,
    { data: userPresentations, isLoading }] = useLazyFetchUserPresentationsQuery();

  const paramsFetchUserPresentations: QueryParams = {
    page: 1,
    pageSize: 3,
    sort: [{ field: 'updatedAt', order: 'DESC' }],
  };

  useEffect(() => {
    if (!userPresentations && !isLoading) {
      fetchUserPresentations(paramsFetchUserPresentations);
    }
  }, [userPresentations, isLoading]);

  return (
    <>
      {showDownloadInfo && <DownloadInfo onClose={() => setShowDownloadInfo(false)} />}
      <DeleteConfirmation
        onClick={confirmationCallback}
      />
      <Box margin={{ bottom: 'small', top: 'none' }} justify="center" elevation="none">
        {!!userPresentations?.results.length && (
        <Heading
          level="4"
          size="small"
          color="black"
        >My Presentations
        </Heading>
        )}
        {isLoading && (
        <Box>
          <UserPresentationCardSkeleton />
          <UserPresentationCardSkeleton />
          <UserPresentationCardSkeleton />
        </Box>
        )}
        {userPresentations?.results.map((item: UserPresentation) => (
          <UserPresentationCard
            key={item.id}
            userPresentation={item}
            onDeleteConfirmation={(callback) => {
              setConfirmationCallback(() => (p: ConfirmationValueType) => {
                callback(p);
                setConfirmationCallback(null);
              });
            }}
            onDelete={() => fetchUserPresentations(paramsFetchUserPresentations)}
            onDownload={() => setShowDownloadInfo(true)}
          />
        ))}
        {
        userPresentations && userPresentations?.pagination.pageCount > 1
          ? (
            <Anchor
              alignSelf="end"
              disabled={!userPresentations?.results.length}
              size="small"
              onClick={() => history.push(ROUTE_USER_PRESENTATIONS)}
              label="More"
            />
          ) : null
      }
      </Box>
    </>
  );
};

export default MyPresentations;
