import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Spinner,
} from 'grommet';
import { useParams } from 'react-router-dom';
import { buildPresentationDownloadUrl } from '../../utils/file';

export const UserPresentationDownload = (): JSX.Element => {
  const { presentationId } = useParams<{presentationId: string}>();
  useEffect(() => {
    if (presentationId) {
      document.location.href = buildPresentationDownloadUrl(presentationId);
    }
  }, []);
  return (
    <>
      <Box
        direction="column"
        align="center"
        width="100vw"
        height="100vh"
        justify="center"
      >
        <Spinner size="medium" />
        <Heading level="4" alignSelf="center">
          Downloading the presentation, please close the window after the download is complete
        </Heading>
      </Box>

    </>
  );
};

export default UserPresentationDownload;
