import React, { useEffect, useState } from 'react';

import {
  ThemeContext,
  Form,
  FormField,
  TextInput,
  CheckBox,
  Text,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Anchor,
  Spinner,
} from 'grommet';
import { LinkNext } from 'grommet-icons';
import {
  useForm,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../../assets/styles/themes';
import {
  useIdentity,
} from '../../../app/hooks';
import { User } from '../../../types';
import { RouterPath } from '../../../app/router';
import { ValidationMessage } from '../../../components';
import { isEmail } from '../../../utils/validations';

import { useUpdateMutation } from '../../users/usersApiSlice';

interface FormFields extends User {
  password: string;
  tnc: boolean;
  optIn?: boolean;
}

const validationSchema = (
  register: UseFormRegister<FormFields>,
  t: any,
): { [key: string]: UseFormRegisterReturn } => ({
  referralCode: register('referralCode', { required: false }),
  email: register('email', {
    required: t('email.validations.required'),
    validate: isEmail(t('email.validations.valid')),
  }),
  firstName: register('firstName', {
    required: t('name.first.validations.required'),
    minLength: {
      value: 3,
      message: t('name.first.validations.min', { min: 3 }),
    },
  }),
  lastName: register('lastName', {
    required: t('name.last.validations.required'),
    minLength: {
      value: 3,
      message: t('name.last.validations.min', { min: 3 }),
    },
  }),
  tnc: register('tnc', { required: t('tnc.validations.required') }),
  optIn: register('optIn', { required: false }),
});

const AcceptTnC = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormFields>();

  const [isLoading, setIsLoading] = useState(false);

  const { identity, fetchIdentity } = useIdentity();
  const [userUpdate, { isError }] = useUpdateMutation();

  const history = useHistory();
  const { t } = useTranslation();
  const validation = validationSchema(register, t);

  useEffect(() => {
    if (identity) {
      if (identity?.tnc) {
        history.replace(RouterPath.ROUTE_HOME);
      } else {
        setValue('email', identity?.email);
        setValue('firstName', identity?.firstName);
        setValue('lastName', identity?.lastName);
      }
    }
    if (isError) {
      setIsLoading(false);
    }
  }, [history, identity, setValue, isError]);

  const handleSignUp = async (values: FormFields): Promise<void> => {
    const {
      firstName,
      lastName,
      tnc,
      optIn,
      referralCode,
    } = values;
    setIsLoading(true);
    await userUpdate({
      firstName,
      lastName,
      tnc: tnc as boolean,
      optIn: optIn as boolean,
      id: identity?.id as string,
      referralCode,
    });
    fetchIdentity();
  };

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="xlarge" direction="row-responsive" flex="grow" justify="center">
        <Card
          width="medium"
          pad="large"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading level="3" margin="none" alignSelf="center">Confirm Account</Heading>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleSignUp)}>
              <Heading margin={{ bottom: 'small' }} level="5" color="brand">
                Have you been sent an invite/referral code?
              </Heading>
              <FormField
                name="referralCode"
                htmlFor="referralCode"
                label="Have you been sent an invite/referral code?"
                size={1000}
              >
                <TextInput
                  id="referralCode"
                  type="text"
                  placeholder="Referral Code"
                  maxLength={50}
                  {...validation.referralCode}
                />
              </FormField>
              <Box
                direction="row-responsive"
                gap="small"
                style={{ borderTop: '1px solid #ccc', paddingTop: '20px' }}
              >
                <FormField
                  name="firstName"
                  htmlFor="firstName"
                  label="First Name"
                  error={
                    errors.firstName
                    && (
                      <ValidationMessage message={errors.firstName.message} />
                    )
                  }
                >
                  <TextInput
                    id="firstName"
                    maxLength={50}
                    {...validation.firstName}
                  />
                </FormField>
                <FormField
                  name="lastName"
                  htmlFor="lastName"
                  label="Last Name"
                  error={
                    errors.lastName
                    && (
                      <ValidationMessage message={errors.lastName.message} />
                    )
                  }
                >
                  <TextInput
                    id="lastName"
                    maxLength={50}
                    {...validation.lastName}
                  />
                </FormField>
              </Box>
              <FormField
                name="email"
                htmlFor="email"
                label="Email"
                size={1000}
                disabled
                error={
                  errors.email
                  && (
                    <ValidationMessage message={errors.email.message} />
                  )
                }
              >
                <TextInput
                  id="email"
                  type="email"
                  disabled
                  maxLength={50}
                  {...validation.email}
                />
              </FormField>

              <ThemeContext.Extend
                value={{ formField: { border: false } }}
              >
                <FormField
                  name="tnc"
                  htmlFor="tnc"
                  border={false}
                  error={
                    errors.tnc
                    && (
                      <ValidationMessage message={errors.tnc.message} />
                    )
                  }
                >
                  <CheckBox
                    label={(
                      <Text size="small">
                        <Text>Yes, I accept the </Text>
                        <Anchor
                          href={RouterPath.ROUTE_TERMS_N_CONDITIONS}
                          label="Terms of Use"
                          target="_blank"
                        />
                        <Text> and </Text>
                        <Anchor
                          href={RouterPath.ROUTE_PRIVACY_POLICY}
                          label="Privacy Policy"
                          target="_blank"
                        />.
                      </Text>
                    )}
                    {...validation.tnc}
                  />
                </FormField>
                <FormField name="optIn" htmlFor="optIn">
                  <CheckBox
                    label={(
                      <Text size="xsmall">
                        {t('optIn.label')}
                      </Text>
                    )}
                    {...validation.optIn}
                  />
                </FormField>
              </ThemeContext.Extend>
              {/* { error && <ErrorMessage error={error} /> } */}

              <Box align="center" pad={{ top: 'large' }}>
                <Button
                  type="submit"
                  size="medium"
                  alignSelf="center"
                  primary
                  disabled={isLoading}
                  label="Submit"
                  icon={isLoading ? <Spinner /> : <LinkNext />}
                  reverse
                />
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>

  );
};

export default AcceptTnC;
