import React, { useContext } from 'react';

import {
  Box,
  Heading,
  Grid,
  ResponsiveContext,
} from 'grommet';

import { useCategoriesQuery } from '../categories/categoriesApiSlice';
import { useFetchFeaturedPresentationsQuery, useSearchPresentationsQuery } from '../presentations/presentationsApiSlice';
import { PresentationCard } from '../../components';
import PresentationSkeleton from '../../components/PresentationCard/PresentationSkeleton';
import { Sort } from '../../types';

type Props = {
  title: string;
  filters?: { [key: string]: any };
  sort?: Sort[];
};

// const columns = {
//   small: ['auto'],
//   medium: ['auto', 'auto'],
//   large: ['auto', 'auto', 'auto'],
//   xlarge: ['auto', 'auto', 'auto'],
// };

export const PresentationList = (props: Props): JSX.Element => {
  const { title, filters, sort } = props;
  const { data: categories } = useCategoriesQuery();
  const { data, isLoading, isSuccess } = useFetchFeaturedPresentationsQuery();
  // const { data, isLoading, isSuccess } = useSearchPresentationsQuery({ filters, sort });
  const size = useContext(ResponsiveContext);
  const getColSize = (): string => {
    if (size === 'small') return '100%';
    if (size === 'medium') return '30%';
    return '20%';
  };
  return (
    <Box
      width="100%"
      gap="small"
      margin={{ bottom: 'medium' }}
      round="small"
      background="background-front"
    >
      <Box
        pad={{ top: 'medium', bottom: 'none', horizontal: 'large' }}
      >
        <Heading
          level="4"
          size="small"
          color="black"
          margin={{ top: 'small', bottom: '0' }}
          truncate={false}
        >
          {title}
        </Heading>
      </Box>
      {/* <Grid
        columns={size !== 'medium' ? 'medium' : '100%'}
      >
        { result.data?.results?.map((item) => <PresentationCard item={item} />) }
      </Grid> */}
      <Grid
        columns={getColSize()}
        pad={{ top: 'medium', bottom: 'large', horizontal: 'large' }}
        gap="medium"
        align="stretch"
        justify="stretch"
      >
        {
          isLoading
          && [1, 2, 3, 4].map((item) => (
            <PresentationSkeleton key={item} />
          ))
        }
        {isSuccess && data?.map((item) => {
          const parentCategory = (categories || [])
            .find((c) => c.id === item.category?.parent);
          return <PresentationCard key={item.id} item={item} category={parentCategory} />;
        })}
      </Grid>
    </Box>
  );
};

PresentationList.defaultProps = {
  sort: [],
  filters: {},
};

export default PresentationList;
