import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';
import {
  Identifier,
  PaginatedResult,
  QueryParams,
  UserPresentation,
  UserPresentationCreatePayload,
  UserPresentationUpdatePayload,
  UserSlide,
} from '../../types';
import { buildSearchQuery, getDefaultSearchQuery } from '../../utils';

type UpdateDisplayOrderPayload = {
  id: Identifier;
  userSlides: {
    id?: Identifier;
    tmpId?: string;
  }[];
};

export const userPresentationsApiSlice = createApi({
  reducerPath: 'userPresentationsApi',
  baseQuery,
  endpoints(builder) {
    return {
      fetchUserPresentations: builder.query<PaginatedResult<UserPresentation>, QueryParams>({
        query(query = getDefaultSearchQuery()) {
          return {
            url: `user-presentations${buildSearchQuery(query)}`,
          };
        },
        keepUnusedDataFor: 1,
      }),
      fetchUserPresentation: builder.query<UserPresentation, Identifier>({
        query(id) {
          return {
            url: `/user-presentations/${id}`,
          };
        },
      }),
      addUserPresentation: builder.mutation<UserPresentation, UserPresentationCreatePayload>({
        query(payload) {
          return {
            url: '/user-presentations',
            body: payload,
            method: 'POST',
          };
        },
      }),
      updateUserPresentation: builder.mutation<UserPresentation, UserPresentationUpdatePayload>({
        query({ id, ...body }) {
          return {
            url: `/user-presentations/${id}`,
            method: 'PUT',
            body,
          };
        },
      }),
      deleteUserPresentation: builder.mutation<UserPresentation, UserPresentation>({
        query({ id }) {
          return {
            url: `/user-presentations/${id}`,
            method: 'DELETE',
          };
        },
      }),
      fetchUserPresentationSlides: builder
        .query<PaginatedResult<UserSlide>, QueryParams>({
          query(query = getDefaultSearchQuery()) {
            const payload = query;
            const filters = payload.filters || {};
            const { id, ...rest } = filters;

            return {
              url: `/user-presentations/${id}/slides${buildSearchQuery({
                ...query,
                filters: rest,
              })}`,
            };
          },
        }),
      updateUserPresentationDisplayOrders: builder
        .mutation<PaginatedResult<UserSlide>, UpdateDisplayOrderPayload>({
          query(payload) {
            const { id, ...body } = payload;
            return {
              url: `/user-presentations/${id}/display-order`,
              method: 'PUT',
              body,
            };
          },
        }),
    };
  },
});

export const {
  useFetchUserPresentationsQuery,
  useLazyFetchUserPresentationsQuery,
  useFetchUserPresentationQuery,
  useLazyFetchUserPresentationQuery,
  useAddUserPresentationMutation,
  useUpdateUserPresentationMutation,
  useFetchUserPresentationSlidesQuery,
  useLazyFetchUserPresentationSlidesQuery,
  useUpdateUserPresentationDisplayOrdersMutation,
  useDeleteUserPresentationMutation,
} = userPresentationsApiSlice;
