import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import {
  ROUTE_LOGIN, ROUTE_ROOT, ROUTE_REGISTER, ROUTE_GOOGLE_SSO_CALLBACK,
} from './route-path.constants';
import Props from './props';
import { useAuthenticated } from '../hooks';

// List of routes restricts once user authenticated
const routes = [
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_GOOGLE_SSO_CALLBACK,
];

export const PublicRoute: React.FC<Props> = (
  {
    layout: Layout,
    component: Component,
    path,
    title,
    ...rest
  },
) => {
  const { authenticated } = useAuthenticated();
  const [lastPath, setLastPath] = useState('');

  if (routes.find((p) => path === p) && authenticated) {
    return <Redirect to={ROUTE_ROOT} />;
  }

  const fullPath = window.location.href;
  if (lastPath !== fullPath) {
    setLastPath(fullPath);
    ReactGA.pageview(fullPath, undefined, title);
    const el = document.getElementById('main-grommet');
    if (el) el.scrollTop = 0;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        Layout ? <Layout><Component {...props} /></Layout> : <Component {...props} />
      )}
    />
  );
};
