import React, { useState, useEffect } from 'react';
import {
  Paragraph,
} from 'grommet';
import { useTranslation } from 'react-i18next';

import { processError } from '../../utils';

export interface MessageType {
  code?: string;
  desc?: string;
}

export interface ErrorType {
  detail?: MessageType;
  status?: string;
}

export default (props: { error: any }): JSX.Element => {
  const { error } = props;
  const [data, setData] = useState<ErrorType>();
  const [text, setText] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    const result = processError(error);
    setData(result);

    if (result?.detail) {
      const translation = t(result.detail.code);

      setText(translation === result.detail.code ? result.detail.desc : translation);
    }
  }, [error, t]);

  return (
    <Paragraph size="small" textAlign="center" alignSelf="center" color="status-error">
      { text && text }
      { !text && t('error.generic') }
    </Paragraph>
  );
};
