import React from 'react';
import {
  Heading,
} from 'grommet';

import PresentationCardSkeleton from './PresentationCardSkeleton';
import { Skeleton } from '../Skeleton';

export const PresentationSkeleton = (): JSX.Element => (
  <PresentationCardSkeleton
    image={<Skeleton height="small" />}
    heading={(
      <>
        <Heading
          level="5"
          margin={{ vertical: 'small' }}
          size="small"
          truncate={false}
        >
          <Skeleton height="24px" />
        </Heading>
      </>
    )}
    desc={<Skeleton height="16px" />}
  />
);

export default PresentationSkeleton;
