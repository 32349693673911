import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';
import { UserSlide, CreateUserSlidesPayload, Identifier } from '../../types';

export const userSlidesApiSlice = createApi({
  reducerPath: 'userSlidesApi',
  baseQuery,
  endpoints(builder) {
    return {
      addUserSlides: builder.mutation<UserSlide[], CreateUserSlidesPayload>({
        query(body) {
          return {
            url: '/user-slides',
            method: 'POST',
            body,
          };
        },
      }),
      deleteUserSlide: builder.mutation<UserSlide, string>({
        query(id) {
          return {
            url: `/user-slides/ref/${id}`,
            method: 'DELETE',
          };
        },
      }),
    };
  },
});

export const {
  useAddUserSlidesMutation,
  useDeleteUserSlideMutation,
} = userSlidesApiSlice;
