import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Heading,
  InfiniteScroll,
  Text,
} from 'grommet';

import { FiArrowLeft } from 'react-icons/fi';
import storage from '../../storage';
import {
  useIdentity,
  useCurrentUserPresentation,
  useAppDispatch,
  useWindowSize,
} from '../../app/hooks';
import { Slide } from '../../types';
import { SlideCard } from '../../components';

import UpgradeSubscription from '../../components/upgradeSubscription';
import NewUserPresentation, { ActionType } from '../userPresentations/NewUserPresentation';
import { setImages } from '../imagePreview/imagePreviewSlice';
import LimitExceedWarningDialog from '../userPresentations/LimitExceedWarningDialog';
import { ConfirmationValueType } from '../userPresentations/myPresentations/DeleteConfirmation';

interface Props {
  slides: Slide[];
  heading: string;
  isPaid: boolean;
  onClose: ()=>void;
}

export const SlideGrid = (props: Props): JSX.Element => {
  const steps = 12;
  const [width] = useWindowSize();
  const {
    slides, heading, isPaid, onClose,
  } = props;

  const [loadMore, setLoadMore] = useState(true);

  const [items, setItems] = useState<Slide[]>(slides.slice(0, steps));
  const [newItems, setNewItems] = useState<Slide[]>([]);
  const { identity } = useIdentity();
  const { addSlidesToCurrentPresentation } = useCurrentUserPresentation();
  const [showSubscription, setShowSubscription] = useState(false);
  const [showNewPresentationDialog, setShowNewPresentationDialog] = useState(false);
  const [showNewUserPresentationConfirmation, setShowNewUserPresentationConfirmation] = useState(false);
  const dispatch = useAppDispatch();
  const onMoreHandler = (): void => {
    if (!loadMore) return;
    setLoadMore(false);
    setTimeout(() => {
      setItems(slides.slice(0, items.length + steps));
      if (slides.length !== items.length) setLoadMore(true);
    }, 1500);
  };

  const getColSize = (): string => {
    if (width < 1200) return '100%';
    if (width < 1500) return '40%';
    if (width < 1800) return '30%';
    return '24%';
  };

  const processSizeLimitError = (): void => {
    setShowNewUserPresentationConfirmation(true);
  };

  const onCloseSizeLimitNotificationHandler = (e: ConfirmationValueType): void => {
    setShowNewUserPresentationConfirmation(false);
    if (e === 'yes') setShowNewPresentationDialog(true);
  };

  const addSlide = (data: Slide[]): void => {
    const { currentUserPresentation } = storage.localStorage;

    if (currentUserPresentation) {
      addSlidesToCurrentPresentation(data).catch(processSizeLimitError);
    } else {
      setShowNewPresentationDialog(true);
      setNewItems(data);
    }
  };

  const onCloseDialogHandler = (action?: ActionType): void => {
    setShowNewPresentationDialog(false);

    if (action === 'success' && newItems.length) {
      addSlidesToCurrentPresentation(newItems).catch(processSizeLimitError);
      setNewItems([]);
    }
  };

  const addSlideHandler = (slide: Slide): void => {
    if (identity?.subscription?.type === 'Paid' || !isPaid) {
      addSlide([slide]);
    } else {
      setShowSubscription(isPaid);
    }
  };

  const onImagePreview = (s: Slide): void => {
    const previewImages = slides.filter((slide) => slide.image)
      .map((slide: Slide) => {
        const { id, url } = slide.image;
        return { id: id as string, url };
      });
    const index = slides.findIndex((slide) => slide.id === s.id);
    dispatch(setImages({ images: previewImages, index, show: true }));
  };

  return (
    <>
      {
        showNewUserPresentationConfirmation
        && (
          <LimitExceedWarningDialog
            onClick={(value) => onCloseSizeLimitNotificationHandler(value as ConfirmationValueType)}
          />
        )
      }
      {
        showNewPresentationDialog
        && (
          <NewUserPresentation
            onClose={onCloseDialogHandler}
          />
        )
      }
      {showSubscription && (
      <UpgradeSubscription
        onCloseHandler={() => setShowSubscription(false)}
      />
      )}
      <Box
        width="100%"
        margin="medium"
        align="center"
        background="background-front"
        style={{ borderRadius: '10px' }}
        justify="center"
        pad="small"
        elevation="small"
      >

        <Box pad={{ horizontal: 'medium', vertical: 'small' }} alignSelf="start">
          <Button
            size="medium"
            alignSelf="start"
            plain
            label={<Text color="brand">Back</Text>}
            icon={<FiArrowLeft size="24px" />}
            onClick={() => onClose()}
          />
          <Heading
            alignSelf="start"
            level="4"
            size="small"
            color="black"
          >{heading}
          </Heading>
        </Box>
        <Box pad={{ horizontal: 'small' }} alignSelf="center" alignContent="center" fill>
          {!!slides.length
            && (
              <Grid columns={getColSize()} gap="small" width="100%">
                <InfiniteScroll
                  items={items}
                  onMore={onMoreHandler}
                  step={steps}
                >
                  {(item: Slide) => (
                    <Box
                      key={item.id}
                      align="center"
                    >
                      <SlideCard
                        key={item.id}
                        slide={item}
                        onView={() => onImagePreview(item)}
                        onAdd={() => addSlideHandler(item)}
                      />
                    </Box>
                  )}
                </InfiniteScroll>
              </Grid>
            )}
        </Box>
      </Box>
    </>
  );
};

export default SlideGrid;
