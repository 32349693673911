import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';
import {
  ROUTE_LOGIN,
  ROUTE_SUBSCRIPTION,
  ROUTE_ACCEPT_TNC,
  ROUTE_SUBSCRIPTION_SUCCESS,
  ROUTE_ONBOARDING,
} from './route-path.constants';
import Props from './props';
import { useAuthenticated, useIdentity } from '../hooks';

export const PrivateRoute: React.FC<Props> = ({
  layout: Layout,
  component: Component,
  path,
  title,
  ...rest
}) => {
  const { authenticated } = useAuthenticated();
  const { identity } = useIdentity();
  const [lastPath, setLastPath] = useState('');

  if (path !== ROUTE_ACCEPT_TNC && authenticated && !identity?.tnc) {
    return <Redirect to={ROUTE_ACCEPT_TNC} />;
  }

  const isSubPending = !identity?.subscription || identity?.subscription?.type === 'Pending';

  if (path !== ROUTE_SUBSCRIPTION && path !== ROUTE_SUBSCRIPTION_SUCCESS && authenticated
    && identity?.tnc && isSubPending) {
    return <Redirect to={ROUTE_SUBSCRIPTION} />;
  }

  if (path !== ROUTE_ONBOARDING && authenticated
    && identity?.tnc && !isSubPending && !identity?.isOnboardingCompleted) {
    return <Redirect to={ROUTE_ONBOARDING} />;
  }

  const fullPath = window.location.href;
  if (lastPath !== fullPath) {
    setLastPath(fullPath);
    document.title = title;
    ReactGA.pageview(fullPath, undefined, title);
    const el = document.getElementById('main-grommet');
    if (el) el.scrollTop = 0;
  }

  return (authenticated ? (
    <Route
      {...rest}
      render={(props) => (
        Layout ? <Layout><Component {...props} /></Layout> : <Component {...props} />
      )}
    />
  )
    : (
      <Redirect
        to={ROUTE_LOGIN}
      />
    )
  );
};
