import {
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { signOut } from '../features/auth/authSlice';

export const errorInterceptor: Middleware = ({ dispatch }) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action;
    if (payload.status === 401) {
      dispatch(signOut());
    }
  }
  return next(action);
};
