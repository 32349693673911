import React, { useEffect } from 'react';

import {
  Form,
  FormField,
  TextInput,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Paragraph,
  Text,
  Anchor,
  ThemeContext,
  Spinner,
} from 'grommet';

import { FiArrowRight } from 'react-icons/fi';
import { Google } from 'grommet-icons';
import { useForm, UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../assets/styles/themes';
import { useIdentity, useSignInLocal } from '../../app/hooks';
import { RouterPath } from '../../app/router';
import { ErrorMessage, ValidationMessage } from '../../components';
import { AuthRequest } from '../../types';
import { isEmail } from '../../utils/validations';
import { googleSSO } from '../../api';

const validationSchema = (
  register: UseFormRegister<AuthRequest>,
  t: any,
): { [key: string]: UseFormRegisterReturn } => ({
  email: register('identifier', {
    required: t('email.validations.required'),
    validate: isEmail(t('email.validations.valid')),
  }),
  password: register('password', {
    required: t('password.validations.required'),
  }),
});

const SignIn = (): React.ReactElement => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<AuthRequest>();
  const [authenticate, {
    data, error, isLoading, isError, isSuccess,
  }] = useSignInLocal();
  const history = useHistory();
  const { identity } = useIdentity();

  const validations = validationSchema(register, t);

  const handleSignIn = (values: AuthRequest): void => {
    authenticate(values);
  };

  useEffect(() => {
    if (isSuccess && identity) {
      if (identity.confirmed) {
        history.replace(RouterPath.ROUTE_HOME);
      } else {
        history.replace(RouterPath.ROUTE_VERIFY_EMAIL);
      }
    }
  }, [isSuccess, identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="xlarge" direction="row-responsive" flex="grow" justify="center">
        <Card
          style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
          width={{ min: '360px', max: '100%' }}
          pad="large"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading level="3" margin="none" color="black" alignSelf="center">Sign In</Heading>
            <Paragraph textAlign="center" alignSelf="center">
              <Text size="small">Don’t have an account? </Text>
              <Anchor
                size="small"
                onClick={() => { history.replace(RouterPath.ROUTE_REGISTER); }}
                label="Create New"
              />
            </Paragraph>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleSignIn)}>
              <FormField
                name="identifier"
                htmlFor="identifier"
                label="Email"
                error={
                  errors.identifier
                  && <ValidationMessage message={errors.identifier.message} />
                }
              >
                <TextInput
                  id="identifier"
                  type="email"
                  maxLength={50}
                  {...validations.email}
                />
              </FormField>
              <FormField
                name="password"
                htmlFor="password"
                label="Password"
                error={
                  errors.password
                  && <ValidationMessage message={errors.password.message} />
                }
              >
                <TextInput
                  id="password"
                  type="password"
                  maxLength={50}
                  {...validations.password}
                />
              </FormField>
              {error && <ErrorMessage error={error} />}
              <Paragraph textAlign="center" alignSelf="center">
                <Text size="small">Forgot Password? </Text>
                <Anchor
                  onClick={() => history.push(RouterPath.ROUTE_FORGOT_PASSWORD)}
                  label="Request a new one"
                />
              </Paragraph>
              <Box align="center" margin={{ top: 'medium' }}>
                <Button
                  type="submit"
                  size="medium"
                  alignSelf="center"
                  primary
                  disabled={isLoading}
                  label="Sign In"
                  icon={isLoading ? <Spinner /> : <FiArrowRight size="24px" />}
                  reverse
                />
              </Box>
              <Box align="center" margin={{ vertical: 'small' }}>
                <Text size="medium" weight="bold" color="dark-6">OR</Text>
              </Box>
              <Box align="center">
                <Button
                  style={{ border: '2px solid #ccc', padding: '10px 20px', borderRadius: '50px' }}
                  href={googleSSO.url}
                  size="medium"
                  plain
                  alignSelf="center"
                  icon={<Google color="plain" />}
                  disabled={isLoading}
                  label="Continue with Google"
                />
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default SignIn;
