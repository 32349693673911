import React from 'react';
import {
  Box,
  Button,
  Paragraph,
} from 'grommet';
import {
  Refresh,
} from 'grommet-icons';

type Props = {
  children: JSX.Element;
}

export const EmptySearchResult = (props: Props): JSX.Element => {
  const { children } = props;

  return (
    <Box
      gap="small"
      pad="small"
      width="100%"
      height="100%"
      align="stretch"
    >
      <Box
        height="100%"
        align="center"
        justify="center"
        width="100%"
        pad="medium"
      >
        {children}
      </Box>
    </Box>
  );
};

export default EmptySearchResult;
