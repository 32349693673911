import React, { useEffect } from 'react';
import {
  Box,
} from 'grommet';

import Header from './Header';
import { useIdentity } from '../../app/hooks';

let cache: NodeJS.Timeout | null = null;
const delay = 1000 * 60 * 60; // 1hour

const MainLayout: React.FC<JSX.Element> = ({ children }) => {
  const { fetchIdentity } = useIdentity();
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!cache) {
      fetchIdentity();
      interval = setInterval(() => {
        fetchIdentity();
      }, delay);
    }
    return () => {
      cache = interval;
    };
  }, []);

  return (
    <Box>
      <Box style={{ zIndex: 3 }}>
        <Header />
      </Box>
      <Box
        style={{ width: '100%', maxWidth: '1920px' }}
        margin="auto"
        gridArea="main"
        justify="between"
        flex="grow"
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
