import React, {
  useState, useEffect,
} from 'react';

import {
  Box,
  InfiniteScroll,
  Heading,
  ResponsiveContext,
} from 'grommet';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
import { ScrollBox } from '../../components';
import PresentationStripSkeleton from './PresentationStripSkeleton';
import PresentationStrip from './PresentationStrip';
import UserPresentationManager from '../userPresentations/UserPresentationManager';
import { useCategoriesQuery } from '../categories/categoriesApiSlice';
import { useLazySearchPresentationsQuery } from '../presentations/presentationsApiSlice';
import EmptySearchResult from './EmptySearchResult';
import EmptyDeck from './EmptyDeck';
import {
  PaginatedResult, Presentation, QueryParams, Slide,
} from '../../types';
import SlideGrid from './SlideGrid';

const filterKey = 'keywords_contains';

export const Deck = (): JSX.Element => {
  const { data: categories } = useCategoriesQuery();
  const [status, setStatus] = useState({ isLoading: false });

  const [slideData, setSlideData] = useState<{
    slides: Slide[];
    heading: string;
    isPaid: boolean;
  } | null>(null);

  // const query = useAppSelector((state) => state.searchQuery.query);
  const [params, setParams] = useState<QueryParams>({
    page: 1,
    pageSize: 5,
    filters: {},
    sort: [{ field: 'displayOrder', order: 'ASC' }],
  });
  const [result, setResult] = useState<PaginatedResult<Presentation>>();

  const location = useLocation();
  const [searchPresentations, {
    isLoading,
    isSuccess,
    isError,
    data,
  }] = useLazySearchPresentationsQuery();
  const { category, q, presentation } = qs.parse(location.search.replace('?', ''));
  const query = (q as string)?.trim();

  useEffect(() => {
    if (data) {
      const { pagination, results } = data;

      if (result && pagination.page > 1) {
        setResult({
          pagination,
          results: [...result.results, ...results],
        });
      } else {
        setResult(data);
      }
      setStatus({ isLoading: false });
    }

    setStatus({ isLoading: false });
  }, [data]);

  useEffect(() => {
    const payload = {
      ...params,
      page: 1,
      filters: {},
    };

    if (category) {
      payload.filters = {
        category: category as string,
      };
    } else if (query) {
      payload.filters = {
        [filterKey]: query,
      };
    } else if (presentation) {
      payload.filters = {
        id: presentation,
      };
    }

    setParams(payload);
  }, [category, query, presentation]);

  useEffect(() => {
    if (!_.isEmpty(params.filters)) {
      setSlideData(null);
      setStatus({ isLoading: true });
      searchPresentations(params);
    }
  }, [params, searchPresentations]);

  const onMoreHandler = (): void => {
    console.log('more');
    if (!isLoading && result) {
      const { pagination } = result;

      if (pagination.pageCount > pagination.page) {
        setParams({
          ...params,
          page: pagination.page + 1,
        });
      }
    }
  };

  console.log('Deck rendered');

  return (
    <>
      <Box pad="none" fill width="100%" gap="none" direction="row-responsive" justify="between">
        <ResponsiveContext.Consumer>
          {(size) => (
            <ScrollBox
              width="xxlarge"
              overflow={{ vertical: 'auto' }}
              wrap
              direction="row"
              gap="none"
              pad="small"
              alignContent="start"
              style={{
                zIndex: 1,
                boxShadow: '1px 0px 0px #ddd',
                maxHeight: 'calc(100vh - 80px)',
                height: `${['small', 'xsmall', 'xxsmall'].includes(size) ? 'auto' : 'calc(100vh - 80px)'}`,
              }}
            >

              {
          slideData && (
          <SlideGrid
            slides={slideData.slides}
            heading={slideData.heading}
            isPaid={slideData?.isPaid}
            onClose={() => setSlideData(null)}
          />
          )
          }

              {
          _.isEmpty(params.filters)
          && <EmptyDeck />
        }
              {
          !isError
          && !isSuccess
          && (
            isLoading
            || status.isLoading
          )
          && [1, 2, 3, 4].map((item) => <PresentationStripSkeleton key={item} />)
        }
              {
          !_.isEmpty(params.filters)
          && isSuccess
          && !!result?.results?.length
          && !slideData
          && (
            <InfiniteScroll
              items={result?.results}
              onMore={onMoreHandler}
              step={10}
              show={0}
            >
              { (item: Presentation) => (
                <PresentationStrip
                  onViewAll={(s) => {
                    setSlideData({
                      slides: [...s],
                      heading: item.name,
                      isPaid: item.paid,
                    });
                  }}
                  key={item.id}
                  presentation={item}
                  categories={categories || []}
                />
              )}
            </InfiniteScroll>
          )
        }
              {
          !_.isEmpty(params.filters)
          && isSuccess
          && !result?.results?.length
          && !slideData
          && (
            <EmptySearchResult>
              <Box
                // height="100%"
                style={{
                  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)',
                }}
                background="background-front"
                width="100%"
                round="small"
                align="center"
                justify="center"
                pad="large"
              >
                <Heading level="4" textAlign="center" style={{ maxWidth: '100%' }}>
                  No presentations found for the specified criteria.<br />
                  Try different keywords.
                </Heading>
              </Box>
            </EmptySearchResult>
          )
        }
              {
          isError
          && (
            <EmptySearchResult>
              <Box
                height="100%"
                style={{
                  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)',
                }}
                background="background-front"
                width="100%"
                round="small"
                align="center"
                justify="center"
                pad="large"
              >
                <Heading
                  level="4"
                  textAlign="center"
                  color="status-error"
                  style={{ maxWidth: '100%' }}
                >
                  Something went wrong<br />
                  Please try again later
                </Heading>
              </Box>
            </EmptySearchResult>
          )
        }
            </ScrollBox>
          )}
        </ResponsiveContext.Consumer>

        <UserPresentationManager />
      </Box>
    </>
  );
};

export default Deck;
