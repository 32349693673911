import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Paragraph,
  Button,
} from 'grommet';
import { Draggable } from 'react-beautiful-dnd';
import { FaTrash } from 'react-icons/fa';

import {
  useOnScreen,
  useCurrentUserPresentation,
} from '../../app/hooks';
import { Identifier, Slide, UserSlide as UserSlideType } from '../../types';
import { StyledImage } from '../../components';
import { getMedia } from '../../utils';

type Props = {
  index: number;
  userSlide: UserSlideType;
}

export const UserSlide = (props: Props): React.ReactElement => {
  const { index, userSlide } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [loaded, setLoaded] = useState(false);

  const {
    deleteCurrentUserSlide,
    deleteCurrentUserSlideState,
  } = useCurrentUserPresentation();

  const slide = userSlide.slide as Slide;

  useEffect(() => {
    if (isVisible) {
      setLoaded(true);
    }
  }, [isVisible]);

  const removeSlide = (): void => {
    deleteCurrentUserSlide(userSlide);
  };

  return (
    <Draggable
      draggableId={(userSlide.id || userSlide.tmpId) as string}
      index={index}
    >
      {(provided) => (
        <Box
          height={{ min: '170px', max: '170px' }}
          margin={{ bottom: 'medium' }}
          pad={{ vertical: 'small', left: 'none', right: 'small' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box
            height={{ min: '170px', max: '170px' }}
            style={{
              position: 'relative',
            }}
            align="start"
            justify="center"
            direction="row"
            ref={ref}
          >
            <Box align="center" justify="center" width="xxsmall">
              <Paragraph margin="none">
                {index + 1}
              </Paragraph>
            </Box>
            <Box
              background="status-critical"
              round="large"
              style={{
                right: '10px',
                top: '10px',
                position: 'absolute',
              }}
            >
              <Button
                style={{
                  padding: '5px',
                }}
                icon={<FaTrash color="white" />}
                plain
                disabled={deleteCurrentUserSlideState.isLoading}
                onClick={removeSlide}
              />
            </Box>
            <Box
              align="center"
              style={{
                border: '1px solid rgb(228 228 228)',
                boxShadow: 'rgb(0 0 0 / 28%) 0px 4px 14px',
              }}
              justify="center"
              width="medium"
              height={{ min: '170px', max: '170px' }}
              round="small"
              overflow="hidden"
            >
              {
                (isVisible || loaded)
                && (
                  <StyledImage
                    image={{
                      blurHash: slide?.blurHash,
                      ...getMedia(slide?.image),
                    }}
                  />
                )
              }
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default UserSlide;
