import React from 'react';
import {
  Box,
} from 'grommet';

import Skeleton from '../Skeleton';
import StyledTag from '../StyledTag';

export const SlideCardSkeleton = (): JSX.Element => (
  <Box
    margin={{ horizontal: 'small' }}
    focusIndicator={false}
    justify="start"
    align="start"
  >
    <Box
      width="100%"
      align="center"
      justify="center"
      round="small"
      overflow="hidden"
      style={{
        position: 'relative',
        border: '2px solid rgb(232, 230, 230)',
        boxShadow: 'rgb(0 0 0 / 11%) 0px 4px 14px',
      }}
    >
      <Box
        align="center"
        justify="center"
        height={{ min: 'small', max: 'small' }}
        direction="row"
        width="100%"
        overflow="hidden"
        wrap={false}
        responsive={false}
      >
        <Skeleton height="200px" />
      </Box>
    </Box>
    <Box margin={{ top: '10px' }}>
      <Skeleton width="80px" height="26px" radius="40px" />
    </Box>
  </Box>
);

export default SlideCardSkeleton;
