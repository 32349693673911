import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';

type SessionResponse = {
  data: {
    id: string;
  };
}
type SessionRequest = {
  successUrl: string;
  cancelUrl: string;
  isYearly: boolean;
}

export const paymentGatewayApiSlice = createApi({
  reducerPath: 'paymentGatewayApi',
  baseQuery,
  endpoints(builder) {
    return {
      freeSubscription: builder.mutation<string, void>({
        query() {
          return {
            url: 'payment-gateway/subscription/free',
            method: 'POST',
          };
        },
      }),
      sessions: builder.mutation<SessionResponse, SessionRequest>({
        query({ successUrl, cancelUrl, isYearly }) {
          return {
            url: 'payment-gateway/sessions',
            method: 'POST',
            body: {
              successUrl: `${process.env.REACT_APP_BASE_URL}${successUrl}`,
              cancelUrl: `${process.env.REACT_APP_BASE_URL}${cancelUrl}`,
              isYearly,
            },
          };
        },
      }),
      billingSession: builder.mutation<SessionResponse, void>({
        query() {
          return {
            url: 'payment-gateway/sessions/billing',
            method: 'POST',
          };
        },
      }),
    };
  },
});

export const {
  useFreeSubscriptionMutation,
  useSessionsMutation,
  useBillingSessionMutation,
} = paymentGatewayApiSlice;
