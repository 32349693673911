import React, { useEffect } from 'react';

import {
  Box,
  Heading,
  Spinner,
} from 'grommet';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useSignInGoogle, useAppDispatch,
} from '../../app/hooks';
import { RouterPath } from '../../app/router';
import { processError } from '../../utils';

const GoogleAuthCallback = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [googleApi, {
    data: googleData,
    isSuccess: googleSuccess,
    error,
  }] = useSignInGoogle();

  const history = useHistory();
  const location = useLocation();
  let emailTaken = false;
  if (error) {
    const res = processError(error);
    emailTaken = res.detail.code === 'Auth.form.error.email.taken';
  }
  useEffect(() => {
    if (!location) {
      return;
    }
    const { search } = location;

    if (googleData && googleSuccess) {
      const { user } = googleData;
      if (!user.blocked) {
        history.replace(RouterPath.ROUTE_HOME);
      }
    } else {
      googleApi(search);
    }
  }, [dispatch, googleApi, googleData, googleSuccess, history, location]);

  return (
    <Box pad="medium">
      {
        !emailTaken && !googleSuccess && <Spinner alignSelf="center" size="large" />
      }
      {
        googleData?.user.blocked && (
        <Box align="center">
          <Heading level="3" textAlign="center">
            Your Account has been blocked by the Administrator
          </Heading>
        </Box>
        )
      }
      {emailTaken && (
      <Box align="center">
        <Heading level="3" textAlign="center">
          Email is already taken
        </Heading>
      </Box>
      )}
    </Box>
  );
};

export default GoogleAuthCallback;
