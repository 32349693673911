import React from 'react';
import {
  Button,
  Heading,
  Text,
  Layer,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Box,
} from 'grommet';

export type ConfirmationValueType = 'yes' | 'no';
interface Props {
  onClick: ((c?: ConfirmationValueType) => void) | null;
}

export const LimitExceedWarningDialog = (props: Props): JSX.Element | null => {
  const { onClick } = props;
  if (!onClick) return null;
  return (
    <Layer
      responsive={false}
      style={{
        padding: '0',
        background: 'transparent',
        justifyContent: 'center',
      }}
      onAbort={() => onClick()}
      onClickOutside={() => onClick()}
      onEsc={() => onClick()}
    >
      <Card
        style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
        width={{ min: '360px', max: '100%' }}
        pad="large"
        alignSelf="center"
        justify="center"
        background="background-front"
      >
        <CardHeader gap="none" direction="column" align="center" alignContent="center">
          <Heading level={3} margin="none">
            Limit exceeded
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box pad={{ bottom: 'medium' }}>
            <Text textAlign="center">Looks like you have exceeded the 100 slides or 10MB limit for the presentation.</Text>
          </Box>
          <Text textAlign="center">Do you want to create a new presentation?</Text>
        </CardBody>
        <CardFooter alignSelf="center" gap="small">
          <Button
            size="medium"
            secondary
            label={<Text color="brand">No</Text>}
            onClick={() => onClick('no')}
          />
          <Button
            size="medium"
            primary
            label={<Text>Yes</Text>}
            onClick={() => onClick('yes')}
          />
        </CardFooter>

      </Card>
    </Layer>
  );
};

export default LimitExceedWarningDialog;
