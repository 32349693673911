import React, { useEffect } from 'react';

import {
  ThemeContext,
  Form,
  FormField,
  TextInput,
  CheckBox,
  Text,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Paragraph,
  Anchor,
  Spinner,
} from 'grommet';
import { FiArrowRight } from 'react-icons/fi';
import { Google } from 'grommet-icons';
import {
  useForm,
  UseFormRegister,
  UseFormWatch,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../assets/styles/themes';
import { useIdentity, useSignUpLocal } from '../../app/hooks';
import { User, SignUpRequest } from '../../types';
import { RouterPath } from '../../app/router';
import { ErrorMessage, ValidationMessage } from '../../components';
import { isEmail, isStrongPassword } from '../../utils/validations';
import { googleSSO } from '../../api';
import PasswordPolicy from '../auth/PasswordPolicy';

interface FormFields extends User {
  password: string;
  tnc: boolean;
  optIn?: boolean;
  confirmPassword: string;
}

const validationSchema = (
  register: UseFormRegister<FormFields>,
  watch: UseFormWatch<FormFields>,
  t: any,
): { [key: string]: UseFormRegisterReturn } => {
  const passwordTyped = watch('password', '');

  return {
    referralCode: register('referralCode', { required: false }),
    firstName: register('firstName', {
      required: t('name.first.validations.required'),
      minLength: {
        value: 3,
        message: t('name.first.validations.min', { min: 3 }),
      },
    }),
    lastName: register('lastName', {
      required: t('name.last.validations.required'),
      minLength: {
        value: 3,
        message: t('name.last.validations.min', { min: 3 }),
      },
    }),
    email: register('email', {
      required: t('email.validations.required'),
      validate: isEmail(t('email.validations.valid')),
    }),
    password: register('password', {
      required: t('password.validations.required'),
      validate: isStrongPassword(t('password.validations.strong')),
    }),
    confirmPassword: register('confirmPassword', {
      required: t('confirmPassword.validations.required'),
      validate: (value: string): string | undefined => {
        if (value === passwordTyped) {
          return undefined;
        }

        return t('confirmPassword.validations.mismatch');
      },
    }),
    tnc: register('tnc', { required: t('tnc.validations.required') }),
    optIn: register('optIn', { required: false }),
  };
};

const SignUp = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormFields>();
  const history = useHistory();
  const { t } = useTranslation();
  const [signUp, {
    data, error, isLoading, isError, isSuccess,
  }] = useSignUpLocal();
  const { identity } = useIdentity();

  const validation = validationSchema(register, watch, t);

  useEffect(() => {
    if (isSuccess && identity) {
      if (identity.confirmed) {
        history.replace(RouterPath.ROUTE_HOME);
      } else {
        history.replace(RouterPath.ROUTE_VERIFY_EMAIL);
      }
    }
  }, [isSuccess, identity, history]);

  const handleSignUp = (values: FormFields): void => {
    const {
      confirmPassword,
      ...rest
    } = values;

    const payload: SignUpRequest = {
      username: rest.email,
      ...rest,
    };

    signUp(payload);
  };

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="xlarge" direction="row-responsive" flex="grow" justify="center">
        <Card
          style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
          width={{ min: '360px', max: '476px' }}
          pad="large"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading
              level="3"
              color="black"
              margin="none"
              alignSelf="center"
            >
              Create Account
            </Heading>
            <Paragraph textAlign="center" alignSelf="center">
              <Text size="small">Already have an account? </Text>
              <Anchor
                size="small"
                onClick={() => { history.push(RouterPath.ROUTE_LOGIN); }}
                label="Sign In"
              />
            </Paragraph>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleSignUp)}>
              <Heading margin={{ bottom: 'small' }} level="5" color="brand">
                Have you been sent an invite/referral code?
              </Heading>
              <FormField
                name="referralCode"
                htmlFor="referralCode"
                label="Referral Code"
                size={1000}
              >
                <TextInput
                  id="referralCode"
                  type="text"
                  placeholder="3D4O83DQ"
                  maxLength={50}
                  {...validation.referralCode}
                />
              </FormField>
              <Box
                direction="row-responsive"
                gap="small"
                style={{ borderTop: '1px solid #ccc', paddingTop: '20px' }}
              >
                <FormField
                  name="firstName"
                  htmlFor="firstName"
                  label="First Name"
                  error={
                    errors.firstName
                    && (
                      <ValidationMessage message={errors.firstName.message} />
                    )
                  }
                >
                  <TextInput
                    id="firstName"
                    maxLength={50}
                    {...validation.firstName}
                  />
                </FormField>
                <FormField
                  name="lastName"
                  htmlFor="lastName"
                  label="Last Name"
                  error={
                    errors.lastName
                    && (
                      <ValidationMessage message={errors.lastName.message} />
                    )
                  }
                >
                  <TextInput
                    id="lastName"
                    maxLength={50}
                    {...validation.lastName}
                  />
                </FormField>
              </Box>
              <FormField
                name="email"
                htmlFor="email"
                label="Email"
                size={1000}
                error={
                  errors.email
                  && (
                    <ValidationMessage message={errors.email.message} />
                  )
                }
              >
                <TextInput
                  id="email"
                  type="email"
                  maxLength={50}
                  {...validation.email}
                />
              </FormField>
              <Box direction="row-responsive" gap="small">
                <FormField
                  name="password"
                  htmlFor="password"
                  label="Password"
                  error={
                    errors.password
                    && (
                      <ValidationMessage message={errors.password.message} />
                    )
                  }
                >
                  <TextInput
                    id="password"
                    type="password"
                    maxLength={50}
                    {...validation.password}
                  />
                </FormField>
                <FormField
                  name="confirmPassword"
                  htmlFor="confirmPassword"
                  label="Confirm Password"
                  error={
                    errors.confirmPassword
                    && (
                      <ValidationMessage message={errors.confirmPassword.message} />
                    )
                  }
                >
                  <TextInput
                    id="confirmPassword"
                    type="password"
                    maxLength={50}
                    {...validation.confirmPassword}
                  />
                </FormField>
              </Box>
              <PasswordPolicy />
              <ThemeContext.Extend
                value={{ formField: { border: false } }}
              >
                <FormField
                  name="tnc"
                  htmlFor="tnc"
                  border={false}
                  error={
                    errors.tnc
                    && (
                      <ValidationMessage message={errors.tnc.message} />
                    )
                  }
                >
                  <CheckBox
                    label={(
                      <Text size="small">
                        <Text size="xsmall">Yes, I accept the </Text>
                        <Anchor
                          size="xsmall"
                          href={RouterPath.ROUTE_TERMS_N_CONDITIONS}
                          label="Terms of Use"
                          target="_blank"
                        />
                        <Text size="xsmall"> and </Text>
                        <Anchor
                          size="xsmall"
                          href={RouterPath.ROUTE_PRIVACY_POLICY}
                          label="Privacy Policy"
                          target="_blank"
                        />.
                      </Text>
                    )}
                    {...validation.tnc}
                  />
                </FormField>
                <FormField name="optIn" htmlFor="optIn">
                  <CheckBox
                    label={(
                      <Text size="xsmall">
                        {t('optIn.label')}
                      </Text>
                    )}
                    {...validation.optIn}
                  />
                </FormField>
              </ThemeContext.Extend>
              { error && <ErrorMessage error={error} /> }
              <Box align="center" pad={{ top: 'large' }}>
                <Button
                  type="submit"
                  size="medium"
                  alignSelf="center"
                  primary
                  disabled={isLoading}
                  label="Sign Up"
                  icon={isLoading ? <Spinner /> : <FiArrowRight size="24px" />}
                  reverse
                />
              </Box>
              <Box align="center" margin={{ top: 'small' }}>
                <Text size="medium" weight="bold" color="dark-6">OR</Text>
              </Box>
              <Box align="center" margin={{ top: 'small' }}>
                <Button
                  style={{ border: '2px solid #ccc', padding: '10px 20px', borderRadius: '50px' }}
                  href={googleSSO.url}
                  size="medium"
                  alignSelf="center"
                  icon={<Google color="plain" />}
                  disabled={isLoading}
                  label="Continue with Google"
                />
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default SignUp;
