import Crypto, { AES } from 'crypto-js';

export type cryptoResult = {
  encrypt(message: string): string,
  decrypt(message: string): string,
};

export const crypto = (passPhrase: string): cryptoResult => ({
  encrypt(message: string) {
    return AES.encrypt(message, passPhrase).toString();
  },

  decrypt(message: string) {
    return AES.decrypt(message, passPhrase).toString(Crypto.enc.Utf8);
  },
});
