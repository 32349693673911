import React, { useContext } from 'react';
import {
  Box,
  Image,
  Anchor,
  Header as GrommetHeader,
  ResponsiveContext,
} from 'grommet';

import {
  Menu,
} from 'grommet-icons';
import { MobileLogo, DesktopLogo } from '../../components/Logo';
import { UserMenu, LeftBar } from '../../components';
import CategoryDrop from '../../features/categoryDrop/CategoryDrop';
import SearchBox from './SearchBox';

export const Header = (): React.ReactElement => {
  const responsive = useContext(ResponsiveContext);
  const [showLeftBar, setShowLeftBar] = React.useState(false);
  return (
    <Box
      fill="vertical"
      overflow="auto"
      pad={{ vertical: 'none', horizontal: `${responsive}` }}
      style={{ boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)' }}
      align="center"
      background="background-front"
      flex="grow"
    >
      <Box
        width={{ max: '1920px', width: '100%' }}
      >
        <Box animation={showLeftBar ? 'fadeIn' : 'fadeOut'}>
          {showLeftBar && (
            <LeftBar onCloseHandler={() => setShowLeftBar(false)} />
          )}
        </Box>
        <GrommetHeader background="white" pad="small" width="100%">
          {(responsive === 'small' ? (
            <Box pad="small" direction="row" align="center" flex="grow" gap="small">
              <Box>
                <Menu onClick={() => { setShowLeftBar(true); }} />
              </Box>
              <Box width="100%" margin={{ left: 'small' }}>
                <SearchBox borderRadius="50px" height="35px" />
              </Box>
              <Box>
                <MobileLogo width={35} />
              </Box>
            </Box>
          ) : (
            <Box direction="row" gap="medium" flex="grow">
              <Box direction="row" align="center" justify="center" gap="large">
                <DesktopLogo width={135} />
                <CategoryDrop />
              </Box>
              <Box
                direction="row"
                align="center"
                justify="center"
                flex="grow"
                gap="large"
              >
                <SearchBox borderRadius="50px" />
              </Box>
              <Box direction="row" align="center">
                <UserMenu />
              </Box>
            </Box>
          ))}

        </GrommetHeader>
      </Box>
    </Box>
  );
};
export default Header;
