import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';
import { User } from '../../types';

type UpdateUser = {
  id:string
  firstName:string
  lastName:string
  tnc:boolean
  optIn:boolean
  referralCode:string
}

export const usersApiSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  endpoints(builder) {
    return {
      identity: builder.query<User, void>({
        query() {
          return {
            url: 'users/me',
            method: 'GET',
          };
        },
      }),
      update: builder.mutation<User, UpdateUser>({
        query(payload) {
          const { id, ...data } = payload;
          return {
            url: `users/${id}`,
            method: 'PUT',
            body: data,
          };
        },
      }),
    };
  },
});

export const {
  useUpdateMutation,
  useIdentityQuery,
  useLazyIdentityQuery,
} = usersApiSlice;
