import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Grid,
  Heading,
  InfiniteScroll,
  ResponsiveContext,
  Text,
  Button,
} from 'grommet';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
  useLazyFetchUserPresentationsQuery,
} from '../userPresentationsApiSlice';

import UserPresentationCard from './UserPresentationCard';
import { Pagination, UserPresentation } from '../../../types';
import DeleteConfirmation, { ConfirmationValueType } from './DeleteConfirmation';
import UserPresentationCardSkeleton from './UserPresentationCardSkeleton';
import DownloadInfo from './DownloadInfo';
import { ROUTE_DECK } from '../../../app/router/route-path.constants';
import NewUserPresentation from '../NewUserPresentation';

export const UserPresentations = (): JSX.Element | null => {
  const [showNewUserPresentation, setShowNewUserPresentation] = useState(false);
  const history = useHistory();
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [
    confirmationCallback,
    setConfirmationCallback,
  ] = useState<((p?: ConfirmationValueType)=>void) | null>(null);

  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const [items, setItems] = useState<UserPresentation[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const size = useContext(ResponsiveContext);
  const [getPresentations, { data, isSuccess }] = useLazyFetchUserPresentationsQuery();

  const onMoreHandler = (): void => {
    const { pageCount } = data?.pagination as Pagination;
    if (currentPage < pageCount && !isLoadMore) {
      setIsLoadMore(true);
      setCurrentPage(currentPage + 1);
    }
  };

  const onCloseDialogHandler = (value: string):void => {
    setShowNewUserPresentation(false);
    if (value === 'success') {
      history.push(ROUTE_DECK);
    }
  };

  useEffect(() => {
    if (data) {
      if (data.results.length) {
        setItems([...items, ...data.results]);
      }
      setIsLoadMore(false);
    }
  }, [data]);

  useEffect(() => {
    getPresentations({
      page: currentPage,
      pageSize: 10,
      sort: [{ field: 'updatedAt', order: 'DESC' }],
    });
  }, [currentPage, getPresentations]);

  const getColSize = (): string => {
    if (size === 'small') return '100%';
    if (size === 'medium') return '30%';
    if (size === 'large') return '18%';
    return '18%';
  };

  const onDeleteHandler = (up: UserPresentation): void => {
    const fItems = items.filter((item) => up.id !== item.id);
    setItems(fItems);
  };

  return (
    <>
      {
        showNewUserPresentation && (
        <NewUserPresentation
          onClose={(value) => onCloseDialogHandler(value as string)}
        />
        )
      }
      {showDownloadInfo && <DownloadInfo onClose={() => setShowDownloadInfo(false)} />}
      <DeleteConfirmation
        onClick={confirmationCallback}
      />
      <Box margin="medium" align="center" background="background-front" style={{ borderRadius: '10px' }} justify="center" pad="small" elevation="small">
        <Box pad={{ horizontal: 'large', vertical: 'none' }} justify="between" width="full" align="center" direction="row-responsive" alignSelf="start">
          <Heading
            alignSelf="start"
            level="4"
            size="small"
            color="black"
          >My Presentations
          </Heading>
          <Button
            onClick={() => setShowNewUserPresentation(true)}
            label={<Text color="white">Create New</Text>}
            primary
            icon={<FaPlus color="white" />}
            color="status-ok"
            size="large"
          />
        </Box>
        <Box pad={{ horizontal: 'large', vertical: 'medium' }} alignSelf="center" alignContent="center" fill>
          {items.length
            ? (
              <Grid columns={getColSize()} gap="medium" width="100%">
                <InfiniteScroll
                  items={items}
                  onMore={onMoreHandler}
                  step={10}
                >
                  {(item: UserPresentation) => (
                    <Box
                      key={item.id}
                      align="center"
                    >
                      <UserPresentationCard
                        key={item.id}
                        onDelete={(up: UserPresentation) => onDeleteHandler(up)}
                        userPresentation={item}
                        onDeleteConfirmation={(callback) => {
                          setConfirmationCallback(() => (p: ConfirmationValueType) => {
                            callback(p);
                            setConfirmationCallback(null);
                          });
                        }}
                        onDownload={() => setShowDownloadInfo(true)}
                      />
                    </Box>
                  )}
                </InfiniteScroll>
              </Grid>
            ) : null}
          {isLoadMore && (
          <Box>
            <Grid columns={getColSize()} gap="medium" width="100%">
              {
                [1, 2, 3, 4, 5, 6].map((item) => (
                  <Box align="center" key={item}>
                    <UserPresentationCardSkeleton />
                  </Box>
                ))
              }
            </Grid>
          </Box>
          )}
          {!items.length && isSuccess && data && !isLoadMore
            ? (
              <Box
                margin={{ vertical: 'auto' }}
                align="center"
              >
                <Text textAlign="center" size="small" style={{ fontStyle: 'italic' }}>
                  There are no presentations found
                </Text>
              </Box>
            ) : null }
        </Box>
      </Box>
    </>
  );
};

export default UserPresentations;
