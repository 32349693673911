import {
  Box,
} from 'grommet'; import styled from 'styled-components';

export const StyledTag = styled(Box)`
border: 2px solid #afafaf;
background: white;
border-radius: 40px;
margin-top: 10px;
padding: 1px 10px;
-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`;

export default StyledTag;
