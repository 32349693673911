import React from 'react';
import {
  Box,
  Button,
  Paragraph,
} from 'grommet';
import {
  Refresh,
} from 'grommet-icons';

type Props = {
  onClick: () => void;
}

export const EmptyPresentation = (props: Props): JSX.Element => {
  const { onClick } = props;

  return (
    <Box
      gap="small"
      pad="small"
      width="100%"
    >
      <Box
        align="center"
        justify="center"
        width="100%"
        pad="medium"
      >
        <Paragraph textAlign="center">
          There are no slides in the presentation.
        </Paragraph>
        <Button icon={<Refresh />} onClick={onClick} />
      </Box>
    </Box>
  );
};

export default EmptyPresentation;
