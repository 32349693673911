import { createApi } from '@reduxjs/toolkit/query/react';

import { AuthRequest, AuthResponse, SignUpRequest } from '../../types';
import { baseQuery, googleSSO } from '../../api';

type ForgotPassword= {
  email:string
}

type ResetPassword= {
  code:string,
  password:string,
  passwordConfirmation:string,
}

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints(builder) {
    return {
      signUpLocal: builder.mutation<AuthResponse, SignUpRequest>({
        query(payload) {
          return {
            url: 'auth/local/register',
            method: 'POST',
            body: payload,
          };
        },
      }),

      authenticateLocal: builder.mutation<AuthResponse, AuthRequest>({
        query(payload) {
          return {
            url: 'auth/local',
            method: 'POST',
            body: payload,
          };
        },
      }),

      authenticateGoogle: builder.mutation<AuthResponse, string>({
        query(payload) {
          const { queryString } = googleSSO;
          return {
            url: `auth/google/callback${payload}${queryString}`,
            method: 'GET',
          };
        },
      }),

      verifyEmail: builder.mutation<void, string>({
        query(code) {
          return {
            url: `auth/email-confirmation?confirmation=${code}`,
            // validateStatus(response, result) {
            //   console.log(response.status);
            //   return response.status === 302;
            // },
          };
        },
      }),

      forgotPassword: builder.mutation<string, ForgotPassword>({
        query(payload) {
          return {
            url: 'auth/forgot-password',
            method: 'POST',
            body: payload,
          };
        },
      }),

      resetPassword: builder.mutation<AuthResponse, ResetPassword>({
        query(payload) {
          return {
            url: 'auth/reset-password',
            method: 'POST',
            body: payload,
          };
        },
      }),

    };
  },
});

export const {
  useAuthenticateGoogleMutation,
  useSignUpLocalMutation,
  useAuthenticateLocalMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice;
