import React, { useContext } from 'react';

import {
  Box,
  Grid,
  Paragraph,
  ResponsiveContext,
} from 'grommet';

type Props = {
  image: JSX.Element;
  heading: JSX.Element;
  desc: JSX.Element;
}

const getWidth = (size: string) => {
  switch (size) {
    case 'small':
    case 'xsmall':
      return { min: '100%', max: '100%' };
    case 'medium':
      return { min: '49%', max: '49%' };
    case 'large':
      return { min: '24%', max: '24%' };
    default:
      return { min: '25%', max: '25%' };
  }
};
export const PresentationCardSkeleton = (props: Props): JSX.Element => {
  const { image, heading, desc } = props;
  const size = useContext(ResponsiveContext);

  return (
    <Box
      align="stretch"
      justify="between"
      round="9px"
      overflow="hidden"
      width="100%"
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        overflow="hidden"
        wrap={false}
        responsive={false}
        background="#e4e9ed"
        style={{ borderBottom: '2px solid #e4e9ed' }}
      >
        {image}
      </Box>
      <Box
        height={{ min: '126px' }}
        align="stretch"
        justify="start"
        pad={{ horizontal: 'none', bottom: 'medium', top: 'small' }}
      >
        <Grid
          columns={['flex']}
          align="stretch"
        >
          {heading}
        </Grid>
        <Paragraph margin="none" size="small">
          {desc}
        </Paragraph>
      </Box>
    </Box>
    // <Box
    //   align="stretch"
    //   width={getWidth(size)}
    //   justify="center"
    //   round="small"
    //   elevation="medium"
    //   overflow="hidden"
    // >
    //   <Box
    //     align="center"
    //     justify="center"
    //     height={{ min: 'small', max: 'small' }}
    //     direction="row"
    //     overflow="hidden"
    //     wrap={false}
    //     responsive={false}
    //   >
    //     {image}
    //   </Box>
    //   <Box
    //     direction="column"
    //     align="stretch"
    //     justify="start"
    //     pad={{ horizontal: 'small', bottom: 'medium' }}
    //   >
    //     <Grid
    //       columns={['flex', '70px']}
    //       align="stretch"
    //     >
    //       {heading}
    //     </Grid>
    //     <Paragraph margin="none" size="small">
    //       {desc}
    //     </Paragraph>
    //   </Box>
    // </Box>
  );
};

export default PresentationCardSkeleton;
