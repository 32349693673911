import React, { useState, useEffect } from 'react';
import {
  Anchor,
  Box,
  Button,
  Text,
} from 'grommet';

import { IoEyeOutline } from 'react-icons/io5';
import { FiDownload } from 'react-icons/fi';

import {
  useAppDispatch,
  useAppSelector,
  useCurrentUserPresentation,
} from '../../app/hooks';
import UserPresentationName from './UserPresentationName';
import UserSlideList from './UserSlideList';
import { setImages } from '../imagePreview/imagePreviewSlice';
import NewUserPresentation from './NewUserPresentation';
import { Slide } from '../../types';
import DownloadInfo from './myPresentations/DownloadInfo';
import { ROUTE_USER_PRESENTATION_DOWNLOAD } from '../../app/router/route-path.constants';

export const UserPresentationManager = (): JSX.Element => {
  const {
    presentation: userPresentation,
    slides,
  } = useAppSelector((state) => state.userPresentations);
  const [showNewPresentationDialog, setShowNewPresentationDialog] = useState(false);
  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const dispatch = useAppDispatch();
  const {
    fetchCurrentUserPresentation,
  } = useCurrentUserPresentation();

  useEffect(() => {
    fetchCurrentUserPresentation();
  }, []);

  const onPreviewHandler = (): void => {
    const previewImages = slides.map((item) => {
      const { id, image } = item.slide as Slide;
      return { id: id as string, url: image?.url };
    });
    dispatch(setImages({ images: previewImages, index: 0, show: true }));
  };

  const onNewHandler = (): void => {
    setShowNewPresentationDialog(true);
  };

  const onCloseDialogHandler = (): void => {
    setShowNewPresentationDialog(false);
  };

  return (
    <>
      {showDownloadInfo && <DownloadInfo onClose={() => setShowDownloadInfo(false)} />}
      {
        showNewPresentationDialog
        && (
          <NewUserPresentation
            onClose={onCloseDialogHandler}
          />
        )
      }
      <Box
        alignSelf="center"
        direction="column"
        background="light-3"
        pad="none"
        gap="none"
        justify="between"
        width={{ min: '360px', max: '385px' }}
        style={{
          borderLeft: '1px solid #ececec',
          borderRight: '1px solid #ececec',
          zIndex: 1,
          minHeight: '100%',
          width: '100%',
        }}
      >
        <Box>
          <UserPresentationName
            userPresentation={userPresentation}
            onNewPresentation={onNewHandler}
          />
          <UserSlideList onNewPresentation={onNewHandler} />
        </Box>
        <Box
          align="center"
          justify="between"
          direction="row"
          background={{ color: 'dark-1' }}
          pad={{ vertical: 'small', horizontal: 'medium' }}
        >
          <Anchor
            disabled={!slides.length}
            target="_blank"
            href={
              ROUTE_USER_PRESENTATION_DOWNLOAD.replace(':presentationId', userPresentation?.id as string)
            }
            onClick={() => setShowDownloadInfo(true)}
            style={{ outline: 'none', boxShadow: 'none' }}
          >
            <Button disabled={!slides.length} icon={<FiDownload color="white" size="24px" />} />
          </Anchor>
          <Button
            disabled={!slides.length}
            label={<Text color="white">Preview</Text>}
            primary
            icon={<IoEyeOutline color="white" />}
            reverse={false}
            active={false}
            color="status-ok"
            size="large"
            onClick={onPreviewHandler}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserPresentationManager;
