import React, { useEffect } from 'react';

import {
  Form,
  FormField,
  TextInput,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Paragraph,
  Text,
  Anchor,
  ThemeContext,
  Spinner,
} from 'grommet';
import { FiArrowRight } from 'react-icons/fi';
import { useForm, UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../../assets/styles/themes';
import { useIdentity } from '../../../app/hooks';
import { RouterPath } from '../../../app/router';
import { ErrorMessage, ValidationMessage } from '../../../components';
import { isEmail } from '../../../utils/validations';
import { useForgotPasswordMutation } from '../authApiSlice';

interface FormFields {
  email: string;
}

const validationSchema = (
  register: UseFormRegister<FormFields>,
  t: any,
): { [key: string]: UseFormRegisterReturn } => ({
  email: register('email', {
    required: t('email.validations.required'),
    validate: isEmail(t('email.validations.valid')),
  }),
});

const ForgotPassword = (): React.ReactElement => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<FormFields>();
  const [forgotPassword, {
    error, isLoading, isSuccess,
  }] = useForgotPasswordMutation();
  const history = useHistory();
  const { identity } = useIdentity();

  const validations = validationSchema(register, t);

  useEffect(() => {
    if (isSuccess) {
      history.replace(RouterPath.ROUTE_FORGOT_PASSWORD_INSTRUCTIONS);
    }
  }, [isSuccess, identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad={{ vertical: 'xlarge', horizontal: 'small' }} direction="row-responsive" flex="grow" justify="center">
        <Card
          width={{ min: '360px', max: '476px' }}
          pad="large"
          style={{ boxShadow: '0px 1px 20px -3px rgba(0, 0, 0, 0.25)' }}
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading level="3" margin="none" color="black" alignSelf="center">Reset Password</Heading>
            <Paragraph textAlign="center" alignSelf="center">
              <Text size="small">Enter the email associated with your account and we&apos;ll
                send an email with instructions to reset your password.
              </Text>
            </Paragraph>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(forgotPassword)}>
              <FormField
                name="email"
                htmlFor="email"
                label="Email"
                error={
                  errors.email
                  && <ValidationMessage message={errors.email.message} />
                }
              >
                <TextInput
                  id="identifier"
                  type="email"
                  maxLength={50}
                  {...validations.email}
                />
              </FormField>
              {error && <ErrorMessage error={error} />}
              <Box align="center" justify="between" direction="row" margin={{ top: 'medium' }}>
                <Button
                  size="medium"
                  alignSelf="center"
                  plain
                  disabled={isLoading}
                  label={<Text color="brand">Back</Text>}
                  reverse
                  onClick={() => history.goBack()}
                />
                <Button
                  type="submit"
                  size="medium"
                  alignSelf="center"
                  primary
                  disabled={isLoading}
                  label="Send Instructions"
                  icon={isLoading ? <Spinner /> : <FiArrowRight size="24px" />}
                  reverse
                />
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default ForgotPassword;
