import React, { useEffect, useState } from 'react';
import {
  Anchor,
  Box, Heading, Paragraph, Spinner, Text,
} from 'grommet';
import { format } from 'date-fns';

import { useHistory } from 'react-router-dom';
import { RouterPath } from '../../app/router';
import { useBillingSessionMutation } from '../paymentGateway/paymentGatewayApiSlice';
import UpgradeSubscription from '../../components/upgradeSubscription';
import { useIdentity } from '../../app/hooks';

const UserSettings = (): React.ReactElement => {
  const { identity } = useIdentity();
  const [showSubscription, setShowSubscription] = useState(false);
  const history = useHistory();

  const [billingSession, {
    data, error, isSuccess, isLoading,
  }] = useBillingSessionMutation();
  const onStripeBilling = (): void => {
    billingSession();
  };
  useEffect(() => {
    if (!error && isSuccess && data) {
      const { url } = data as any;
      window.location = url;
    }
  }, [data, error, isSuccess]);
  return (
    <>
      {showSubscription && (
        <UpgradeSubscription
          onCloseHandler={() => setShowSubscription(false)}
        />
      )}
      <Box
        margin="medium"
        align="start"
        background="background-front"
        style={{ borderRadius: '10px' }}
        justify="center"
        pad="small"
        elevation="small"
      >
        <Box pad={{ horizontal: 'large', vertical: 'none' }}>
          <Heading
            margin={{ bottom: 'medium' }}
            level="4"
            size="small"
            color="black"
          >
            User Settings
          </Heading>
        </Box>
        <Box
          pad={{ horizontal: 'large', vertical: 'none', bottom: 'medium' }}
          alignSelf="start"
          alignContent="start"
          fill
        >
          { identity?.referralCode && (
            <>
              <Paragraph margin={{ bottom: 'none' }}>
                Share this code with your friends. Once 10 people sign up using your code,
                you&apos;ll get a year&apos;s subscription for free!
              </Paragraph>
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">My Referral Code: </Text>
                <Text size="small" weight="bold" color="brand">
                  {identity?.referralCode}
                </Text>
              </Paragraph>
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Active Referrals: </Text>
                <Text size="small" weight="bold" color="brand">
                  {identity?.activeReferrals}
                </Text>
              </Paragraph>
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Redeemed Referrals: </Text>
                <Text size="small" weight="bold" color="brand">
                  {identity?.redeemedReferrals}
                </Text>
              </Paragraph>
            </>
          )}
          <div
            style={{
              marginTop: '20px', borderTop: '1px solid #ccc', width: '500px', maxWidth: '100%',
            }}
          />
          {
            identity?.subscription && (
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Upplo Plan: </Text>
                <Text size="small" weight="bold" color="brand">
                  {identity?.subscription?.type === 'Paid' ? 'Premium' : 'Free'}
                </Text>
              </Paragraph>
            )
          }
          {
            identity?.subscription?.renewalDate && (
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Plan valid till: </Text>
                <Text size="small" weight="bold" color="brand">
                  {format(new Date(identity?.subscription?.renewalDate), 'dd MMM, yyyy')}
                </Text>
              </Paragraph>
            )
          }
          {
            identity?.subscription?.type === 'Free' && (
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Upgrade your </Text>
                <Anchor
                  onClick={() => setShowSubscription(true)}
                  label="subscription"
                />
              </Paragraph>
            )
          }
          {
            !!identity?.subscription?.stripeCustomerId && (
              <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
                <Text size="small">Show Upplo </Text>
                <Anchor
                  disabled={isLoading}
                  onClick={() => onStripeBilling()}
                  label="billing history / subscription status"
                  weight="bold"
                  reverse
                />

              </Paragraph>
            )
          }
          <br />
          <Paragraph textAlign="start" alignSelf="start" margin={{ bottom: 'none' }}>
            <Text size="small">Follow this link to </Text>
            <Anchor
              onClick={() => { history.push(RouterPath.ROUTE_FORGOT_PASSWORD); }}
              label="reset your password"
              weight="bold"
            />
          </Paragraph>
        </Box>
      </Box>
    </>
  );
};
export default UserSettings;
