import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  TextInput,
  ThemeContext,
  ThemeType,
  Spinner,
} from 'grommet';

import {
  Add,
  Edit,
  FormCheckmark,
  FormClose,
} from 'grommet-icons';
import { useForm, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserPresentation } from '../../types';
import {
  useFocus,
  useCurrentUserPresentation,
} from '../../app/hooks';
import storage from '../../storage';

// export const CustomFormField = styled(FormField)`
//   background: white;
//   padding: 10px;
//   border: none !important;
// `;
// export const CustomTextInput = styled(TextInput)`
//   background: white;
//   border: none !important;
//   padding: 10px;
// `;
export const formTheme: ThemeType = {
  global: {
    size: {
      medium: '480px',
    },
    focus: {
      border: {
        color: 'brand',
      },
    },
  },
  formField: {
    label: {
      size: 'small',
      margin: {
        start: '0',
      },
    },
    content: {
      margin: 'xxsmall',
      pad: 'xsmall',
    },
    margin: 'none',
    error: {
      margin: {
        start: '0',
      },
    },
  },
  button: {
    size: {
      medium: {
        border: {
          radius: '24px',
        },
        pad: {
          vertical: '8px',
        },
      },
    },
  },
};

export type Props = {
  userPresentation: UserPresentation | null;
  onNewPresentation: () => void;
}

type IntervalProps = {
  userPresentation: UserPresentation | null;
  onNew: () => void;
  onEdit: () => void;
}

type UserPresentationSchema = {
  name: string;
}

const validationSchema = (
  register: UseFormRegister<UserPresentationSchema>,
  t: any,
): { [key: string]: UserPresentationSchema } => ({
  name: register('name', {
    required: t('name.validations.required'),
    validate: (value: string) => !!value.trim(),
  }),
});

const UserPresentationForm = (
  { userPresentation, onCancel }: {
    userPresentation: UserPresentation | null;
    onCancel?: () => void;
  },
): JSX.Element => {
  const [inputRef, setInputFocus] = useFocus();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserPresentationSchema>();
  const validations = validationSchema(register, t);
  const {
    saveCurrentUserPresentation,
    saveCurrentUserPresentationState,
  } = useCurrentUserPresentation();

  const {
    isLoading,
    isSuccess,
    isError,
  } = saveCurrentUserPresentationState;

  useEffect(() => {
    setInputFocus();
  }, [inputRef]);

  useEffect(() => {
    if (isSuccess) {
      onCancel?.();
    }
  }, [isSuccess]);

  const handleSave = (values: UserPresentationSchema): void => {
    const { name } = values;
    saveCurrentUserPresentation({ name: name.trim() });
  };

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box
        pad={{ vertical: 'small', horizontal: 'medium' }}
        background="background-front"
        justify="between"
        direction="row"
        height="70px"
        align="center"
        wrap={false}
      >
        <Form style={{ width: '100%' }} onSubmit={handleSubmit(handleSave)}>
          <Box direction="row" justify="between">
            <FormField
              width="100%"
              name="name"
              htmlFor="name"
              error={
                !!errors.name
                || isError
              }
            >
              <TextInput
                width="100%"
                id="name"
                maxLength={50}
                size="xsmall"
                {...validations.name}
                defaultValue={userPresentation?.name}
                ref={inputRef}
              />
            </FormField>
            <Box width={{ min: '110px' }} direction="row" align="center">
              {
                isLoading
                  ? (
                    <Box align="center" width="xsmall" justify="center">
                      <Spinner />
                    </Box>
                  )
                  : (
                    <>
                      <Box background="status-ok" margin={{ left: '5px' }} round="small">
                        <Button
                          type="submit"
                          hoverIndicator
                          icon={<FormCheckmark color="background-front" />}
                          title="Save"
                        />
                      </Box>
                      <Box background="dark-2" margin={{ left: '5px' }} round="small">
                        <Button
                          icon={<FormClose color="white" />}
                          title="Cancel"
                          onClick={onCancel}
                        />
                      </Box>
                    </>
                  )
                }
            </Box>
          </Box>
        </Form>
      </Box>
    </ThemeContext.Extend>
  );
};

UserPresentationForm.defaultProps = {
  onCancel: () => undefined,
};

const UserPresentationLabel = (props: IntervalProps): JSX.Element => {
  const { userPresentation, onNew, onEdit } = props;
  const { currentUserPresentation } = storage.localStorage;
  let presentationName = '';
  if (currentUserPresentation === userPresentation?.id) {
    presentationName = userPresentation?.name || 'Untitled Presentation';
  }
  return (
    <Box
      pad={{ vertical: 'small', horizontal: 'medium' }}
      background="background-front"
      justify="between"
      direction="row"
      align="center"
      height="70px"
    >
      <Heading level="5" margin="none" size="small" onClick={onEdit}>
        {presentationName}
      </Heading>
      <Box direction="row" width={{ min: '110px' }} align="center">
        <Box background="status-ok" margin={{ left: '5px' }} round="small">
          <Button
            type="submit"
            onClick={onEdit}
            hoverIndicator
            icon={<Edit color="background-front" />}
            title="Change presentation name"
          />
        </Box>
        <Box background="dark-2" margin={{ left: '5px' }} round="small">
          <Button icon={<Add color="white" />} title="Create a new presentation" onClick={onNew} />
        </Box>
      </Box>
    </Box>
  );
};

export const UserPresentationName = (props: Props): JSX.Element => {
  const { userPresentation, onNewPresentation } = props;
  const [editable, setEditable] = useState(false);

  const onEditHandler = (): void => {
    setEditable(true);
  };

  const onCancelHandler = (): void => {
    setEditable(false);
  };

  return (
    <>
      {
        !userPresentation
        && (
          <Box
            pad={{ vertical: 'small', horizontal: 'medium' }}
            background="background-front"
            justify="between"
            direction="row"
            align="center"
            height="70px"
          />
        )
      }
      {
        !!userPresentation
        && editable
        && (
        <UserPresentationForm
          userPresentation={userPresentation}
          onCancel={onCancelHandler}
        />
        )
      }
      {
        !!userPresentation
        && !editable
        && (
          <UserPresentationLabel
            userPresentation={userPresentation}
            onNew={onNewPresentation}
            onEdit={onEditHandler}
          />
        )
      }
    </>
  );
};

export default UserPresentationName;
