import React from 'react';
import {
  Box,
  Grid,
  Heading,
} from 'grommet';
import range from 'lodash/range';

import { PresentationSkeleton, Skeleton, SlideCardSkeleton } from '../../components';
import { useNumberOfSlides } from '../../app/hooks';

export const PresentationStripSkeleton = (): JSX.Element => {
  const num = useNumberOfSlides();

  return (
    <Box width="100%">
      <Box
        align="stretch"
        justify="stretch"
        direction="row-responsive"
        responsive={false}
        wrap={false}
        background={{ color: 'background-back' }}
        pad="none"
      >
        <Box
          align="start"
          justify="start"
          pad="medium"
          width={{
            min: 'medium',
            max: 'small',
          }}
        >
          <Heading
            level="4"
            size="small"
            margin={{ top: 'none', bottom: 'small', horizontal: 'small' }}
          >
            <Skeleton background="background-front" />
          </Heading>
          <PresentationSkeleton />
        </Box>
        <Box
          align="start"
          justify="center"
          border={{
            side: 'bottom',
            size: 'small',
            style: 'solid',
            color: 'light-1',
          }}
          elevation="medium"
          background={{
            color: 'background-front',
          }}
          direction="column"
          gap="small"
          pad="small"
          width="100%"
        >
          <Heading level="4" size="small" margin={{ top: 'small', bottom: 'small' }}>
            Slides
          </Heading>
          <Grid
            width="100%"
            gap="medium"
            columns={{ count: 'fit', size: 'small' }}
          >
            {
              range(num).map((item) => <SlideCardSkeleton key={item} />)
            }
          </Grid>
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default PresentationStripSkeleton;
