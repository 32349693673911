import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
} from 'grommet';

import { useCategoriesQuery } from '../../features/categories/categoriesApiSlice';
import { Category } from '../../types';
import { RouterPath } from '../../app/router';
import CategorySlideBar from './CategorySlideBar';

interface Props {
  onCloseHandler: (flag: boolean) => void;
  width: string,
  height: string,
}
export const CategoryPanel = (props: Props): JSX.Element | null => {
  const [categoryId, setCategoryId] = useState('');
  const { data, isLoading, isSuccess } = useCategoriesQuery();
  const history = useHistory();
  const { onCloseHandler, width, height } = props;

  const getChildren = (c: Category): Category[] => data?.filter(
    (item: Category) => item.parent?.id === c.id,
  ) as Category[];

  const onClickHandler = (c: Category): void => {
    if (!getChildren(c).length) {
      setCategoryId('');
      onCloseHandler(true);
      history.push(`${RouterPath.ROUTE_DECK}?category=${c.id as string}`);
    } else {
      setCategoryId(c.id as string);
    }
  };

  return (
    <Box>
      {
        categoryId && (
        <CategorySlideBar
          categories={data?.filter(
            (item: Category) => item.parent?.id === categoryId,
          ) as Category[]}
          onClickHandler={onClickHandler}
          onCloseHandler={() => setCategoryId('')}
          width={width}
          height={height}
          hasChildren={(item: Category) => !!getChildren(item).length}
        />
        )
      }
      <CategorySlideBar
        showSkeleton={isLoading}
        categories={data?.filter((item: Category) => !item.parent) as Category[]}
        onClickHandler={onClickHandler}
        onCloseHandler={() => onCloseHandler(false)}
        width={width}
        height={height}
        hasChildren={(item: Category) => !!getChildren(item).length}
      />

    </Box>
  );
};
export default CategoryPanel;
