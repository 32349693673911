import React, { useEffect } from 'react';

import {
  ThemeContext,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Paragraph,
  Anchor,
} from 'grommet';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formTheme } from '../../assets/styles/themes';
import { useVerifyEmailMutation } from '../auth/authApiSlice';
import { useIdentity } from '../../app/hooks';
import { RouterPath } from '../../app/router';
import { Logo } from '../../components';

const VerifyEmailStatus = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { identity } = useIdentity();

  const [verifyEmail, {
    error, isLoading, isError, isSuccess,
  }] = useVerifyEmailMutation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('confirmation');
    verifyEmail(code as string);
  }, [location, verifyEmail]);

  useEffect(() => {
    if (identity?.confirmed) {
      history.replace(RouterPath.ROUTE_HOME);
    } else if (!identity) {
      history.replace(RouterPath.ROUTE_LOGIN);
    }
  }, [identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="large" direction="row-responsive" flex="grow" justify="center">
        <Card
          width="medium"
          pad="small"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="none" direction="column" align="center" alignContent="center">
            <Heading level={4} textAlign="center" alignSelf="center">
              { isSuccess && 'Verify your email address' }
              { isError && 'Verification failed' }
            </Heading>
            <Heading level={3} margin={{ top: 'medium' }} alignSelf="center">
              { isSuccess && 'Success!' }
              { isError && 'Contact Us' }
            </Heading>
          </CardHeader>
          <CardBody>
            <Box
              align="center"
              justify="center"
              pad="medium"
            >
              <Logo />
            </Box>
            {
              isSuccess && (
                <Paragraph margin={{ vertical: 'small' }} textAlign="center">
                  We have now verified your email address
                </Paragraph>
              )
            }
            {
              isError && (
                <Paragraph margin={{ vertical: 'small' }} textAlign="center">
                  We may be able to help
                  <br />
                  <Anchor weight="bold" href="mailto:support@upplo.co.uk">
                    support@upplo.co.uk
                  </Anchor>
                </Paragraph>
              )
            }
          </CardBody>
          <CardFooter
            margin={{ bottom: 'large' }}
            justify="center"
            direction="column"
            align="center"
          >
            <Box align="center" justify="center" pad={{ top: 'medium' }}>
              {
                isSuccess
                && (
                  <Button
                    type="button"
                    size="medium"
                    alignSelf="center"
                    primary
                    label="Sign In"
                    onClick={() => { history.replace(RouterPath.ROUTE_LOGIN); }}
                  />
                )
              }
            </Box>
          </CardFooter>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default VerifyEmailStatus;
