import React, { useEffect } from 'react';
import {
  ThemeContext,
  Text,
  Box,
  Button,
  Heading,
  Paragraph,
  Image,
} from 'grommet';

import { useHistory, useLocation } from 'react-router-dom';
import { useFetchIdentityInterval } from '../../app/hooks';

import { RouterPath } from '../../app/router';

import { formTheme } from '../../assets/styles/themes';

import premiumImg from '../../assets/images/premium.png';

const SubscriptionSuccess = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { identity } = useFetchIdentityInterval(10000);

  useEffect(() => {
    const subs = identity?.subscription;
    if (subs?.type === 'Paid') {
      const query = new URLSearchParams(location.search);
      const path = query.get('callback') || RouterPath.ROUTE_HOME;
      history.replace(path);
    }
  }, [identity, history]);

  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="large">
        <Box align="center">
          <Heading level="3">Please Wait...</Heading>
        </Box>
        <Box justify="center" align="center" direction="row-responsive" gap="large">
          <Box
            align="stretch"
            justify="between"
            background={{
              color: 'background',
            }}
            style={{
              position: 'relative',
            }}
            pad={{
              horizontal: 'medium',
              vertical: 'large',
            }}
            elevation="large"
            round="medium"
            responsive={false}
            wrap={false}
          >
            <Box
              tabIndex={-10}
              style={{
                position: 'absolute',
                left: '-20px',
                top: '18px',
                width: '20px',
                background: '#E19D00',
                borderRadius: '50px 0px 0',
              }}
            >&nbsp;
            </Box>
            <Box
              align="stretch"
              justify="start"
              background={{
                color: 'accent-4',
              }}
              style={{
                position: 'absolute',
                left: '-20px',
                top: '30px',
                borderRadius: '0 50px 50px 50px',
              }}
              direction="column"
              pad={{ left: '50px', right: 'medium', vertical: 'xsmall' }}
              gap="none"
            >

              <Paragraph margin="none" color="white">
                Recommended
              </Paragraph>
            </Box>
            <Box
              align="stretch"
              justify="between"
              direction="row"
              style={{ position: 'relative', borderBottom: '1px solid #D9D9D9' }}
            >
              <Box align="start" margin={{ top: 'medium' }} justify="center">
                <Paragraph>
                  <Text weight={600} color="dark">Upplo </Text>
                  <Text weight={600} color="status-warning">Premium</Text>
                </Paragraph>
                <Heading
                  style={{ fontWeight: 'bold' }}
                  size="medium"
                  margin={{ bottom: 'medium', top: 'none' }}
                  color="black"
                  level="1"
                  truncate={false}
                >
                  £10 <Text weight={500} size="small">/month, billed annually</Text>
                </Heading>
              </Box>
              <Box align="center" justify="center" width="xsmall">
                <Image src={premiumImg} fit="contain" fill="horizontal" />
              </Box>
            </Box>
            <Box align="stretch" justify="between">
              <Paragraph color="dark-5">
                Over 100+ presentations ready to use Updated
                content from experienced educators Better productivity – save time planning
              </Paragraph>
              <Button
                disabled
                alignSelf="end"
                label="Select"
                primary
                reverse
                secondary={false}
                size="medium"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};
export default SubscriptionSuccess;
