import React, { useEffect } from 'react';
import {
  Anchor,
  Box,
  Button,
  Heading,
  Paragraph,
} from 'grommet';
import { format } from 'date-fns';
import {
  FaTrash, FaPencilAlt, FaEye, FaDownload,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import {
  ROUTE_DECK,
  ROUTE_USER_PRESENTATION_DOWNLOAD,
} from '../../../app/router/route-path.constants';
import {
  Identifier, Slide, UserPresentation, UserSlide,
} from '../../../types';
import { StyledImage } from '../../../components';
import {
  useDeleteUserPresentationMutation,
  useLazyFetchUserPresentationSlidesQuery,
} from '../userPresentationsApiSlice';
import { setImages } from '../../imagePreview/imagePreviewSlice';
import { useAppDispatch, useCurrentUserPresentation, useAppSelector } from '../../../app/hooks';
import { ConfirmationValueType } from './DeleteConfirmation';

import { getMedia } from '../../../utils';
import storage from '../../../storage';

interface Props {
  userPresentation: UserPresentation;
  onDownload: () => void;
  onDelete: (p: UserPresentation) => void;
  onDeleteConfirmation: (callback: (t: ConfirmationValueType) => void) => void;
}

export const UserPresentationCard = (props: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { currentUserPresentation } = storage.localStorage;
  const { removeCurrentUserPresentationLocal } = useCurrentUserPresentation();
  const { show: isPreviewVisible } = useAppSelector((state) => state.imagePreview);
  const {
    userPresentation, onDelete, onDeleteConfirmation, onDownload,
  } = props;
  const [deleteUserPresentation, {
    isSuccess: deleteSuccess,
    isLoading: deleteLoading,
    isError: deleteError,
  }] = useDeleteUserPresentationMutation();
  const [fetchUserPresentationSlides, {
    data: slides, isFetching,
  }] = useLazyFetchUserPresentationSlidesQuery();
  const history = useHistory();
  const { saveCurrentUserPresentationLocal } = useCurrentUserPresentation();

  useEffect(() => {
    if (slides && !isFetching && isPreviewVisible) {
      const userSlides = [...slides.results] as UserSlide[];
      const images = userSlides.sort((a, b) => a.displayOrder - b.displayOrder).map(({ slide }) => {
        const { id, url } = (slide as Slide).image;
        return { id: id as string, url };
      });
      dispatch(setImages({ images, index: 0, show: true }));
    }
  }, [slides, isFetching]);

  useEffect(() => {
    if (deleteSuccess) {
      onDelete(userPresentation);
      if (currentUserPresentation && currentUserPresentation === userPresentation.id) {
        removeCurrentUserPresentationLocal();
      }
    }
  }, [deleteSuccess]);

  const onPreviewHandler = (up: UserPresentation):void => {
    dispatch(setImages({ images: [], index: 0, show: true }));
    fetchUserPresentationSlides({
      page: 1,
      pageSize: 1000,
      filters: {
        id: up.id as string,
      },
    });
  };

  const onEditHandler = (): void => {
    saveCurrentUserPresentationLocal(userPresentation.id as Identifier);
    history.push(ROUTE_DECK);
  };

  const onDeleteHandler = (): void => {
    onDeleteConfirmation((value): void => {
      if (value === 'yes') {
        deleteUserPresentation(userPresentation);
      }
    });
  };

  return (
    <Box
      margin={{
        bottom: 'small',
      }}
      width="100%"
      align="stretch"
      justify="center"
      style={{
        position: 'relative',
      }}
      elevation="none"
    >
      <Box round="small" style={{ border: '2px solid #e4e9ed' }} overflow="hidden" gap="none" pad="none">
        <StyledImage
          image={{
            blurHash: userPresentation.blurHash,
            ...getMedia(userPresentation.image),
          }}
        />
      </Box>
      <Box align="start" justify="between" direction="row" width="100%">
        <Box align="start" pad={{ vertical: 'small' }} justify="start" direction="column">
          <Heading level="5" color="black" margin="none">
            {userPresentation.name}
          </Heading>
          <Paragraph size="small" fill={false} margin="none">
            {format(new Date((userPresentation as any).updatedAt), 'dd-MM-yyyy')}
          </Paragraph>
        </Box>
        <Box
          width={{ min: '130px' }}
          align="center"
          justify="center"
          direction="row"
          pad={{ vertical: 'small' }}
        >
          <Button
            icon={<FaPencilAlt />}
            color="dark-1"
            primary
            onClick={onEditHandler}
          />
          <Anchor
            target="_blank"
            href={
              ROUTE_USER_PRESENTATION_DOWNLOAD.replace(':presentationId', userPresentation.id as string)
            }
            onClick={() => onDownload()}
            style={{ outline: 'none', boxShadow: 'none' }}
          >
            <Button
              margin={{ left: '5px' }}
              icon={<FaDownload color="white" />}
              color="dark-1"
              primary
            />
          </Anchor>
          <Button
            margin={{ left: '5px' }}
            disabled={!userPresentation.image}
            icon={<FaEye color="white" />}
            color="status-ok"
            primary
            onClick={() => onPreviewHandler(userPresentation)}
          />

          <Box
            background="status-critical"
            round="large"
            style={{
              right: '10px',
              top: '10px',
              position: 'absolute',
            }}
          >
            <Button
              style={{
                padding: '5px',
              }}
              icon={<FaTrash color="white" />}
              plain
              disabled={!deleteError && (deleteLoading || deleteSuccess)}
              onClick={() => onDeleteHandler()}
            />
          </Box>
        </Box>
      </Box>
    </Box>

  );
};

export default UserPresentationCard;
