import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Carousel,
  Image,
  Layer,
  Spinner,
} from 'grommet';

import styled from 'styled-components';
import { HiArrowRight, HiArrowLeft } from 'react-icons/hi';
import { Close } from 'grommet-icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setImages } from './imagePreviewSlice';

import CardImg from '../../assets/images/default-image.png';

export const SliderNavWrap = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;
export const SliderNav = styled(Button)`
  position: absolute;
  border-radius: 100px;
  background: #333333;
  color: #fff;
`;
export const ImagePreview = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { index, images, show } = useAppSelector((state) => state.imagePreview);
  const [image, setImage] = useState({ index: -1, url: '' });

  const width = '90vw';
  const height = '90vh';

  const removePreview = (): void => {
    dispatch(setImages(null));
  };

  const onSlideHandler = (id: 'NEXT' | 'PREV'): void => {
    let slideIndex: number = image.index;
    if (id === 'NEXT') slideIndex++;
    else slideIndex--;
    slideIndex = Math.min(slideIndex, images.length - 1);
    slideIndex = Math.max(slideIndex, 0);
    // setActiveSlide(slideIndex);
    const img = images[slideIndex];
    setImage({ index: slideIndex, ...img });
  };

  useEffect(() => {
    if (images.length) {
      setImage({ index, ...images[index] });
    }
  }, [images, index]);

  if (!show) {
    return null;
  }

  return (
    <Box style={{ position: 'absolute' }}>
      <Layer
        responsive={false}
        animation="none"
        background={{ color: 'light', opacity: 'strong' }}
        onClickOutside={() => removePreview()}
        onEsc={() => removePreview()}
        onAbort={removePreview}
      >
        <SliderNavWrap
          direction="row"
          gap="small"
          align="center"
        >
          <SliderNav
            style={{ left: '10px' }}
            disabled={images.length === 0 || image.index === 0}
            onClick={() => onSlideHandler('PREV')}
            icon={<HiArrowLeft size="20px" />}
          />
          <SliderNav
            style={{ right: '10px' }}
            disabled={images.length === 0 || image.index === (images.length - 1)}
            onClick={() => onSlideHandler('NEXT')}
            icon={<HiArrowRight size="20px" />}
          />
        </SliderNavWrap>
        <Box
          direction="row"
          alignContent="center"
          alignSelf="center"
        >

          <Box
            overflow="hidden"
            width={width}
            height={height}
            background="black"
          >
            <Box style={{ position: 'absolute', top: '45%', left: '50%' }}>
              <Spinner align="center" size="large" />
            </Box>

            <Carousel
              controls={false}
              initialChild={index}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              activeChild={image.index}
              fill
            >

              {images.map((item, i) => (
                <Box
                  key={item.id}
                  width={width}
                  height={height}
                  align="center"
                >
                  {
                    image.index === i && <Image fallback={CardImg} fit="contain" src={image.url || CardImg} fill="horizontal" />
                  }
                </Box>
              ))}
            </Carousel>
          </Box>

          <Box
            style={{
              zIndex: 1, position: 'absolute', right: '10px', top: '10px',
            }}
          >
            <Button
              primary
              color="dark-1"
              onClick={() => removePreview()}
              style={{
                borderRadius: '50%',
              }}
              icon={<Close size="15px" />}
            />
          </Box>

        </Box>
      </Layer>
    </Box>

  );
};

export default ImagePreview;
