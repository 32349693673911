import React from 'react';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import { Image } from 'grommet';

import LogoIcon from '../../assets/images/mobile-logo.svg';
import logo from '../../assets/images/logo.svg';
import { RouterPath } from '../../app/router';

// const LogoWrapper = styled.img`
//   height: 40vmin;
//   pointer-events: none;

//   @media (prefers-reduced-motion: no-preference) {
//     animation: App-logo-spin infinite 20s linear;
//   }
// `;

// const Logo = (): React.ReactElement => (<LogoWrapper src={logo} alt="logo" />);

// export default Logo;

interface Props {
  width: number
}

export const MobileLogo = (props: Props): JSX.Element => {
  const { width } = props;
  return (
    <Link to={RouterPath.ROUTE_HOME}>
      <Image width={width} src={LogoIcon} alt="logo" />
    </Link>
  );
};

export const DesktopLogo = (props: Props): JSX.Element => {
  const { width } = props;
  return (
    <Link to={RouterPath.ROUTE_HOME}>
      <Image width={width} src={logo} alt="logo" />
    </Link>
  );
};

const Logo = (): React.ReactElement => (<Image src={logo} alt="logo" />);

export default Logo;
