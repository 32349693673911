import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Identifier, Presentation, Slide, UserPresentation, UserSlide,
} from '../../types';

export type UserPresentationsState = {
  presentation: UserPresentation | null;
  slides: UserSlide[];
  added: boolean;
  state: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: any;
  };
};

const initialState: UserPresentationsState = {
  presentation: null,
  slides: [],
  added: false,
  state: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const userPresentationsSlice = createSlice({
  name: 'userPresentations',
  initialState,
  reducers: {
    resetUserPresentation: () => initialState,
    setUserPresentation(state, presentation: PayloadAction<UserPresentation| undefined>) {
      if (presentation.payload) {
        state.presentation = presentation.payload;
      }
    },

    setUserSlides(state, slides: PayloadAction<UserSlide[]>) {
      const arr = slides.payload?.slice() || [];
      state.slides = arr.sort((a, b) => a.displayOrder - b.displayOrder);
      state.added = false;
    },

    addUserSlides(state, slides: PayloadAction<UserSlide[]>) {
      const source = slides.payload.reduce((prev: any, current) => {
        prev[current.tmpId as any] = current;

        return prev;
      }, {});

      const dest = state.slides.reduce((prev: any, current) => {
        prev[current.tmpId as any] = current;

        return prev;
      }, {});

      const arr = [
        ...Object.values<UserSlide>(dest),
        ...Object.values<UserSlide>(source),
      ];

      state.slides = arr.sort((a, b) => a.displayOrder - b.displayOrder);
      state.added = true;
    },

    setUserSlideState(state, payload: PayloadAction<{
        isLoading: boolean;
        isSuccess: boolean;
        isError: boolean;
        error?: any;
      }>) {
      state.state = payload.payload;
    },

    updateUserSlides(state, slides: PayloadAction<UserSlide[]>) {
      state.slides = state.slides.map((userSlide) => {
        const item = slides.payload.find((us) => us.tmpId && us.tmpId === userSlide.tmpId);

        return item || userSlide;
      }).sort((a, b) => a.displayOrder - b.displayOrder);
    },

    removeUserSlide(state, slide: PayloadAction<UserSlide>) {
      state.slides = state.slides.filter((item) => item.tmpId !== slide.payload.tmpId);
      state.added = false;
    },

    clearUserPresentation(state) {
      state.presentation = null;
      state.slides = [];
      state.added = false;
    },
  },
});

export const {
  resetUserPresentation,
  setUserPresentation,
  setUserSlides,
  addUserSlides,
  setUserSlideState,
  updateUserSlides,
  removeUserSlide,
  clearUserPresentation,
} = userPresentationsSlice.actions;

export default userPresentationsSlice.reducer;
