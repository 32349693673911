import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Heading,
  Button,
} from 'grommet';

import {
  AddCircle,
} from 'grommet-icons';
import range from 'lodash/range';

import { Category, Presentation, Slide } from '../../types';
import { PresentationCard, SlideCardSkeleton } from '../../components';
import { useNumberOfSlides } from '../../app/hooks';
import { useLazyFetchSlidesQuery } from '../slides/slidesApiSlice';
import EmptyPresentation from './EmptyPresentation';
import SlidesLoadingError from './SlidesLoadingError';
import SlideCarousel from './SlideCarousel';

type Props = {
  presentation: Presentation;
  categories: Category[];
  onViewAll: (t: Slide[]) => void;
}

export const PresentationStrip = (props: Props): JSX.Element => {
  const { categories, presentation, onViewAll } = props;

  const [fetchSlides, {
    isLoading,
    isSuccess,
    isError,
    data,
  }] = useLazyFetchSlidesQuery();
  const [page, setPage] = useState(1);
  const num = useNumberOfSlides();

  const parentCategory = categories.find((c) => c.id === presentation.category?.parent);

  useEffect(() => {
    fetchSlides({
      page,
      pageSize: 1000,
      filters: {
        presentation: presentation.id as string,
      },
      sort: [
        { field: 'displayOrder', order: 'ASC' },
      ],
    });
  }, [page, fetchSlides, presentation]);

  const onRefreshHandler = (): void => {
    fetchSlides({
      page,
      pageSize: 1000,
      filters: {
        presentation: presentation.id as string,
      },
    });
  };

  // console.log('render PresentationStrip');

  return (
    <Box width="100%">
      <Box
        justify="stretch"
        direction="row-responsive"
        responsive={false}
        wrap={false}
        background={{ color: 'light-2' }}
        pad="small"
        round="small"
        margin={{ bottom: '1rem' }}
        align="stretch"
      >
        <Box
          alignSelf="center"
          align="stretch"
          justify="start"
          pad="small"
          width={{
            min: '350px',
            max: 'medium',
          }}
        >
          <Heading
            level="5"
            size="xsmall"
            margin={{ top: 'none', bottom: 'small', horizontal: 'small' }}
          >
            {/* { presentation.category?.parent?.name || '' }
            {'>'} */}
            {presentation.category?.name || ''}
          </Heading>
          <PresentationCard item={presentation} category={parentCategory} allowNavigation={false} />
        </Box>
        <Box
          align="start"
          justify="center"
          background={{
            color: 'background-front',
          }}
          round="small"
          direction="column"
          gap="small"
          pad="medium"
          width="100%"
        >
          <Box
            justify="between"
            align="stretch"
            direction="row"
            width="100%"
            pad={{ top: 'none', bottom: 'none' }}
          >
            <Heading
              level="4"
              size="small"
              color="black"
              margin={{ top: 'small', bottom: 'small', horizontal: 'small' }}
            >
              Slides
            </Heading>
            {
              !!data?.results.length
              && (
                <Button
                  label="View All"
                  plain
                  primary={false}
                  onClick={() => onViewAll(data.results)}
                />
              )
            }
          </Box>
          <Grid
            width="100%"
            gap="none"
            margin="none"
            pad="none"
            columns={{ count: 'fit', size: 'small' }}
          >
            {
              isLoading
              && range(num).map((item) => <SlideCardSkeleton key={item} />)
            }
            {
              !isLoading
              && isSuccess
              && !!data?.results.length
              && <SlideCarousel slides={data.results} isPaid={presentation.paid} />
            }
            {
              !isLoading
              && isSuccess
              && !data?.results.length
              && <EmptyPresentation onClick={onRefreshHandler} />
            }
            {
              !isLoading
              && isError
              && <SlidesLoadingError onClick={onRefreshHandler} />
            }
          </Grid>
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default PresentationStrip;
