import React, { useState } from 'react';
import {
  Box,
  Text,
} from 'grommet';

import { FormNext } from 'grommet-icons';
import { Category } from '../../types';

interface Props {
  categories: Category[];
  onMouseEnterHandler?:(c: Category) => void;
  onClickHandler: (c: Category) => void;
  hasChildren: (c: Category) => boolean;
}
const CategoryList = (props: Props): React.ReactElement | null => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const {
    categories, onMouseEnterHandler, hasChildren, onClickHandler,
  } = props;
  if (!categories?.length) {
    return null;
  }
  return (
    <Box direction="column" width="100%" style={{ minWidth: '200px' }}>
      {
        categories.map((item: Category) => (
          <Box
            onClick={() => { onClickHandler(item); }}
            onMouseEnter={() => {
              setSelectedCategoryId(item.id as string);
              onMouseEnterHandler?.(item);
            }}
            key={item.id}
            direction="row"
            width="100%"
            alignContent="between"
            align="baseline"
            pad="small"
            gap="medium"
            focusIndicator={false}
          >
            <Box flex="grow">
              <Text
                size="xsmall"
                color={item.id === selectedCategoryId ? 'brand' : ''}
              >{item.name}
              </Text>
            </Box>
            <Box>
              {hasChildren(item)
                ? (
                  <FormNext
                    size="small"
                    color={item.id === selectedCategoryId ? 'brand' : ''}
                  />
                ) : <Text key={item.id} />}

            </Box>
          </Box>
        ))
      }
    </Box>
  );
};
CategoryList.defaultProps = {
  onMouseEnterHandler: null,
};
export default CategoryList;
