import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const prepareHeaders = (headers: Headers): Headers => {
  headers.set('x-app-id', process.env.REACT_APP_API_KEY as string);
  headers.set('x-app-secret', process.env.REACT_APP_API_SECRET as string);

  return headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders,
});

export const jwtBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders(headers, { getState }) {
    const state = getState() as any;
    const { auth: { token } } = state;

    const h = prepareHeaders(headers);

    if (token) {
      h.set('Authorization', `Bearer ${token}`);
    }

    return h;
  },
});

// eslint-disable-next-line max-len
const googleSSOQueryString = `app-id=${process.env.REACT_APP_GOOGLE_API_KEY}&app-secret=${process.env.REACT_APP_GOOGLE_API_SECRET}`;
export const googleSSO = {
  url: `${process.env.REACT_APP_API_BASE_URL}/connect/google?${googleSSOQueryString}`,
  queryString: `&${googleSSOQueryString}`,
};
