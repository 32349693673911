import React, {
  useState, useEffect,
} from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import {
  Box,
  TextInput,
} from 'grommet';
import {
  FaSearch,
} from 'react-icons/fa';
import styled from 'styled-components';
import qs from 'qs';

import { useAppDispatch, useAppSelector, useFocus } from '../../app/hooks';
import { RouterPath } from '../../app/router';

interface Props {
  height?: string;
  borderRadius: string;
}

const StyledTextInput = styled(TextInput)`
  background: #f5f5f5;
  border-color: transparent;
  border-radius: ${(props: Props) => props.borderRadius};
  height: ${(props: Props) => props.height};
  ::placeholder{
    font-size: 12px;
  }
`;

export const SearchBox = (props: Props): JSX.Element => {
  const { height, borderRadius } = props;
  // const ref = useRef<any>(0);
  const [value, setValue] = useState('');
  // const dispatch = useAppDispatch();
  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [inputRef, setInputFocus] = useFocus();
  // const query = useAppSelector((state) => state.searchQuery.query);
  const { focus, q } = qs.parse(location.search.replace('?', ''));
  const query = q as string;

  useEffect(() => {
    if (focus === 'search-box') {
      setInputFocus();
    }
  }, [focus, setInputFocus]);

  useEffect(() => {
    const handler = setTimeout(() => {
      // dispatch(search(value));
      if (value) {
        history.push(`${RouterPath.ROUTE_DECK}?q=${value}`);
      } else if ((!location.search || q) && location.pathname === RouterPath.ROUTE_DECK) {
        history.push(RouterPath.ROUTE_DECK);
      }
    }, 700);

    return () => clearTimeout(handler);
  }, [value]);

  useEffect(() => {
    if (!query?.trim()) {
      setValue('');
    }
  }, [query]);

  useEffect(() => {
    setValue(query);
  }, []);

  // useEffect(() => {
  //   setValue(query);
  // }, [query]);

  const onChangeHandler = (e: any): void => {
    setValue(e.target.value);
  };

  const onClickHandler = (): void => {
    if (route.path !== RouterPath.ROUTE_DECK) {
      history.push(`${RouterPath.ROUTE_DECK}?focus=search-box`);
    }
  };

  console.log('search box');

  return (
    <StyledTextInput
      maxLength={120}
      ref={inputRef}
      value={value}
      placeholder="Search for keyword, examination course or content type"
      reverse={false}
      plain={false}
      height={height}
      borderRadius={borderRadius}
      icon={<Box margin={{ left: 'small' }}><FaSearch /></Box>}
      onChange={onChangeHandler}
      onClick={onClickHandler}
    />
  );
};

SearchBox.defaultProps = {
  height: '',
};

export default SearchBox;
