import {
  ThemeType,
} from 'grommet';

export const formTheme: ThemeType = {
  global: {
    size: {
      medium: '480px',
    },
    focus: {
      border: {
        color: 'brand',
      },
    },
  },
  formField: {
    label: {
      size: 'small',
      margin: {
        start: '0',
      },
    },
    error: {
      margin: {
        start: '0',
      },
    },
  },
  button: {
    size: {
      medium: {
        border: {
          radius: '24px',
        },
        pad: {
          vertical: '8px',
        },
      },
    },
  },
};
