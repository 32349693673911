import React from 'react';
import {
  Box,
  Button,
  Nav,
  Text,
} from 'grommet';

import {
  FormNext,
  Previous,
} from 'grommet-icons';
import { Category } from '../../types';
import NavButton from './NavButtons';
import AppSlidebar from './AppSlidebar';
import Skeleton from '../Skeleton';

interface Props {
  categories: Category[];
  showSkeleton?: boolean;
  onClickHandler: (c: Category) => void;
  onCloseHandler: () => void;
  hasChildren: (c: Category) => boolean;
  width: string,
  height: string,
}
export const CategoryLayer = (props: Props): JSX.Element | null => {
  const {
    categories,
    showSkeleton,
    hasChildren,
    onClickHandler,
    onCloseHandler,
    width,
    height,
  } = props;

  return (
    <AppSlidebar
      onCloseHandler={onCloseHandler}
      width={width}
      height={height}
      header={(
        <Box
          direction="row"
          onClick={onCloseHandler}
          background="light-2"
          pad="medium"
          style={{ boxShadow: 'none' }}
        >
          <Button plain gap="xxsmall" icon={<Previous />} label="Back" />
        </Box>
        )}
      body={showSkeleton ? (
        <Nav gap="medium" pad="small">
          <Skeleton height="23px" />
          <Skeleton height="23px" />
          <Skeleton height="23px" />
          <Skeleton height="23px" />
          <Skeleton height="23px" />
        </Nav>
      ) : (
        <Nav gap="small" pad="small">
          <Box overflow="auto" height={height}>
            <Nav gap="small" pad="small">
              {
                    categories?.map((item: Category) => (
                      <NavButton
                        key={item.id}
                        onClick={() => onClickHandler(item)}
                        body={(
                          <>
                            <Box flex="grow" style={{ borderBottom: '1px solid #ccc' }} pad={{ top: 'none', bottom: 'medium' }} width="70%">
                              <Text size="small">{item.name}</Text>
                            </Box>
                            <Box style={{ borderBottom: '1px solid #ccc' }}>
                              {hasChildren(item) ? <FormNext /> : undefined}
                            </Box>
                          </>
                        )}
                      />
                    ))
                  }
            </Nav>
          </Box>
        </Nav>
      )}
    />
  );
};
CategoryLayer.defaultProps = {
  showSkeleton: false,
};
export default CategoryLayer;
