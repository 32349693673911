import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Text,
  Box,
  Button,
  Heading,
  Paragraph,
  Image,
  Spinner,
} from 'grommet';

import premiumImg from '../../assets/images/premium.png';

import {
  useSessionsMutation,
} from '../paymentGateway/paymentGatewayApiSlice';
import { ErrorMessage } from '../../components';
import { RouterPath } from '../../app/router';

interface Props {
  onSelect: () => void;
  disabled?: boolean;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
const PaidSubscription = (props: Props): React.ReactElement => {
  const [isPlanYearly, setPlanYearly] = useState(true);
  const [stripeCalling, setStripeCalling] = useState(false);
  const [session, {
    data, error, isSuccess, isLoading,
  }] = useSessionsMutation();
  const { onSelect, disabled } = props;
  const onPaidSelect = (): void => {
    onSelect();

    session({
      successUrl:
        `${RouterPath.ROUTE_SUBSCRIPTION_SUCCESS}?callback=${encodeURI(RouterPath.ROUTE_HOME)}`,
      cancelUrl: RouterPath.ROUTE_SUBSCRIPTION,
      isYearly: isPlanYearly,
    });
    setStripeCalling(true);
  };

  useEffect(() => {
    if (!error && isSuccess && data) {
      const { id: sessionId } = data as any;
      (async () => {
        const stripe = await stripePromise;
        await stripe?.redirectToCheckout({
          sessionId,
        });
        setStripeCalling(false);
      })();
    }
  }, [data, error, isSuccess]);

  return (
    <Box
      align="stretch"
      justify="between"
      background={{
        color: 'background-front',
      }}
      style={{
        position: 'relative',
      }}
      pad={{
        horizontal: 'medium',
        vertical: 'large',
      }}
      elevation="large"
      round="medium"
      responsive={false}
      wrap={false}
    >
      <Box
        tabIndex={-10}
        background="#E19D00"
        style={{
          position: 'absolute',
          left: '-20px',
          top: '18px',
          width: '20px',
          borderRadius: '50px 0px 0',
        }}
      >&nbsp;
      </Box>
      <Box
        align="stretch"
        justify="start"
        background={{
          color: 'accent-4',
        }}
        style={{
          position: 'absolute',
          left: '-20px',
          top: '30px',
          borderRadius: '0 50px 50px 50px',
        }}
        direction="column"
        pad={{ left: '50px', right: 'medium', vertical: 'xsmall' }}
        gap="none"
      >

        <Paragraph margin="none" color="white">
          Recommended
        </Paragraph>
      </Box>
      <Box
        align="stretch"
        justify="between"
        direction="row"
        style={{ position: 'relative' }}
      >
        <Box align="start" margin={{ top: 'medium' }} justify="center">
          <Paragraph>
            <Text weight={600} color="dark">Upplo</Text>
            <Text weight={600} color="status-warning">Premium</Text>
          </Paragraph>
          <Heading
            style={{ fontWeight: 'bold' }}
            size="medium"
            margin={{ bottom: 'medium', top: 'none' }}
            color="black"
            level="1"
            truncate={false}
          >
            { isPlanYearly ? '£99' : '£10'}
            <Text weight={500} size="small">/{ isPlanYearly ? 'year' : 'month'}</Text>
          </Heading>
        </Box>
        <Box align="center" justify="center" width="xsmall">
          <Image src={premiumImg} fit="contain" fill="horizontal" />
        </Box>
      </Box>
      <Box direction="row" gap="small" align="stretch" margin={{ bottom: 'medium' }}>
        <Button
          onClick={() => setPlanYearly(false)}
          primary={!isPlanYearly}
          secondary={isPlanYearly}
          label="Monthly"
          fill="horizontal"
        />
        <Button
          onClick={() => setPlanYearly(true)}
          primary={isPlanYearly}
          secondary={!isPlanYearly}
          label="Annually"
          fill="horizontal"
        />
      </Box>
      <Box align="stretch" justify="between" style={{ borderTop: '1px solid #D9D9D9' }}>
        <Paragraph color="dark-5">
          Over 100+ presentations ready to use. Updated
          content from experienced educators. Better productivity – save time planning
        </Paragraph>
        <Button
          onClick={onPaidSelect}
          disabled={disabled || stripeCalling || isLoading}
          alignSelf="end"
          label={<Text color="white">Continue</Text>}
          primary
          reverse
          secondary={false}
          size="medium"
          icon={
            stripeCalling || isLoading
              ? <Spinner size="xsmall" /> : undefined
          }
        />
      </Box>
      {error && <ErrorMessage error={error} />}
    </Box>

  );
};

PaidSubscription.defaultProps = {
  disabled: false,
};

export default PaidSubscription;
