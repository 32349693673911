import React from 'react';
import {
  Box,
} from 'grommet';

import { Skeleton } from '../../../components';

export const UserPresentationCardSkeleton = (): JSX.Element => (
  <Box margin={{ bottom: 'small' }} align="stretch" justify="center" elevation="none" background="white" pad="small">
    <Box round="small" overflow="hidden" gap="none" pad="none">
      <Skeleton width="100%" height="150px" />
    </Box>
    <Box align="start" justify="between" direction="row" width="100%">
      <Box align="start" gap="8px" pad={{ vertical: 'small' }} margin="xsmall" justify="start" direction="column">
        <Skeleton width="200px" height="10px" />
        <Skeleton width="200px" height="10px" />
      </Box>
      <Box
        align="center"
        justify="center"
        direction="row"
        pad={{ vertical: 'small' }}
        gap="xsmall"
      >
        <Skeleton width="80px" height="40px" />
        <Skeleton width="80px" height="40px" />
        <Skeleton width="80px" height="40px" />
      </Box>
    </Box>
  </Box>
);
export default UserPresentationCardSkeleton;
