import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';
import { QueryParams, PaginatedResult, Presentation } from '../../types';
import { buildSearchQuery, getDefaultSearchQuery } from '../../utils';

export const presentationsApiSlice = createApi({
  reducerPath: 'presentationsApi',
  baseQuery,
  endpoints(builder) {
    return {
      searchPresentations: builder.query<PaginatedResult<Presentation>, QueryParams>({
        query(query = getDefaultSearchQuery()) {
          return {
            url: `presentations${buildSearchQuery(query)}`,
          };
        },
      }),
      fetchFeaturedPresentations: builder.query<Presentation[], void>({
        query() {
          return {
            url: 'presentations/featured',
          };
        },
      }),
    };
  },
});

export const {
  useSearchPresentationsQuery,
  useLazySearchPresentationsQuery,
  useFetchFeaturedPresentationsQuery,
  useLazyFetchFeaturedPresentationsQuery,
} = presentationsApiSlice;
