import React from 'react';
import {
  Box,
  Heading,
} from 'grommet';

export const EmptyPresentation = (): JSX.Element => (

  <Box
    gap="small"
    pad="small"
    width="100%"
    height="100%"
    align="stretch"
  >
    <Box
      height="100%"
      align="center"
      justify="center"
      width="100%"
      pad="medium"
    >
      <Box height="100%" style={{ boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)' }} background="background-front" width="100%" round="small" align="center" justify="center" pad="large">
        <Heading level="4" textAlign="center" style={{ maxWidth: '100%' }}>
          Choose category or type keywords in search box.
        </Heading>
      </Box>
    </Box>
  </Box>
);

export default EmptyPresentation;
