import { configureStore } from '@reduxjs/toolkit';

import counterReducer from '../features/counter/counterSlice';
import searchQueryReducer from '../features/searchQuery/searchQuerySlice';

import { dogApiSlice } from '../features/dogs/dogs-api-slice';
import { authApiSlice } from '../features/auth/authApiSlice';
import { authSlice } from '../features/auth/authSlice';
import { onboardingSlice } from '../features/onboarding/onboardingSlice';
import { onboardingApiSlice } from '../features/onboarding/onboardingApiSlice';
import { categoriesApiSlice } from '../features/categories/categoriesApiSlice';
import { presentationsApiSlice } from '../features/presentations/presentationsApiSlice';
import { slidesApiSlice } from '../features/slides/slidesApiSlice';
import { paymentGatewayApiSlice } from '../features/paymentGateway/paymentGatewayApiSlice';
import { usersApiSlice } from '../features/users/usersApiSlice';
import { userPresentationsSlice } from '../features/userPresentations/userPresentationsSlice';
import { imagePreviewSlice } from '../features/imagePreview/imagePreviewSlice';
import { userPresentationsApiSlice } from '../features/userPresentations/userPresentationsApiSlice';
import { userSlidesApiSlice } from '../features/userSlides/userSlidesApiSlice';
import { errorInterceptor } from './errorInterceptor';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authSlice.reducer,
    onboarding: onboardingSlice.reducer,
    searchQuery: searchQueryReducer,
    userPresentations: userPresentationsSlice.reducer,
    imagePreview: imagePreviewSlice.reducer,
    [dogApiSlice.reducerPath]: dogApiSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [onboardingApiSlice.reducerPath]: onboardingApiSlice.reducer,
    [categoriesApiSlice.reducerPath]: categoriesApiSlice.reducer,
    [presentationsApiSlice.reducerPath]: presentationsApiSlice.reducer,
    [slidesApiSlice.reducerPath]: slidesApiSlice.reducer,
    [paymentGatewayApiSlice.reducerPath]: paymentGatewayApiSlice.reducer,
    [usersApiSlice.reducerPath]: usersApiSlice.reducer,
    [userPresentationsApiSlice.reducerPath]: userPresentationsApiSlice.reducer,
    [userSlidesApiSlice.reducerPath]: userSlidesApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    dogApiSlice.middleware,
    authApiSlice.middleware,
    authApiSlice.middleware,
    categoriesApiSlice.middleware,
    presentationsApiSlice.middleware,
    slidesApiSlice.middleware,
    paymentGatewayApiSlice.middleware,
    usersApiSlice.middleware,
    userPresentationsApiSlice.middleware,
    userSlidesApiSlice.middleware,
    errorInterceptor,
  ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type RootState = ReturnType<typeof store.getState>;
