import React from 'react';
import {
  Box,
} from 'grommet';

interface NavProps {
  onClick?: () => void;
  body: JSX.Element;
}
const NavButton = (props: NavProps): JSX.Element => {
  const {
    onClick, body,
  } = props;
  return (
    <Box
      onClick={onClick}
      direction="row"
      width="100%"
      pad="small"
      focusIndicator={false}
    >
      {body}
    </Box>
  );
};
NavButton.defaultProps = {
  onClick: null,
};

export default NavButton;
