import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';

import { IoEyeOutline } from 'react-icons/io5';
import {
  Add,
  Edit,
  View,
} from 'grommet-icons';

import { useHistory } from 'react-router-dom';
import PresentationList from './PresentationList';
import { formTheme } from '../../assets/styles/themes';

import { ROUTE_DECK } from '../../app/router/route-path.constants';
import NewUserPresentation from '../userPresentations/NewUserPresentation';
import MyPresentations from '../userPresentations/myPresentations/MyPresentations';

const cards = Array(20)
  .fill(10, 1, 20)

  .map((_, i) => <Text>{`Card ${i}`}</Text>);

const getSize = (size: string) => {
  switch (size) {
    case 'small':
      return 'small';
    case 'medium':
      return 'medium';
    case 'large':
      return 'large';
    default:
      return 'small';
  }
};

const Home = (): React.ReactElement => {
  const history = useHistory();
  const size = useContext(ResponsiveContext);
  const [show, setShow] = React.useState();
  const [showNewUserPresentation, setShowNewUserPresentation] = useState(false);

  const onCloseDialogHandler = (value: string):void => {
    setShowNewUserPresentation(false);
    if (value === 'success') {
      history.push(ROUTE_DECK);
    }
  };

  return (
    <>
      {
        showNewUserPresentation && (
        <NewUserPresentation
          onClose={(value) => onCloseDialogHandler(value as string)}
        />
        )
      }
      <Box pad="large" width="100%" gap="medium" direction="row-responsive" justify="center">

        <Box width="xxlarge" direction="column" gap="small">
          <PresentationList
            title="Featured Presentations"
            filters={{ featured: true }}
          />
          {/* <PresentationList
          title="Recently Updated Presentations"
          sort={[{ field: 'updatedAt', order: 'DESC' }]}
        /> */}
        </Box>
        <Box pad="none" width={{ min: '280px', max: '100%', width: 'medium' }}>
          <Box margin={{ bottom: 'large' }} align="center" background="background-front" style={{ borderRadius: '10px' }} justify="center" pad="medium" elevation="small">
            <Box background="status-ok" margin={{ left: '5px' }} round="small">
              <Button
                onClick={() => setShowNewUserPresentation(true)}
                icon={<Add color="white" />}
                title="Create new presentation"
              />
            </Box>
            <Heading textAlign="center" level="5" margin={{ bottom: 'small' }}>
              Create a new presentation
            </Heading>
            <Paragraph size="small" fill={false} margin="none" textAlign="center">
              Create a blank presentation and search for content to add
            </Paragraph>
          </Box>
          <MyPresentations />
        </Box>
      </Box>
    </>
  );
};

export default Home;
