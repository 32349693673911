import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImagePreviewState {
  images: {url: string, id: string}[];
  index: number;
  show: boolean;
}

const initialState: ImagePreviewState = {
  images: [],
  index: 0,
  show: false,
};

export const imagePreviewSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setImages(state: ImagePreviewState, { payload }: PayloadAction<ImagePreviewState | null>) {
      if (payload) {
        state.images = payload.images;
        state.index = payload.index;
        state.show = payload.show;
      } else {
        state.images = initialState.images;
        state.index = initialState.index;
        state.show = initialState.show;
      }
    },
  },
});

export const { setImages } = imagePreviewSlice.actions;

export default imagePreviewSlice.reducer;
