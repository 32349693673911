import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Grommet, ThemeType } from 'grommet';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import { deepMerge } from 'grommet/utils';

import './assets/styles/style.css';

import theme from './assets/styles/theme.json';
// import { generate } from './assets/styles/theme';

import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
// const theme = generate();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Grommet id="main-grommet" full theme={theme as ThemeType}>
        <Suspense fallback="loading">
          <App />
        </Suspense>
      </Grommet>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
