import { createApi } from '@reduxjs/toolkit/query/react';

import { jwtBaseQuery as baseQuery } from '../../api/api-base';
import { QueryParams, PaginatedResult, Slide } from '../../types';
import { buildSearchQuery, getDefaultSearchQuery } from '../../utils';

export const slidesApiSlice = createApi({
  reducerPath: 'slideApi',
  baseQuery,
  endpoints(builder) {
    return {
      fetchSlides: builder.query<PaginatedResult<Slide>, QueryParams>({
        query(query = getDefaultSearchQuery()) {
          return {
            url: `/slides${buildSearchQuery(query)}`,
          };
        },
      }),
    };
  },
});

export const {
  useFetchSlidesQuery,
  useLazyFetchSlidesQuery,
} = slidesApiSlice;
