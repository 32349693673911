import React from 'react';
import {
  Box,
  Grid,
} from 'grommet';

import Bg from '../assets/images/sign-in-bg.png';
import { DesktopLogo } from '../components/Logo';

// TODO Need to implement Default Layout UI Elements
const DefaultLayout: React.FC<JSX.Element> = ({ children }) => (
  <>
    <Grid
      fill
      rows={['auto', 'flex', 'xxsmall']}
      columns={['auto', 'flex']}
      gap="none"
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [0, 1], end: [1, 2] },
        // { name: 'footer', start: [0, 2], end: [1, 2] },
      ]}
    >
      <Box
        fill="vertical"
        overflow="auto"
        pad={{ vertical: 'none', horizontal: 'large' }}
        align="center"
        elevation="small"
        background="background-front"
        flex="grow"
        gridArea="header"
      >
        <Grid
          columns={['auto', 'auto', 'flex', ['small', 'small']]}
          gap="small"
          pad="small"
          width="100%"
        >
          <Box align="center" justify="center">
            <DesktopLogo width={135} />
          </Box>
        </Grid>
      </Box>
      <Box gridArea="main" style={{ background: `url("${Bg}") bottom center / 100% auto no-repeat` }} overflow="auto">
        {children}
      </Box>
      {/* <Box gridArea="footer" pad="xsmall">
        footer
      </Box> */}
    </Grid>
  </>
);

export default DefaultLayout;
