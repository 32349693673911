import React from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Paragraph,
  Text,
  Anchor,
  ThemeContext,
} from 'grommet';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { formTheme } from '../../../assets/styles/themes';
import { RouterPath } from '../../../app/router';
import { Logo } from '../../../components';

const ForgotPassword = (): React.ReactElement => {
  const history = useHistory();
  return (
    <ThemeContext.Extend value={formTheme}>
      <Box pad="xlarge" direction="row-responsive" flex="grow" justify="center">
        <Card
          width={{ min: '360px', max: '100%' }}
          pad="large"
          alignSelf="center"
          background="background-front"
        >
          <CardHeader gap="large" direction="column" align="center" alignContent="center">
            <Heading level="4" margin="none" color="black" alignSelf="center">Forgotten your password?</Heading>
            <Text weight="bold" margin="none" alignSelf="center">Check your email</Text>
          </CardHeader>
          <Box width="small" align="center" margin={{ horizontal: 'auto', vertical: 'small' }}>
            <Logo />
          </Box>
          <Paragraph size="small" textAlign="center" alignSelf="center">
            <Text>We have sent password recovery instructions to your email.</Text>
          </Paragraph>
          <CardBody>
            <Box align="center" pad={{ top: 'large' }}>
              <Button
                type="button"
                onClick={() => { history.replace(RouterPath.ROUTE_LOGIN); }}
                size="medium"
                alignSelf="center"
                primary
                label="Sign In"
                icon={<FiArrowRight size="24px" />}
                reverse
              />
            </Box>
            <Box>
              <Paragraph size="small" textAlign="center" alignSelf="center">
                <Text size="small">Didn&apos;t receive the email? Check your spam filter or </Text>
                <Anchor
                  size="small"
                  onClick={() => { history.replace(RouterPath.ROUTE_FORGOT_PASSWORD); }}
                  label="Request another"
                />
              </Paragraph>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </ThemeContext.Extend>
  );
};

export default ForgotPassword;
